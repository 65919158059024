import { useState } from 'react'
import { slide as Menu } from 'react-burger-menu'
import * as Styled from './SideBar.styled'
import { IoMenuSharp } from 'react-icons/io5'
import { useHistory } from 'react-router-dom'
import { useAppDispatch } from '../../Hooks/hooks'
import { setModal } from '../../container/App/appSlice'
import RemedyLogo from '../RemedyLogo'
import { logout } from '../../container/App/actions'
interface ISideBarProps {
  menuType: string[]
  allMenuByType: {
    [key: string]: any[]
  }
  login: {
    isLoggedIn: boolean
  }
}

const SideBar = ({
  menuType,
  allMenuByType,
  login,
}: ISideBarProps): JSX.Element => {
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const history = useHistory()
  const dispatch = useAppDispatch()
  const renderMenuList = (list) => {
    return (
      list &&
      list.map((item, index) => {
        const handleMenuListClick = () => {
          history.push(`/menus/${item._id}/${item.subMenu[0]?._id}`)
          handleOnOpen()
        }
        return (
          <Styled.MenuItem onClick={handleMenuListClick} key={index}>
            {item.name}
          </Styled.MenuItem>
        )
      })
    )
  }

  const renderMenuTypeList = () => {
    return menuType.map((item) => {
      const handleMenuClick = () => {
        let path = `/${item.toLowerCase()}`
        history.push(`/menus${path}`)
        handleOnOpen()
      }
      return (
        <Styled.ListWrapper key={item}>
          <Styled.MenuType onClick={handleMenuClick}>{item}</Styled.MenuType>
          <Styled.MenuListWrapper>
            {renderMenuList(allMenuByType[item])}
          </Styled.MenuListWrapper>
        </Styled.ListWrapper>
      )
    })
  }

  const handleOnOpen = () => {
    setIsOpen((prev) => !prev)
  }

  const handleShowModal = (type: string): void => {
    dispatch(setModal({ isShow: true, type: type }))
    handleOnOpen()
  }

  const handleRedirect = (path: string): void => {
    history.push(path)
    handleOnOpen()
  }

  const toggleShowLoginBtn = () => {
    if (login.isLoggedIn) {
      return (
        <>
          <Styled.AccLogStyle>
            <Styled.LoginButton onClick={() => handleRedirect('/history')}>
              Order History
            </Styled.LoginButton>
          </Styled.AccLogStyle>
          <Styled.AccLogStyle>
            <Styled.ColFlex>
              <Styled.LoginButton onClick={() => handleRedirect('/account')}>
                My Account
              </Styled.LoginButton>
              <Styled.LoginButton onClick={() => dispatch(logout())}>
                Logout
              </Styled.LoginButton>
            </Styled.ColFlex>
          </Styled.AccLogStyle>
        </>
      )
    } else {
      return (
        <Styled.AccLogStyle>
          <Styled.ColFlex>
            <Styled.LoginButton onClick={() => handleShowModal('register')}>
              Register
            </Styled.LoginButton>
            <Styled.LoginButton onClick={() => handleShowModal('login')}>
              Login
            </Styled.LoginButton>
          </Styled.ColFlex>
        </Styled.AccLogStyle>
      )
    }
  }
  return (
    <Styled.SideBar>
      <Menu
        customBurgerIcon={<IoMenuSharp />}
        itemListElement='div'
        isOpen={isOpen}
        onOpen={handleOnOpen}
        onClose={handleOnOpen}
      >
        <RemedyLogo
          onClick={() => handleRedirect('/')}
          height='60px'
          width='auto'
        />
        {renderMenuTypeList()}
        {toggleShowLoginBtn()}
        <Styled.Bottom />
      </Menu>
    </Styled.SideBar>
  )
}
export default SideBar
