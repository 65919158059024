import styled from 'styled-components'
import { setFlex, setTransition } from '../../styles/themeStyles'
import { MotionContainer } from '../../styles/animations'

export const Separator = styled.span`
  margin: 0 5px;
`

export const OTPInputStyle = styled.span`
  ${setFlex()};
  flex-direction: column;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.brown2};
  padding: 10px;
  margin: 5px 0;
  width: 100%;
`

export const OTPInputWrapper = styled.div`
  ${setFlex()};
  margin: 10px 0;
`
export const Input = {
  borderRadius: `5px`,
  border: `1px solid #a28e71`,
  color: '#000000',
  backgroundColor: '#ffffff',
  padding: '5px',
  width: '25px',
}

export const InputFocus = {
  boxShadow: `rgba(162, 142, 113,0.2) 0px 2px 8px 0px`,
}

export const OTPWrapper = styled(MotionContainer)`
  width: 100%;
`

interface IOTPMessage {
  isHeader?: boolean | undefined
}

export const OTPMessage = styled.p<IOTPMessage>`
  font-size: 12px;
  color: ${({ theme, isHeader }) =>
    isHeader ? theme.textColor : theme.colors.grey1};
  font-size: 14px;
  text-align: center;
  font-family: 'Cardo';
`

interface ILink {
  width?: string
}
export const Link = styled.button<ILink>`
  padding: 0 5px;
  cursor: pointer;
  font-size: 14px;
  ${setTransition()};
  text-decoration: underline;
  width: ${({ width = 'fit-content' }) => width};
  &:hover {
    color: ${({ theme }) => theme.colors.brown2};
  }
`
