import { useEffect, useState } from 'react'
import { message } from 'antd'
import { useHistory } from 'react-router-dom'
import Radio from '../../component/Radio'
import Breadcrumbs from '../../component/Breadcrumbs'
import Header from '../../component/Header'
import numbro from 'numbro'
import { useAppSelector, useAppDispatch } from '../../Hooks/hooks'
import { selectMenu, selectSubMenu } from './menuDetailSlice'
import * as Styled from './MenuDetail.styled'
import { getMenuById, getSubMenuById } from './actions'
import {
  selectLogin,
  setModal,
  selectCart,
  selectAddToCartStatus,
  setAddToCartIdle,
} from '../App/appSlice'
import defaultImage from '../../images/remedy_coffee_2.webp'
import { onImageError } from '../../utils/imageError'

import { addToCart } from './actions'

interface IParams {
  params: {
    menuId: string
    subMenuId: string
    type: string
  }
}
interface IChoiceData {
  price: number
  name: string
  _id: string
}

const MenusDetail = ({ match }): JSX.Element => {
  const history = useHistory()
  const dispatch = useAppDispatch()
  const menuList = useAppSelector(selectMenu)
  const subMenuList = useAppSelector(selectSubMenu)
  const login = useAppSelector(selectLogin)
  const cart = useAppSelector(selectCart)
  const addToCartStatus = useAppSelector(selectAddToCartStatus)
  const { params }: IParams = match
  const [selectedSubMenu, setSelectedSubMenu] = useState<string>(
    params.subMenuId
  )
  const [selectedChoice, setSelectedChoice] = useState({})
  const [quantity, setQuantity] = useState<number>(1)
  const [totalPrice, setTotalPrice] = useState<number>(0)
  const [disableAddToCart, setDisableAddToCart] = useState<boolean>(false)
  useEffect(() => {
    setSelectedSubMenu(params.subMenuId)
    dispatch(getMenuById(params.menuId))
    //eslint-disable-next-line
  }, [params.menuId])

  useEffect(() => {
    dispatch(getSubMenuById(params.subMenuId))
    //eslint-disable-next-line
  }, [params.subMenuId])

  useEffect(() => {
    if (subMenuList.status === 'succeeded') {
      setTotalPrice(subMenuList.data.basePrice)
      assignCustomObject()
      setDisableAddToCart(subMenuList.data.active)
    }
    //eslint-disable-next-line
  }, [subMenuList.data])

  useEffect(() => {
    if (addToCartStatus === 'succeeded') {
      message.success('Menu added to cart')
      setQuantity(1)
    } else if (addToCartStatus === 'failed') {
      message.error('Cannot add menu to cart')
    }
    setTimeout(() => {
      dispatch(setAddToCartIdle())
    }, 1500)
    //eslint-disable-next-line
  }, [addToCartStatus])

  const checkIsAllCustomizeSelected = () => {
    const selectedChoiceData: IChoiceData[] = Object.values(selectedChoice)
    let price: number = subMenuList.data.basePrice
    let selectedCustom: number = 0
    selectedChoiceData.map((item) => {
      selectedCustom += item._id === '' ? 0 : 1
      price = item.price + price
      return price
    })
    setTotalPrice(price * quantity)

    if (
      selectedCustom === subMenuList.data.customizes.length &&
      subMenuList.data.active &&
      Object.keys(selectedChoice).length
    ) {
      setDisableAddToCart(false)
    } else {
      setDisableAddToCart(true)
    }
  }

  useEffect(() => {
    checkIsAllCustomizeSelected()
    //eslint-disable-next-line
  }, [selectedChoice, quantity])

  const assignCustomObject = () => {
    let choiceSelection = {}
    subMenuList.data.customizes.map((item) => {
      const defaultChoice =
        item?.choice.find((ch) => ch.default) || item.choice[0]
      const newChoice = defaultChoice.active
        ? {
            [item._id]: {
              price: defaultChoice?.price || 0,
              _id: defaultChoice?._id || '',
              name: defaultChoice?.name || '',
            },
          }
        : {}
      choiceSelection = {
        ...choiceSelection,
        ...newChoice,
      }
      return choiceSelection
    })
    setSelectedChoice(choiceSelection)
  }

  const handleAddToCart = () => {
    if (login.isLoggedIn) {
      let selectedChoices: string[] = []
      Object.keys(selectedChoice).forEach((choice: string) => {
        selectedChoices.push(selectedChoice[choice]._id)
      })
      const body = {
        subMenu: subMenuList.data._id,
        choices: selectedChoices,
        quantity,
      }
      dispatch(addToCart(body))
    } else {
      dispatch(setModal({ isShow: true, type: 'loginRequired' }))
    }
  }

  const renderMenuDetail = () => {
    if (menuList.status === 'succeeded') {
      const { name, description, type } = menuList.data
      return (
        <>
          <Breadcrumbs
            list={[
              { label: 'menu', to: '/menus' },
              { label: type, to: `/menus/${type.toLowerCase()}` },
              { label: name, to: match.url, isActive: true },
            ]}
          />
          <Header text={name} />
          <Styled.Detail>
            <div>
              <Styled.ImageWrapper>
                <Styled.SubMenuImage
                  src={subMenuList.data.image || defaultImage}
                  alt='subMenuImage'
                  onError={onImageError}
                />
                <Styled.OutOfStock isActive={subMenuList.data.active}>
                  Out of Stock
                </Styled.OutOfStock>
              </Styled.ImageWrapper>
            </div>
            <Styled.MenuDetailWrapper>
              <Styled.Description>{description}</Styled.Description>
              {renderSubMenu()}
              {renderSubMenuCustomize()}
            </Styled.MenuDetailWrapper>
          </Styled.Detail>
        </>
      )
    } else if (menuList.status === 'loading') {
      return <div>Loading</div>
    } else {
      return <div>Error</div>
    }
  }

  const handleSubMenuSelected = (e) => {
    setSelectedSubMenu(e.target.value)
    history.push(`/menus/${params.menuId}/${e.target.value}`)
  }

  const renderSubMenu = () => {
    const { subMenu } = menuList.data
    const data = subMenu.map((item) => ({ key: item._id, label: item.name }))
    if (subMenu.length > 0) {
      const result = (
        <Radio
          name='subMenu'
          value={selectedSubMenu}
          onChange={handleSubMenuSelected}
          data={data}
          className='radio-submenu'
        />
      )
      return <Styled.RadioWrapper>{result}</Styled.RadioWrapper>
    }
  }

  const handleChoiceSelected = (e, customizeId) => {
    const matchChoice = subMenuList.data.customizes
      .find((custom) => custom._id === customizeId)
      ?.choice.find((ch) => ch._id === e.target.value)
    setSelectedChoice({
      ...selectedChoice,
      [customizeId]: {
        _id: e.target.value,
        price: matchChoice.price,
        name: matchChoice.name,
      },
    })
  }

  const renderChoice = (customize) => {
    const { name, _id, choice } = customize
    if (choice.length > 0) {
      const data = choice.map((item) => {
        return {
          key: item._id,
          label: item.name,
          image: item.image,
          price: item.price,
          active: item.active,
          disabled: !item.active,
        }
      })
      return (
        <Radio
          name={name}
          onChange={(e) => handleChoiceSelected(e, _id)}
          data={data}
          key={_id}
          value={selectedChoice[_id]?._id}
        />
      )
    }
  }

  const renderSubMenuCustomize = () => {
    const { customizes } = subMenuList.data
    const result = customizes.map((customize) => (
      <Styled.Customize key={customize._id}>
        <Styled.CustomizeTitle>{customize.name}</Styled.CustomizeTitle>
        <Styled.Choices>
          {customize.choice.length > 0 && renderChoice(customize)}
        </Styled.Choices>
      </Styled.Customize>
    ))
    return (
      <Styled.RadioWrapper>
        {customizes.length > 0 && (
          <Styled.RadioTitle>Customize:</Styled.RadioTitle>
        )}
        {result}
      </Styled.RadioWrapper>
    )
  }

  const handleQuantity = (action) => {
    if (quantity > 0 && action === 'add') {
      setQuantity((prev) => prev + 1)
    } else if (quantity > 1 && action === 'minus') {
      setQuantity((prev) => prev - 1)
    }
  }

  return (
    <Styled.MenuDetail>
      {renderMenuDetail()}
      <Styled.QuantityWrapper>
        <Styled.QuantityButton onClick={() => handleQuantity('minus')}>
          <Styled.RemoveIcon />
        </Styled.QuantityButton>
        <Styled.Quantity>{quantity}</Styled.Quantity>
        <Styled.QuantityButton onClick={() => handleQuantity('add')}>
          <Styled.AddIcon />
        </Styled.QuantityButton>
      </Styled.QuantityWrapper>
      <Styled.TotalPrice>
        Total Price: ฿ {numbro(totalPrice).format({ thousandSeparated: true })}
      </Styled.TotalPrice>
      <Styled.AddToCart
        onClick={handleAddToCart}
        disabled={disableAddToCart}
        isLoading={cart.status === 'loading'}
      >
        {disableAddToCart ? 'Out of Stock' : `Add to Cart`}
      </Styled.AddToCart>
    </Styled.MenuDetail>
  )
}
export default MenusDetail
