import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import {
  getUserInfo,
  editAccountRequest,
  getHistory,
  cancelOrder,
  deleteAccount,
  getPointRate,
} from './actions'

export interface IMenusState {
  userInfo: {
    data: any
    status: string
  }
  history: {
    data: any
    status: string
  }
  editAccuntStatus: string
  cancelOrderStatus: string
  deleteAccountStatus: string
  pointRate: {
    status: string
    cashToPoint: {
      cash: number
      point: number
    }
    pointToDiscount: {
      discount: number
      point: number
    }
  }
}

const initialState: IMenusState = {
  userInfo: { data: [], status: 'idle' },
  history: { data: [], status: 'idle' },
  editAccuntStatus: 'idle',
  cancelOrderStatus: 'idle',
  deleteAccountStatus: 'idle',
  pointRate: {
    status: 'idle',
    cashToPoint: {
      cash: 0,
      point: 0,
    },
    pointToDiscount: {
      discount: 0,
      point: 0,
    },
  },
}

export const accountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    setInitialCancelOrderStatus: (state) => {
      state.cancelOrderStatus = 'idle'
    },
    setInitialEditAccountStatus: (state) => {
      state.editAccuntStatus = 'idle'
    },
    setInitialDeleteAccountStatus: (state) => {
      state.deleteAccountStatus = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserInfo.pending, (state) => {
      state.userInfo.status = 'loading'
      state.userInfo.data = []
    })
    builder.addCase(getUserInfo.fulfilled, (state, action) => {
      state.userInfo.status = 'succeeded'
      state.userInfo.data = action.payload
    })
    builder.addCase(getUserInfo.rejected, (state) => {
      state.userInfo.status = 'failed'
      state.userInfo.data = []
    })
    builder.addCase(editAccountRequest.pending, (state) => {
      state.editAccuntStatus = 'loading'
    })
    builder.addCase(editAccountRequest.fulfilled, (state) => {
      state.editAccuntStatus = 'succeeded'
    })
    builder.addCase(editAccountRequest.rejected, (state) => {
      state.editAccuntStatus = 'failed'
    })
    builder.addCase(getHistory.pending, (state) => {
      state.history.status = 'loading'
      state.history.data = []
    })
    builder.addCase(getHistory.fulfilled, (state, action) => {
      state.history.status = 'succeeded'
      state.history.data = action.payload
    })
    builder.addCase(getHistory.rejected, (state) => {
      state.history.status = 'failed'
      state.history.data = []
    })
    builder.addCase(cancelOrder.pending, (state) => {
      state.cancelOrderStatus = 'loading'
    })
    builder.addCase(cancelOrder.fulfilled, (state, action) => {
      state.cancelOrderStatus = 'succeeded'
    })
    builder.addCase(cancelOrder.rejected, (state) => {
      state.cancelOrderStatus = 'failed'
    })
    builder.addCase(deleteAccount.pending, (state) => {
      state.deleteAccountStatus = 'loading'
    })
    builder.addCase(deleteAccount.fulfilled, (state) => {
      state.deleteAccountStatus = 'succeeded'
    })
    builder.addCase(deleteAccount.rejected, (state) => {
      state.deleteAccountStatus = 'failed'
    })
    builder.addCase(getPointRate.pending, (state) => {
      state.pointRate.status = 'loading'
    })
    builder.addCase(getPointRate.fulfilled, (state, { payload }) => {
      const { cashToPoint, pointToDiscount } = payload
      state.pointRate.status = 'succeeded'
      state.pointRate.cashToPoint.cash = cashToPoint.cash
      state.pointRate.cashToPoint.point = cashToPoint.point
      state.pointRate.pointToDiscount.discount = pointToDiscount.discount
      state.pointRate.pointToDiscount.point = pointToDiscount.point
    })
    builder.addCase(getPointRate.rejected, (state) => {
      state.pointRate.status = 'failed'
      state.pointRate.cashToPoint.cash = 0
      state.pointRate.cashToPoint.point = 0
      state.pointRate.pointToDiscount.discount = 0
      state.pointRate.pointToDiscount.point = 0
    })
  },
})
export const {
  setInitialCancelOrderStatus,
  setInitialEditAccountStatus,
  setInitialDeleteAccountStatus,
} = accountSlice.actions

export const selectUserInfo = (state: RootState) => state.account.userInfo
export const selectEditAccountStatus = (state: RootState) =>
  state.account.editAccuntStatus
export const selectHistory = (state: RootState) => state.account.history
export const selectCancelOrderStatus = (state: RootState) =>
  state.account.cancelOrderStatus
export const selectDeleteAccountStatus = (state: RootState) =>
  state.account.deleteAccountStatus
export const selectPointRate = (state: RootState) => state.account.pointRate

export default accountSlice.reducer
