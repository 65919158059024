import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import {
  verifyPhoneNumberRequest,
  verifyForgotPasswordOtpRequest,
  setPasswordRequest,
} from './actions'

type IStatus = 'idle' | 'loading' | 'succeeded' | 'failed'
interface IResponeItem {
  status: IStatus
  token: string | ''
}
interface IForgorPasswordState {
  verifyPhoneNumberResponse: IResponeItem
  verifyOTPResponse: IResponeItem
  setPasswordResponse: IResponeItem
}

const initialState: IForgorPasswordState = {
  verifyPhoneNumberResponse: { status: 'idle', token: '' },
  verifyOTPResponse: { status: 'idle', token: '' },
  setPasswordResponse: { status: 'idle', token: '' },
}

export const forgotPasswordSlice = createSlice({
  name: 'forgotPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(verifyPhoneNumberRequest.pending, (state) => {
      state.verifyPhoneNumberResponse.status = 'loading'
    })
    builder.addCase(verifyPhoneNumberRequest.fulfilled, (state, action) => {
      state.verifyPhoneNumberResponse.status = 'succeeded'
      state.verifyPhoneNumberResponse.token = action.payload
    })
    builder.addCase(verifyPhoneNumberRequest.rejected, (state) => {
      state.verifyPhoneNumberResponse.status = 'failed'
    })

    builder.addCase(verifyForgotPasswordOtpRequest.pending, (state) => {
      state.verifyOTPResponse.status = 'loading'
    })
    builder.addCase(
      verifyForgotPasswordOtpRequest.fulfilled,
      (state, action) => {
        state.verifyOTPResponse.status = 'succeeded'
        state.verifyOTPResponse.token = action.payload
      }
    )
    builder.addCase(verifyForgotPasswordOtpRequest.rejected, (state) => {
      state.verifyOTPResponse.status = 'failed'
    })

    builder.addCase(setPasswordRequest.pending, (state) => {
      state.setPasswordResponse.status = 'loading'
    })
    builder.addCase(setPasswordRequest.fulfilled, (state, action) => {
      state.setPasswordResponse.status = 'succeeded'
    })
    builder.addCase(setPasswordRequest.rejected, (state) => {
      state.setPasswordResponse.status = 'failed'
    })
  },
})

export const selectVerifyPhoneToken = (state: RootState) =>
  state.forgotPassword.verifyPhoneNumberResponse
export const selectOTPToken = (state: RootState) =>
  state.forgotPassword.verifyOTPResponse
export const selectSetPasswordResponse = (state: RootState) =>
  state.forgotPassword.setPasswordResponse

export default forgotPasswordSlice.reducer
