import { useEffect, useCallback } from 'react'
import * as Styled from './Home.styled'

import { selectRecommendedMenuList, selectBanner } from './homeSlice'
import { useAppSelector, useAppDispatch } from '../../Hooks/hooks'
import { getRecommendedMenu, getBanner } from './actions'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import { Carousel } from 'react-responsive-carousel'
import Button from '../../component/Button'
import Menu from '../../component/Menu'
import Spinner from '../../component/Spinner'
import Footer from '../../component/Footer'

const Home = ({ history }): JSX.Element => {
  const recommendedMenu = useAppSelector(selectRecommendedMenuList)
  const banner = useAppSelector(selectBanner)
  const dispatch = useAppDispatch()
  useEffect(() => {
    if (recommendedMenu.status === 'idle') {
      dispatch(getRecommendedMenu())
    }
    if (banner.status === 'idle') {
      dispatch(getBanner())
    }
    //eslint-disable-next-line
  }, [])

  const renderLoader = () => (
    <Styled.LoaderWrapper>
      <Spinner />
    </Styled.LoaderWrapper>
  )
  const renderRecommended = useCallback(() => {
    if (recommendedMenu.status === 'loading') {
      return renderLoader()
    } else if (recommendedMenu.status === 'succeeded') {
      const result = recommendedMenu.data.map((menu) => (
        <Menu menu={menu} key={menu._id} />
      ))
      return (
        <Styled.RecommendedMenuWrapper>{result}</Styled.RecommendedMenuWrapper>
      )
    } else if (recommendedMenu.status === 'failed') {
      return <>Error</>
    }
  }, [recommendedMenu])

  const renderBanner = useCallback(() => {
    if (banner.status === 'loading') {
      return renderLoader()
    } else if (banner.status === 'succeeded') {
      const result = banner.data.map((item) =>
        item.type === 'image' ? (
          <Styled.BannerImage src={item.url} alt='banner' key={item._id} />
        ) : (
          <Styled.BannerVideo
            src={item.url}
            loop
            muted
            key={item._id}
            playsInline
            autoPlay
          />
        )
      )
      return (
        <Carousel
          autoPlay={true}
          interval={4000}
          infiniteLoop
          stopOnHover
          showThumbs={false}
        >
          {result}
        </Carousel>
      )
    } else if (banner.status === 'failed') {
      return <>Error</>
    }
  }, [banner])

  const handleSeeAllMenu = () => {
    history.push('/menus')
  }

  return (
    <Styled.Home>
      <Styled.Banner>{renderBanner()}</Styled.Banner>
      <Styled.Recommend>
        <Styled.RecommendHeader>Recommended Menu </Styled.RecommendHeader>
        <Styled.Description>
          we hope that every cup and dish that passes through our doors honors
          the meaningful in your life.
        </Styled.Description>
        {renderRecommended()}
        <Button onClick={handleSeeAllMenu} width='fit-content'>
          <>
            SEE ALL MENU
            <Styled.Arrow />
          </>
        </Button>
      </Styled.Recommend>
      <Footer />
    </Styled.Home>
  )
}
export default Home
