import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux'
import { selectUserInfo } from '../container/Account/accountSlice'
import { selectCart } from '../container/App/appSlice'
import type { RootState, AppDispatch } from '../store'

export const useAppDispatch = () => useDispatch<AppDispatch>()
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector

export const useCartTotalDiscount = () => {
  const DISCOUNT_PER_ITEM: number = 5
  const userInfo = useSelector(selectUserInfo)
  const cart = useSelector(selectCart)
  let totalDiscount = userInfo.data.firstOrderDiscount || 0
  if (userInfo.status === 'succeeded' && cart.status === 'succeeded') {
    if (userInfo.data.type !== 'general') {
      totalDiscount = cart.total * DISCOUNT_PER_ITEM
    }
  }
  return { totalDiscount, totalPrice: cart.totalPrice - totalDiscount }
}
