import { createAsyncThunk } from '@reduxjs/toolkit'
import { setLocalStorage, getLocalStorage } from '../../utils/localStorage'
import axios from 'axios'
import {
  LOGIN_URL,
  VERIFY_OTP_API,
  RESEND_OTP_API,
  CART_API,
  DELIVERY_SCHEDULE_API,
} from '../../utils/API'
import { getPointRate } from '../Account/actions'
interface ILogin {
  phone: string
  password: string
}
export const login = createAsyncThunk(
  'app/login',
  async (
    { phone, password }: ILogin,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    try {
      const response = await axios({
        method: 'post',
        url: LOGIN_URL,
        headers: {
          platform: 'CLIENT',
        },
        data: {
          phone,
          password,
        },
      })

      let formattedResponse = {
        token: response.data.token,
        isLoggedIn: !response.data.expired,
        expired: response.data.expired,
      }

      setLocalStorage({
        login: formattedResponse,
      })
      dispatch(getCart())
      dispatch(getPointRate())
      dispatch(getDeliverySchedule())
      dispatch(getPickupSchedule())

      return formattedResponse
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

export const logout = createAsyncThunk('app/logout', async () => {
  clearLogInStorage()
  window.location.reload()
})

export const clearLogInStorage = () => {
  setLocalStorage({ login: { token: '', isLoggedIn: false, expired: true } })
}

export const checkIsLoggedIn = () => {
  const local = getLocalStorage()
  return local?.login
}

interface IPayload {
  otp: string
  token: string
}

export const verifyLoginOtpRequest = createAsyncThunk(
  'app/verifyLoginOtpRequest',
  async ({ otp, token }: IPayload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(VERIFY_OTP_API, {
        type: 'login',
        token: otp,
        verifyOtpToken: token,
      })
      let formattedResponse = {
        token: response.data.token,
        isLoggedIn: true,
        expired: false,
      }
      setLocalStorage({
        login: formattedResponse,
      })
      return formattedResponse
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

interface IResendOTPPayload {
  token: string
}
export const resendLoginOTP = createAsyncThunk(
  'app/resendLoginOTP',
  async ({ token }: IResendOTPPayload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(RESEND_OTP_API, {
        verifyOtpToken: token,
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const getPickupSchedule = createAsyncThunk(
  'app/getPickupSchedule',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.get(
        `${DELIVERY_SCHEDULE_API}?type=pickup`,
        {
          headers: { Authorization: `Bearer ${local.login.token}` },
        }
      )
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
export const getDeliverySchedule = createAsyncThunk(
  'app/getDeliverySchedule',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.get(
        `${DELIVERY_SCHEDULE_API}?type=delivery`,
        {
          headers: { Authorization: `Bearer ${local.login.token}` },
        }
      )
      const sortedData = response.data.sort((a, b) => (a._id < b._id ? -1 : 1))
      return sortedData
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const getCart = createAsyncThunk(
  'app/getCart',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.get(CART_API, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const removeCartItem = createAsyncThunk(
  'app/removeCartItem',
  async (id: string, { rejectWithValue, dispatch }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.delete(`${CART_API}/${id}`, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      dispatch(getCart())
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

interface IUpdateQuantityBody {
  id: string
  quantity: number
}
export const updateQuantity = createAsyncThunk(
  'app/updateQuantity',
  async (
    { id, quantity }: IUpdateQuantityBody,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.patch(
        `${CART_API}/${id}`,
        { quantity },
        {
          headers: { Authorization: `Bearer ${local.login.token}` },
        }
      )
      dispatch(getCart())
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
