import * as Styled from './Radio.Styled'
import imageDefault from '../../images/remedy_coffee_2.webp'
import { onImageError } from '../../utils/imageError'
interface IData {
  key: string
  label: string
  disabled?: boolean
  image?: string
  price?: string
  active?: boolean
}

interface IRadioCusProps {
  value?: string | number
  onChange(e: any): void
  data: IData[]
  name: string
  className?: string
  disabled?: boolean
}
const RadioCus = ({
  value,
  onChange,
  data,
  name,
  className,
  disabled,
}: IRadioCusProps): JSX.Element => {
  return (
    <Styled.RadioGroupWrapper
      name={name}
      value={value}
      onChange={onChange}
      className={className}
      disabled={disabled}
      key='radioGroupWrapper'
    >
      {data.map((item) => {
        const labelImage = (
          <div className='radio-label' key={item.key}>
            {item.image && (
              <img
                src={item.image || imageDefault}
                alt='img'
                width={100}
                className='radio-image'
                onError={onImageError}
              />
            )}
            {item.label}
            {item.price?.toString() && (
              <p className='radio-price'>
                {item.active ? `(+ ฿${item.price})` : `(Out of Stock)`}
              </p>
            )}
          </div>
        )
        return (
          <Styled.Radio
            value={item.key}
            className='radio-choice'
            disabled={item.disabled}
          >
            {labelImage}
          </Styled.Radio>
        )
      })}
    </Styled.RadioGroupWrapper>
  )
}

export default RadioCus
