import styled from 'styled-components'
import { setFlex } from '../../styles/themeStyles'

interface IButton {
  renderType: string
  isActive: boolean
  width: string
}

export const Button = styled.button<IButton>`
  background-color: ${({ theme, renderType, isActive }) => {
    if (renderType === 'outline') {
      if (isActive) {
        return theme.colors.brown2
      }
      return theme.colors.white
    } else {
      return theme.colors.brown2
    }
  }};
  color: ${({ theme, renderType, isActive }) => {
    if (renderType === 'outline') {
      if (isActive) {
        return theme.colors.white
      }
      return theme.colors.brown2
    } else {
      return theme.colors.white
    }
  }};
  border: 1px solid ${({ theme }) => theme.colors.brown2};
  width: ${({ width }) => width};
  padding: 5px 10px;
  margin: 5px 0;
  font-size: 16px;
  cursor: pointer;
  ${setFlex()};
  &:disabled {
    cursor: not-allowed;
    color: grey;
    background-color: ${({ theme }) => theme.colors.grey3};
    border: 1px solid ${({ theme }) => theme.colors.grey3};
    font-style: italic;
  }
`
