import { ReactElement } from 'react-router/node_modules/@types/react'
import { setModal } from '../../container/App/appSlice'
import { useAppDispatch } from '../../Hooks/hooks'
import * as Styled from './Footer.styled'

const Footer = (): ReactElement => {
  const dispatch = useAppDispatch()
  const renderLocation = (): JSX.Element => (
    <Styled.FooterSection>
      <Styled.FooterLabel>Meet us</Styled.FooterLabel>
      <Styled.FooterText>
        1st Floor, Srinagarind Hospital, Khonkaen, Thailand 40000
      </Styled.FooterText>
      <Styled.OpenTime>
        <Styled.FooterText>Monday - Friday : 7am - 3pm</Styled.FooterText>
        <Styled.FooterText>
          Saturday - Sunday : 8.30am - 3.30pm
        </Styled.FooterText>
      </Styled.OpenTime>
    </Styled.FooterSection>
  )

  const renderSocialMedia = (): JSX.Element => (
    <Styled.FooterSection>
      <Styled.FooterLabel>BE THE FIRST TO KNOW THE LATEST</Styled.FooterLabel>
      <Styled.SocialMedia>
        <Styled.SocialMediaLink href='https://www.facebook.com/remedycoffeeth'>
          <Styled.Facebook />
          Facebook
        </Styled.SocialMediaLink>
        <Styled.SocialMediaLink href='https://www.instagram.com/reme.dycoffee/'>
          <Styled.Instagram />
          Instagram
        </Styled.SocialMediaLink>
      </Styled.SocialMedia>
    </Styled.FooterSection>
  )

  const renderContractUs = (): JSX.Element => (
    <Styled.FooterSection>
      <Styled.FooterLabel>Contact us</Styled.FooterLabel>
      <Styled.FooterText>098 274 0302</Styled.FooterText>
      <Styled.FooterText>remedy.coffeeth@gmail.com</Styled.FooterText>
    </Styled.FooterSection>
  )

  const renderTermsAndCondition = (): JSX.Element => (
    <Styled.FooterSection>
      <Styled.FooterLabel>Terms & Conditions</Styled.FooterLabel>
      <Styled.FooterText>
        <button
          onClick={() =>
            dispatch(setModal({ isShow: true, type: 'user-agreement' }))
          }
        >
          User agreement
        </button>
      </Styled.FooterText>
      <Styled.FooterText>
        <button
          onClick={() =>
            dispatch(setModal({ isShow: true, type: 'privacy-policy' }))
          }
        >
          Privacy policy
        </button>
      </Styled.FooterText>
    </Styled.FooterSection>
  )
  return (
    <div>
      <Styled.Footer>
        <Styled.FooterBox>{renderSocialMedia()}</Styled.FooterBox>
        <Styled.FooterBox>{renderLocation()}</Styled.FooterBox>
        <Styled.FooterBox>{renderContractUs()}</Styled.FooterBox>
        <Styled.FooterBox>{renderTermsAndCondition()}</Styled.FooterBox>
      </Styled.Footer>
      <Styled.Copyright>Remedy © 2021 | All Rights Reserved</Styled.Copyright>
    </div>
  )
}
export default Footer
