import { useEffect } from 'react'
import { message } from 'antd'
import Header from '../../component/Header'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import * as Styled from './Cart.styled'
import {
  selectCart,
  selectRemoveFromCartStatus,
  setRemoveFromCartIdle,
  selectUpdateQuantityStatus,
  setUpdateQuantityIdle,
} from '../App/appSlice'
import {
  removeCartItem,
  updateQuantity,
  getDeliverySchedule,
} from '../App/actions'
import Button from '../../component/Button'
import { useHistory } from 'react-router'
import { IoTrashBinOutline } from 'react-icons/io5'
import defaultImage from '../../images/remedy_coffee_2.webp'
import NumberCounter from '../../component/NumberCounter'
import { onImageError } from '../../utils/imageError'
const Cart = (): JSX.Element => {
  const cart = useAppSelector(selectCart)
  const removeFromCartStatus = useAppSelector(selectRemoveFromCartStatus)
  const updateQuantityStatus = useAppSelector(selectUpdateQuantityStatus)
  const dispatch = useAppDispatch()
  const history = useHistory()

  const getNewQuantity = (qty, type, id) => {
    if (type === 'add') {
      return +qty + 1
    } else if (qty > 0) {
      return +qty - 1
    }
    return qty
  }

  useEffect(() => {
    if (removeFromCartStatus === 'succeeded') {
      message.success('Menu removed from cart')
    } else if (removeFromCartStatus === 'failed') {
      message.error('Cannot remove menu from cart')
    }
    setTimeout(() => {
      dispatch(setRemoveFromCartIdle())
    }, 1500)
    //eslint-disable-next-line
  }, [removeFromCartStatus])

  useEffect(() => {
    if (updateQuantityStatus === 'succeeded') {
      message.success('Quantity updated')
    } else if (updateQuantityStatus === 'failed') {
      message.error('Cannot update quantity')
    }
    setTimeout(() => {
      dispatch(setUpdateQuantityIdle())
    }, 1500)
    //eslint-disable-next-line
  }, [updateQuantityStatus])

  const onQuantityChange = (data, type) => {
    const { quantity, _id } = data
    if (type === 'remove' && data.quantity === 1) {
      dispatch(removeCartItem(_id))
    } else {
      const body = {
        quantity: getNewQuantity(quantity, type, _id),
        id: _id,
      }
      dispatch(updateQuantity(body))
    }
  }

  const getCustomize = (item) =>
    item.customizes.map((cus) => (
      <Styled.CustomTag>
        {cus.customize.name}:
        <span>
          {cus.choice.name} ( +฿{cus.choice.price} )
        </span>
      </Styled.CustomTag>
    ))

  const renderItems = () => {
    const items = cart.data.map((item) => (
      <Styled.ItemWrapper>
        <Styled.MenuName>
          <Styled.MenuImage
            src={item.product.subMenu.image || defaultImage}
            onError={onImageError}
          />
          <div>
            {item.product.menu.name}
            <Styled.CustomTagWrapper>
              <Styled.CustomTag primary>
                {item.product.subMenu.name}
              </Styled.CustomTag>
              {getCustomize(item)}
            </Styled.CustomTagWrapper>
          </div>
        </Styled.MenuName>
        <Styled.Quantity>
          <Styled.RemoveIcon onClick={() => onQuantityChange(item, 'remove')} />
          <span>{item.quantity}</span>
          <Styled.AddIcon onClick={() => onQuantityChange(item, 'add')} />
        </Styled.Quantity>
        <Styled.ItemPrice>฿ {item.totalPrice.toFixed(2)}</Styled.ItemPrice>
        <Styled.RemoveButton onClick={() => dispatch(removeCartItem(item._id))}>
          <IoTrashBinOutline />
        </Styled.RemoveButton>
      </Styled.ItemWrapper>
    ))

    return (
      <>
        {items}
        <Styled.TotalPrice>
          Total price: ฿
          <NumberCounter target={cart.totalPrice} duration={70} />
        </Styled.TotalPrice>
        <Button
          onClick={() => {
            history.push('/checkout')
            dispatch(getDeliverySchedule())
          }}
        >
          Check out
        </Button>
      </>
    )
  }
  const renderEmptyCart = () => (
    <Styled.ZeroState>
      <Styled.CoffeeIcon />
      <p>NO ITEMS IN CART</p>
      <Button width='fit-content' onClick={() => history.push('/menus')}>
        <>
          SEE ALL MENU
          <Styled.Arrow />
        </>
      </Button>
    </Styled.ZeroState>
  )

  return (
    <Styled.CartWrapper>
      <Styled.Cart>
        <Header text='Cart' />
        {cart.data.length > 0 ? renderItems() : renderEmptyCart()}
      </Styled.Cart>
    </Styled.CartWrapper>
  )
}
export default Cart
