import styled, { css } from 'styled-components'
import { devices, setFlex } from '../../styles/themeStyles'
import { IoArrowForwardOutline } from 'react-icons/io5'
import { FiCoffee } from 'react-icons/fi'
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md'

const IconStyle = css`
  width: 30px;
  height: 30px;
  fill: ${({ theme }) => theme.colors.brown2};
  cursor: pointer;
`
export const AddIcon = styled(MdAddCircle)`
  ${IconStyle};
`
export const RemoveIcon = styled(MdRemoveCircle)`
  ${IconStyle};
`
export const Cart = styled.div`
  max-width: 60vw;
  width: 100%;
  ${devices.tablet} {
    max-width: 90vw;
  }
`
export const CartWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 20% 20% 10%;
  padding: 10px;
  margin: 10px 0;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.whiteTransparent};
  ${devices.mobile} {
    grid-template-columns: 40% 30% 20% 10%;
  }
  ${devices.smallMobile} {
    grid-template-columns: 35% 35% 20% 10%;
  }
`

export const MenuName = styled.div`
  font-weight: bold;
  text-align: left;
  margin-right: 5px;
  display: flex;
  ${devices.mobile} {
    flex-direction: column;
  }
`
export const UnitPrice = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const Quantity = styled.p`
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  span {
    padding: 0 10px;
    text-align: center;
  }
`
export const ItemPrice = styled.p`
  text-align: end;
  font-weight: bold;
  ${devices.mobile} {
    text-align: center;
  }
`
export const TotalPrice = styled.p`
  text-align: center;
  font-size: 28px;
`
export const CustomTag = styled.p<{ primary?: boolean }>`
  font-size: 12px;
  border-radius: 10px;
  border: 1px solid ${({ theme }) => theme.colors.brown2};
  width: fit-content;
  padding: 5px 10px;
  margin: 5px 10px 0 0;
  font-weight: bold;
  span {
    font-weight: normal;
    margin-left: 5px;
  }
  ${devices.mobile} {
    width: 100%;
    text-align: center;
  }
  ${({ primary, theme }) =>
    primary
      ? ` 
      background-color: ${theme.colors.brown2};
      color: ${theme.colors.white};
      font-weight: bold;
    `
      : ''};
`

export const CustomTagWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
export const ZeroState = styled.div`
  width: 100%;
  ${setFlex()};
  flex-direction: column;
  font-size: 20px;
  p {
    margin: 20px;
  }
`
export const Arrow = styled(IoArrowForwardOutline)`
  margin-left: 5px;
  path {
    color: ${({ theme }) => theme.colors.white};
  }
`

export const CoffeeIcon = styled(FiCoffee)`
  font-size: 80px;
  margin-top: 20px;
`

export const RemoveButton = styled.button``

export const MenuImage = styled.img`
  border-radius: 10px;
  max-height: 100px;
  max-width: 100px;
  min-width: 100px;
  margin: 0 10px 5px 0;
  border: none;
  ${devices.mobile} {
    margin: 0 0px 5px 0;
  }
`
