import styled from 'styled-components'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'

import { Link } from 'react-router-dom'

interface IList {
  isActive: boolean
}

export const List = styled(Link)<IList>`
  text-transform: uppercase;
  color: ${({ isActive, theme }) =>
    isActive ? theme.textColor : theme.colors.grey1};
  font-size: 10px;
`
export const BreadcrumbsStyle = styled(Breadcrumbs)`
  font-size: 12px;
  .MuiBreadcrumbs-separator {
    font-size: 12px;
    color: ${({ theme }) => theme.colors.grey1};
  }
`
