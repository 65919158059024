import styled from 'styled-components'
import { setFlex } from '../../styles/themeStyles'
import { Radio as AntdRadio } from 'antd'

export const RadioGroupWrapper = styled(AntdRadio.Group)`
  width: 100%;
  .radio-label {
    ${setFlex()};
    padding: 5px;
    font-family: 'Cardo';
  }
  .radio-image {
    border-radius: 5px;
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
  .radio-list-wrapper {
    ${setFlex('space-between')};
    width: 100%;
    border-radius: 5px;
    padding: 0 5px;
  }
  .radio-price {
    margin: auto;
    margin-left: 5px;
    color: ${({ theme }) => theme.colors.brown2};
    font-style: italic;
    font-size: 14px;
  }
`

export const Radio = styled(AntdRadio)`
  .ant-radio-checked::after {
    border: 1px solid ${({ theme }) => theme.colors.brown2};
  }
  .ant-radio-inner::after {
    background-color: ${({ theme }) => theme.colors.brown2};
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${({ theme }) => theme.colors.brown2};
  }
  ≈≈≈ span.ant-radio + * {
    padding: 0;
  }
`
