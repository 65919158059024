import { useState, useEffect, ReactElement } from 'react'
import Header from '../../component/Header'
import * as Styled from './Account.styled'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { logout } from '../../container/App/actions'
import Button from '../../component/Button'
import Radio from '../../component/Radio'
import Input from '../../component/Input'
import {
  selectUserInfo,
  selectEditAccountStatus,
  setInitialEditAccountStatus,
  selectDeleteAccountStatus,
  setInitialDeleteAccountStatus,
  selectPointRate,
} from './accountSlice'
import {
  getUserInfo,
  editAccountRequest,
  getHistory,
  deleteAccount,
  getPointRate,
} from './actions'
import { setModal } from '../../container/App/appSlice'
import validator from 'validator'
import moment from 'moment'
import { DatePicker, message } from 'antd'
import Spinner from '../../component/Spinner'
import './overrideAntd.scss'
import { FaUserEdit } from 'react-icons/fa'
import OverlayModal from '../../component/OverlayModal'

const Account = (): ReactElement => {
  const dispatch = useAppDispatch()
  const userInfo = useAppSelector(selectUserInfo)
  const pointRate = useAppSelector(selectPointRate)
  const editAccountStatus = useAppSelector(selectEditAccountStatus)
  const deleteAccountStatus = useAppSelector(selectDeleteAccountStatus)
  const [isInEditMode, setIsInEditMode] = useState(false)
  const [isShowRemoveAccountModal, setIsShowRemoveAccountModal] =
    useState(false)
  const [dataState, setDataState] = useState(userInfo.data)
  const [errorMessage, setErrorMessage] = useState('')
  const [confirmPhoneNumber, setConfirmPhoneNumber] = useState('')
  const [isShowPointInfo, setIsShowPointInfo] = useState(false)

  useEffect(() => {
    if (userInfo.status === 'idle') {
      dispatch(getUserInfo())
    }
    dispatch(getHistory())
    dispatch(getPointRate())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (userInfo.status === 'succeeded') {
      setDataState(userInfo.data)
    }
  }, [userInfo])
  useEffect(() => {
    if (editAccountStatus === 'succeeded') {
      setIsInEditMode(false)
      message.success('Account info has been updated')
    } else if (editAccountStatus === 'failed') {
      message.error('Sorry, something went wrong, Please try again.')
    }
    setTimeout(() => {
      dispatch(setInitialEditAccountStatus())
    }, 500)
    //eslint-disable-next-line
  }, [editAccountStatus])

  useEffect(() => {
    if (deleteAccountStatus === 'succeeded') {
      message.success('Your Account has been successfully deleted')
      setTimeout(() => {
        dispatch(logout())
      }, 200)
    } else if (deleteAccountStatus === 'failed') {
      message.error('Sorry, something went wrong, Please try again.')
    }
    setIsShowRemoveAccountModal(false)
    setConfirmPhoneNumber('')
    setTimeout(() => {
      dispatch(setInitialDeleteAccountStatus())
    }, 500)
    //eslint-disable-next-line
  }, [deleteAccountStatus])

  const handleEditAcc = (): void => {
    if (!validator.isMobilePhone(dataState.phone, 'th-TH')) {
      setErrorMessage('Please fill in a valid phone number')
    } else if (dataState.email && !validator.isEmail(dataState.email)) {
      setErrorMessage('Please fill in a valid email')
    } else if (
      dataState.firstname === '' ||
      dataState.lastname === '' ||
      dataState.dob === 'Invalid date'
    ) {
      setErrorMessage('Please fill in a valid for all required fields')
    } else {
      setErrorMessage('')
      const dob = new Date(dataState.dob).toISOString()
      const emailField =
        dataState.email === '' ? {} : { email: dataState.email }
      dispatch(
        editAccountRequest({
          firstname: dataState.firstname,
          lastname: dataState.lastname,
          gender: dataState.gender,
          dob,
          ...emailField,
        })
      )
    }
  }

  const handleResetPassword = (): void => {
    dispatch(setModal({ isShow: true, type: 'resetPassword' }))
  }

  const renderInfo = (): ReactElement | ReactElement[] => {
    const onChange = (e) => {
      const { name, value } = e.target
      setDataState((prev) => ({ ...prev, [name]: value }))
    }

    const info = [
      {
        key: 'firstname',
        label: 'First Name',
        placeholder: 'First Name',
        value: dataState.firstname,
      },
      {
        key: 'lastname',
        label: 'Last Name',
        placeholder: 'Last Name',
        value: dataState.lastname,
      },
      {
        key: 'email',
        label: 'Email',
        placeholder: 'Email',
        value: dataState.email,
      },
    ]
    if (userInfo.status === 'succeeded') {
      return info.map((info) => (
        <Styled.Row key={info.key}>
          {isInEditMode ? (
            <Input
              label={info.label}
              placeholder={info.placeholder}
              value={info.value}
              onChange={onChange}
              type='text'
              isRequire
              name={info.key}
            />
          ) : (
            <Styled.InfoText>
              <Styled.Label>{info.label}: </Styled.Label>
              <Styled.Value>{info.value ?? '-'}</Styled.Value>
            </Styled.InfoText>
          )}
        </Styled.Row>
      ))
    } else {
      return <></>
    }
  }

  const handleGenderChange = (e): void => {
    setDataState((prev) => ({ ...prev, gender: e.target.value }))
  }

  const renderPassword = () => {
    return isInEditMode ? (
      <Styled.GenderWrapper>
        <Styled.GenderLabel>
          Password<Styled.RequireStar>*</Styled.RequireStar>
        </Styled.GenderLabel>
        <Styled.PasswordMocked>
          <input
            placeholder='Password'
            value='0000000000'
            onChange={() => {}}
            type='password'
            name='password'
            disabled
            style={{ padding: '10px 0', background: 'white' }}
          />
          <Button width='fit-content' onClick={handleResetPassword}>
            Reset Password
          </Button>
        </Styled.PasswordMocked>
      </Styled.GenderWrapper>
    ) : (
      <Styled.InfoText>
        <Styled.Label>Password: </Styled.Label>
        <Styled.Value>••••••••••</Styled.Value>
      </Styled.InfoText>
    )
  }

  const renderGender = (): ReactElement => {
    const genders = [
      { key: 'male', label: 'male' },
      { key: 'female', label: 'female' },
    ]
    const radioItem = dataState.gender && (
      <Radio
        name='gender'
        value={dataState.gender}
        onChange={handleGenderChange}
        data={genders}
        disabled={!isInEditMode}
        className='gender-radio'
      />
    )

    return isInEditMode ? (
      <Styled.GenderWrapper>
        <Styled.GenderLabel>
          Gender<Styled.RequireStar>*</Styled.RequireStar>
        </Styled.GenderLabel>
        {radioItem}
      </Styled.GenderWrapper>
    ) : (
      <Styled.InfoText>
        <Styled.Label>Gender:</Styled.Label>
        {radioItem}
      </Styled.InfoText>
    )
  }
  const renderPhoneNumber = (): ReactElement => {
    return (
      <Styled.PointSectionWrapper key='phone'>
        <Styled.Label style={{ textAlign: 'center' }}>
          Phone Number:{' '}
        </Styled.Label>
        <Styled.Value>{dataState.phone}</Styled.Value>
      </Styled.PointSectionWrapper>
    )
  }

  const setEditMode = (): void => {
    if (isInEditMode) {
      setIsShowRemoveAccountModal(true)
    } else {
      setIsInEditMode(true)
    }
  }
  const onDateChange = (date): void => {
    setDataState((prev) => ({ ...prev, dob: moment(date).format() }))
  }

  const renderDateOfBirth = (): ReactElement => {
    return isInEditMode ? (
      <Styled.DateOfBirthWrapper>
        <Styled.GenderLabel>
          Date of Birth: <Styled.RequireStar>*</Styled.RequireStar>
        </Styled.GenderLabel>
        <DatePicker
          onChange={onDateChange}
          defaultValue={moment(dataState.dob)}
          inputReadOnly
          format='DD MMM YYYY'
          allowClear={false}
        />
      </Styled.DateOfBirthWrapper>
    ) : (
      <Styled.InfoText>
        <Styled.Label>Date of birth: </Styled.Label>
        <Styled.Value>
          {moment(dataState.dob).format('DD MMMM YYYY')}
        </Styled.Value>
      </Styled.InfoText>
    )
  }

  const renderDeleteAccountModal = () => {
    return (
      isShowRemoveAccountModal && (
        <OverlayModal
          onClose={() => {
            setIsShowRemoveAccountModal(false)
          }}
        >
          <Styled.CancelModal>
            <Header text='DELETE ACCOUNT' />
            <h3>Are you sure you want to delete your account?</h3>
            <p>
              This will permanently remove your account and cannot be undone.
            </p>
            <p>
              You are about to
              <b className='delete'>DELETE</b>
              the account that registered with
              <b> "{dataState.phone}"</b>.
            </p>
            <p>Please fill in your phone number to confirm.</p>
            <Input
              label='Phone no.'
              placeholder='Please fill in your phone no.'
              value={confirmPhoneNumber}
              onChange={(e) => setConfirmPhoneNumber(e.target.value)}
              type='number'
              isRequire
            />

            <Styled.CancelButtonWrapper>
              <Button
                renderType='outline'
                onClick={() => {
                  setIsShowRemoveAccountModal(false)
                }}
              >
                Keep Account
              </Button>
              <Button
                disabled={dataState.phone !== confirmPhoneNumber}
                onClick={() => {
                  dispatch(deleteAccount())
                }}
              >
                Delete Account
              </Button>
            </Styled.CancelButtonWrapper>
          </Styled.CancelModal>
        </OverlayModal>
      )
    )
  }

  const renderPoint = () => {
    return (
      <Styled.PointSectionWrapper>
        <Styled.Label style={{ textAlign: 'center' }}>
          You have <span>{userInfo.data?.point?.toLocaleString() ?? '-'}</span>{' '}
          points
        </Styled.Label>
        <Styled.Value>
          <Styled.PointInfoButton onClick={() => setIsShowPointInfo(true)}>
            How to redeem Remedy points?
          </Styled.PointInfoButton>
        </Styled.Value>
      </Styled.PointSectionWrapper>
    )
  }

  const renderRedemptionInfoModal = () => {
    return (
      isShowPointInfo && (
        <OverlayModal
          onClose={() => {
            setIsShowPointInfo(false)
          }}
        >
          <Styled.PointInfoModal>
            <h3>Remedy Point Redemption</h3>
            <p>How do I earn Remedy Points?</p>
            <ul>
              <li>
                Every <span>฿{pointRate.cashToPoint.cash}</span> spent on
                Remedy, you''ll earn <span>{pointRate.cashToPoint.point}</span>{' '}
                points.
              </li>
            </ul>
            <p>How to redeem Remedy points?</p>
            <ul>
              <li>
                At Checkout page, Tab Redeem to open Remedy point calculator.
              </li>
              <li>
                Fill out the amount of Remedy point that you'd like to redeem.
              </li>
              <li>
                Confirmation box will appear when Remedy points redemption has
                been applied to your order the total price will be calculated.
              </li>
              <li>Continue payment process.</li>
            </ul>
            <p>Remarks</p>
            <ul>
              <li>
                You can check Remedy point balance before redeeming in the
                checkout page.
              </li>
              <li>
                If the order has been cancelled, Remedy points will be returned
                to your account.
              </li>
            </ul>
          </Styled.PointInfoModal>
        </OverlayModal>
      )
    )
  }

  const renderEditButtons = () => {
    return (
      <Styled.EditButtonWrapper>
        <Button
          style={{ padding: '0 5px' }}
          renderType='outline'
          onClick={() => setIsInEditMode(false)}
        >
          cancel
        </Button>
        <Button style={{ padding: '0' }} onClick={handleEditAcc}>
          Save
        </Button>
      </Styled.EditButtonWrapper>
    )
  }
  return (
    <Styled.Account>
      <Styled.Section>
        <Header text='Your Account Information' />
        <Styled.InfoWrapper>
          {userInfo.status === 'loading' ? (
            <Spinner />
          ) : (
            <>
              <Styled.ButtonWrapper>
                <Styled.Info>{renderPhoneNumber()}</Styled.Info>
                <Styled.Info>{renderPoint()}</Styled.Info>
              </Styled.ButtonWrapper>
              <Styled.Info>
                {!isInEditMode ? (
                  <Styled.EditButton onClick={setEditMode}>
                    <FaUserEdit />
                  </Styled.EditButton>
                ) : (
                  renderEditButtons()
                )}
                {renderInfo()}
                {renderDateOfBirth()}
                {renderGender()}
                {renderPassword()}
                {errorMessage.length > 0 && (
                  <Styled.ErrorMessage>{errorMessage}</Styled.ErrorMessage>
                )}
                {isInEditMode && (
                  <Styled.DeleteAccountButton
                    onClick={() => setIsShowRemoveAccountModal(true)}
                  >
                    Delete Account?
                  </Styled.DeleteAccountButton>
                )}
              </Styled.Info>
            </>
          )}
        </Styled.InfoWrapper>
      </Styled.Section>
      {renderDeleteAccountModal()}
      {renderRedemptionInfoModal()}
    </Styled.Account>
  )
}
export default Account
