import styled, { css } from 'styled-components'
import { setFlex, devices } from '../../styles/themeStyles'
import { IoArrowForwardOutline } from 'react-icons/io5'

const common = css`
  width: 100%;
  ${setFlex('flex-start')};
  flex-direction: column;
`
export const Home = styled.div`
  padding-top: 60px;
`

export const Banner = styled.div`
  ${common};
  height: 70vh;
`
export const LoaderWrapper = styled.div`
  height: 70vh;
  ${setFlex()};
`
export const BannerImage = styled.img`
  width: 100%;
  object-fit: cover;
  height: 70vh;
  margin: auto;
`
export const BannerVideo = styled.video`
  width: 100%;
  object-fit: cover;
  height: 70vh;
  margin: auto;
  video::-webkit-media-controls {
    display: none;
  }
`
export const Recommend = styled.div`
  ${common};
  padding: 30px 0;
  background-color: ${({ theme }) => theme.colors.brown3};
`

export const RecommendedMenuWrapper = styled.div`
  ${setFlex()};
  width: 100%;
  flex-wrap: wrap;
`

export const RecommendHeader = styled.div`
  font-size: 30px;
  text-transform: uppercase;
`

export const Description = styled.div`
  font-size: 14px;
  padding: 10px 20px;
  text-align: center;
  font-family: 'Cardo';
  ${devices.smallMobile} {
    font-size: 12px;
  }
`

export const Arrow = styled(IoArrowForwardOutline)`
  margin-left: 5px;
  path {
    color: ${({ theme }) => theme.colors.white};
  }
`
