import moment from 'moment'
import OverlayModal from '../OverlayModal'
import remedyIcon from '../../images/remedy_coffee_1.png'
import * as Styled from './payment.styled'
import Button from '../Button'

const Payment = ({
  data,
  onPaidClicked,
  setIsShow,
  shippingMethod,
  onCloseModal,
}): JSX.Element => {
  const {
    updatedAt,
    discount,
    originalPrice,
    summaryPrice,
    location,
    products,
    paymentQRUrl,
    instruction,
  } = data

  const totalItems = products.reduce(
    (accumulator, currentValue) => accumulator + +currentValue.quantity,
    0
  )
  return (
    <OverlayModal
      onClose={() => {
        if (onCloseModal) {
          onCloseModal()
        } else {
          setIsShow(false)
        }
      }}
    >
      <Styled.BoxWrapper>
        <Styled.RemedyIcon src={remedyIcon} alt='remedy' />
        <hr />
        <Styled.Row>
          <Styled.PaymentLabel>Total items</Styled.PaymentLabel>
          <p>{totalItems}</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <Styled.PaymentLabel>Order time</Styled.PaymentLabel>
          <p>{moment(updatedAt).format('DD MMM YYYY HH:mm')}</p>
        </Styled.Row>
        {shippingMethod === 'pickup' ? (
          <Styled.Row>
            <Styled.PaymentLabel>Pickup time</Styled.PaymentLabel>
            <p>{moment(location.startTime).format('DD MMM YYYY HH:mm')}</p>
          </Styled.Row>
        ) : (
          <>
            <Styled.Row>
              <Styled.PaymentLabel>Delivery to</Styled.PaymentLabel>
              <p>{location.name}</p>
            </Styled.Row>
            <Styled.Row>
              <Styled.PaymentLabel>Delivery time</Styled.PaymentLabel>
              <p>
                {moment(location.startTime).format('DD MMM YYYY')}{' '}
                {moment(location.startTime).format('HH:mm')} -{' '}
                {moment(location.endTime).format('HH:mm')}
              </p>
            </Styled.Row>
          </>
        )}
        <hr />
        <Styled.Row>
          <Styled.PaymentLabel>Special Note</Styled.PaymentLabel>
          <p>{instruction.length ? instruction : '-'}</p>
        </Styled.Row>
        <hr />
        <Styled.Row>
          <Styled.PaymentLabel>Subtotal</Styled.PaymentLabel>
          <Styled.Price>{originalPrice.toLocaleString()}</Styled.Price>
        </Styled.Row>
        <Styled.Row>
          <Styled.PaymentLabel>Discount</Styled.PaymentLabel>
          <Styled.Discount>{discount.toLocaleString()}</Styled.Discount>
        </Styled.Row>
        <Styled.Row>
          <Styled.TotalLabel>Total</Styled.TotalLabel>
          <Styled.TotalPrice>{summaryPrice.toLocaleString()}</Styled.TotalPrice>
        </Styled.Row>
        <hr />

        <p style={{ textAlign: 'center', margin: '5px 0' }}>
          Please scan QR code with your mobile banking by using Mobile banking
          application
        </p>
        <Styled.QRImage src={paymentQRUrl} alt='qr' />
        <Button onClick={onPaidClicked}>Already paid?</Button>
      </Styled.BoxWrapper>
    </OverlayModal>
  )
}
export default Payment
