import styled from 'styled-components'
import { setFlex } from '../../styles/themeStyles'
import Button from '../../component/Button'

export const Menus = styled.div`
  ${setFlex('flex-start')};
  flex-direction: column;
  width: 100%;
`

export const MenuTypeWrapper = styled.div`
  ${setFlex()};
  width: 100%;
  flex-wrap: wrap;
`
export const MenuListWrapper = styled.div`
  ${setFlex()};
  width: 100%;
  flex-wrap: wrap;
`
export const MenuTypeButton = styled(Button)`
  width: fit-content;
  text-transform: capitalize;
`
