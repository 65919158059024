import { ReactElement } from 'react'
import OtpInput from 'react-otp-input'
import * as Styled from './OTPInput.styled'

interface OTPInputProps {
  value: any
  onChange: any
  resendFn: any
}

const OTPInput = ({
  onChange,
  value,
  resendFn,
}: OTPInputProps): ReactElement => {
  return (
    <Styled.OTPInputStyle>
      <Styled.OTPMessage isHeader>
        OTP is sent to your phone number
      </Styled.OTPMessage>
      <Styled.OTPInputWrapper>
        <OtpInput
          onChange={onChange}
          value={value}
          numInputs={6}
          isInputNum
          separator={<Styled.Separator />}
          inputStyle={Styled.Input}
          focusStyle={Styled.InputFocus}
        />
      </Styled.OTPInputWrapper>
      <Styled.OTPMessage>
        Not receive an OTP?
        <Styled.Link onClick={resendFn}>Resend OTP</Styled.Link>
      </Styled.OTPMessage>
    </Styled.OTPInputStyle>
  )
}

export default OTPInput
