import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import {
  checkoutDeliveryRequest,
  checkoutPickupRequest,
  verifyPayment,
} from './actions'

type IStatus = 'idle' | 'loading' | 'succeeded' | 'failed'

interface ICheckoutPickupRes {
  checkoutPickupRes: {
    status: IStatus
    data: any
  }
  checkoutDeliveryRes: {
    status: IStatus
    data: any
  }
  verifyPaymentStatus: IStatus
}
const initialState: ICheckoutPickupRes = {
  checkoutPickupRes: { status: 'idle', data: {} },
  checkoutDeliveryRes: { status: 'idle', data: {} },
  verifyPaymentStatus: 'idle',
}

export const checkoutSlice = createSlice({
  name: 'checkout',
  initialState,
  reducers: {
    setInitialVerifyPaymentStatus: (state) => {
      state.verifyPaymentStatus = 'idle'
    },
    setInitialCheckoutPickupRes: (state) => {
      state.checkoutPickupRes.status = 'idle'
      state.checkoutPickupRes.data = {}
    },
    setInitialCheckoutDeliveryRes: (state) => {
      state.checkoutDeliveryRes.status = 'idle'
      state.checkoutDeliveryRes.data = {}
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkoutPickupRequest.pending, (state) => {
      state.checkoutPickupRes.status = 'loading'
    })
    builder.addCase(checkoutPickupRequest.fulfilled, (state, action) => {
      state.checkoutPickupRes.status = 'succeeded'
      state.checkoutPickupRes.data = action.payload
    })
    builder.addCase(checkoutPickupRequest.rejected, (state) => {
      state.checkoutPickupRes.status = 'failed'
      state.checkoutPickupRes.data = {}
    })
    builder.addCase(checkoutDeliveryRequest.pending, (state) => {
      state.checkoutDeliveryRes.status = 'loading'
    })
    builder.addCase(checkoutDeliveryRequest.fulfilled, (state, action) => {
      state.checkoutDeliveryRes.status = 'succeeded'
      state.checkoutDeliveryRes.data = action.payload
    })
    builder.addCase(checkoutDeliveryRequest.rejected, (state) => {
      state.checkoutDeliveryRes.status = 'failed'
      state.checkoutDeliveryRes.data = {}
    })
    builder.addCase(verifyPayment.pending, (state) => {
      state.verifyPaymentStatus = 'loading'
    })
    builder.addCase(verifyPayment.fulfilled, (state) => {
      state.verifyPaymentStatus = 'succeeded'
    })
    builder.addCase(verifyPayment.rejected, (state, action) => {
      state.verifyPaymentStatus = 'failed'
    })
  },
})

export const {
  setInitialVerifyPaymentStatus,
  setInitialCheckoutPickupRes,
  setInitialCheckoutDeliveryRes,
} = checkoutSlice.actions

export const selectCheckoutPickupRes = (state: RootState) =>
  state.checkout.checkoutPickupRes

export const selectCheckoutDeliveryRes = (state: RootState) =>
  state.checkout.checkoutDeliveryRes

export const selectVerifyPaymentStatusRes = (state: RootState) =>
  state.checkout.verifyPaymentStatus

export default checkoutSlice.reducer
