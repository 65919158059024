import styled from 'styled-components'
import { setBoxShadow, setFlex } from '../../styles/themeStyles'
import { RiEyeOffFill, RiEyeFill } from 'react-icons/ri'

export const Wrapper = styled.div`
  ${setFlex('flex-start', 'flex-start')};
  flex-direction: column;
  width: 100%;
  margin: 10px 0;
`
export const Label = styled.span`
  font-size: 14px;
  margin-bottom: 5px;
  font-family: 'Cardo';
`

interface IInputWrapper {
  disabled: boolean
}
export const InputWrapper = styled.div<IInputWrapper>`
  padding: 10px 20px;
  ${setFlex('space-between')};
  width: 100%;
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey3 : theme.colors.white};
  ${setBoxShadow};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'initial')};
`

export const Input = styled.input<IInputWrapper>`
  font-size: 16px;
  width: 100%;
  font-family: 'Cardo';
  background-color: ${({ theme, disabled }) =>
    disabled ? theme.colors.grey3 : theme.colors.white};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'initial')};
  &::placeholder {
    font-family: 'Cardo';
  }
`
export const ShowPassword = styled(RiEyeFill)`
  height: 16px;
  width: 16px;
  fill: ${({ theme }) => theme.textColor};
  margin-right: 5px;
`
export const HidePassword = styled(RiEyeOffFill)`
  height: 16px;
  width: 16px;
  fill: ${({ theme }) => theme.textColor};
  margin-right: 5px;
`
export const TogglePassword = styled.button``

export const RequireStar = styled.span`
  color: ${({ theme }) => theme.colors.red};
  padding: 0 3px;
`
