import { css } from 'styled-components'

export const setFlex = (x: string = 'center', y: string = 'center') => css`
  display: flex;
  align-items: ${y};
  justify-content: ${x};
`

export const setBoxShadow = css`
  box-shadow: rgba(17, 12, 46, 0.15) 0px 48px 100px 0px;
`
export const setBoxShadowHover = css`
  box-shadow: rgba(255, 255, 255, 0.1) 0px 1px 1px 0px inset,
    rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;
`

export const setTransition = (
  target: string = 'all',
  duration: string = '0.2s',
  effect: string = 'cubic-bezier(0.21, 0.69, 0.83, 0.21)'
) => css`
  -webkit-transition: ${target} ${duration} ${effect};
  -moz-transition: ${target} ${duration} ${effect};
  transition: ${target} ${duration} ${effect};
`

export const devices = {
  smallMobile: '@media screen and (max-width: 375px)',
  mobile: '@media screen and (max-width: 750px)',
  tablet: '@media screen and (max-width: 1200px)',
}

export const baseTheme = {
  colors: {
    grey1: '#aaa7a2',
    grey2: '#5e5553',
    grey3: '#EAEAEA',
    grey4: '#F5F5F5',
    brown1: '#595959',
    brown2: '#BCB4AD',
    brown3: '#efede4',
    brown4: '#d0cecf',
    brown5: 'rgb(239, 237, 228);',
    black: '#000000',
    white: '#ffffff',
    red: '#D0312D',
    whiteTransparent: 'rgba(256,256,256,0.6)',
    queue: '#4e6172',
    success: '#82ac7c',
    pending: '#edcc8b',
    cancel: '#84342d',
  },

  devices,
}

export const lightTheme = {
  ...baseTheme,
  backgroundColor: '#EFEDE4',
  textColor: baseTheme.colors.brown1,
}
