import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import {
  DELETE_ORDER_API,
  ME_API,
  ORDER_API,
  POINT_RATE_API,
} from '../../utils/API'
import { getLocalStorage, setLocalStorage } from '../../utils/localStorage'

export const getUserInfo = createAsyncThunk(
  'account/getUserInfo',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.get(ME_API, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

interface IEditAccount {
  firstname: string
  lastname: string
  gender: string
  email?: string
  dob: string
}
export const editAccountRequest = createAsyncThunk(
  'account/editAccountRequest',
  async (
    { firstname, lastname, gender, email, dob }: IEditAccount,
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response = await axios({
        method: 'patch',
        url: ME_API,
        data: {
          firstname,
          lastname,
          email,
          gender,
          dob,
        },
        headers: { Authorization: `Bearer ${local.login.token}` },
      })

      return response.data
    } catch (err) {
      setLocalStorage({
        login: {
          token: '',
          isLoggedIn: false,
          expired: true,
        },
      })

      if (!err.response) {
        throw err
      }

      return rejectWithValue(err.response)
    }
  }
)

export const getHistory = createAsyncThunk(
  'account/getHistory',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.get(`${ORDER_API}/me`, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      const sortedData = response.data.sort((a, b) =>
        a.createdAt > b.createdAt ? -1 : 1
      )
      return sortedData
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const cancelOrder = createAsyncThunk(
  'account/cancelOrder',
  async (id: string, { rejectWithValue, dispatch }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.delete(`${DELETE_ORDER_API}/${id}`, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      dispatch(getHistory())
      return response
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const deleteAccount = createAsyncThunk(
  'account/deleteAccount',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.delete(ME_API, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      return response
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

export const getPointRate = createAsyncThunk(
  'account/getPonitRate',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response: any = await axios.get(POINT_RATE_API, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
