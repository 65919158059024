import { useState, useCallback, useEffect } from 'react'
import * as Styled from './ResetPassword.styled'
import Button from '../../component/Button'
import Input from '../../component/Input'
import Header from '../../component/Header'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { selectResetPasswordResponse } from './resetPasswordSlice'
import { changePasswordRequest } from './actions'

const ResetPassword = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState('')
  const [password, setPassword] = useState('')
  const [currentPassword, setCurrentPassword] = useState('')
  const dispatch = useAppDispatch()
  const resetPasswordStatus = useAppSelector(selectResetPasswordResponse)

  useEffect(() => {
    if (resetPasswordStatus.status === 'failed') {
      setErrorMessage('Wrong current password')
    } else if (resetPasswordStatus.status === 'succeeded') {
      setErrorMessage('')
      setPassword('')
      setCurrentPassword('')
    }
    return () => {
      setErrorMessage('')
    }
  }, [resetPasswordStatus.status])

  const onChange = (e: any, type: string): void => {
    const { value } = e.target
    if (type === 'password') {
      setPassword(value)
    } else {
      setCurrentPassword(value)
    }
  }

  const checkIsAllInputValid = (): boolean => {
    let message = ''
    if (password === '' || currentPassword === '') {
      message = 'Please enter required fields'
    }
    setErrorMessage(message)
    return message === ''
  }

  const onSubmit = (): void => {
    const isValid = checkIsAllInputValid()
    if (isValid) {
      dispatch(
        changePasswordRequest({
          password: currentPassword,
          newPassword: password,
        })
      )
    }
  }

  const renderErrorMessage = useCallback((): JSX.Element => {
    if (errorMessage.length > 0) {
      return (
        <Styled.ErrorMessage>
          <Styled.ErrorIcon />
          {errorMessage}
        </Styled.ErrorMessage>
      )
    } else {
      return <></>
    }
  }, [errorMessage])

  return (
    <Styled.ResetPassword>
      <Header text='Reset Password' />
      {resetPasswordStatus.status === 'succeeded' ? (
        <Styled.SuccessMessage>
          Password Updated! Your password has been changed successfully
        </Styled.SuccessMessage>
      ) : (
        <>
          <Input
            onChange={(e: any) => onChange(e, 'currentPassword')}
            value={currentPassword}
            label='Current Password'
            type='password'
            placeholder='Current Password'
            isRequire
          />
          <Input
            onChange={(e: any) => onChange(e, 'password')}
            value={password}
            label='New Password'
            type='password'
            placeholder='New Password'
            isRequire
          />
        </>
      )}
      {renderErrorMessage()}
      {resetPasswordStatus.status !== 'succeeded' ? (
        <Button onClick={onSubmit}>Change Password</Button>
      ) : (
        <></>
      )}
    </Styled.ResetPassword>
  )
}
export default ResetPassword
