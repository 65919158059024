import { useState, useCallback, useEffect } from 'react'
import * as Styled from './ForgotPassword.styled'
import Button from '../../component/Button'
import Input from '../../component/Input'
import Header from '../../component/Header'
import OTPInput from '../../component/OTPInput'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import { setModal } from '../../container/App/appSlice'
import validator from 'validator'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import {
  verifyPhoneNumberRequest,
  resendForgotPasswordOTP,
  verifyForgotPasswordOtpRequest,
  setPasswordRequest,
} from './actions'
import {
  selectVerifyPhoneToken,
  selectOTPToken,
  selectSetPasswordResponse,
} from './forgotPasswordSlice'

const ForgotPassword = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const verifyPhoneToken = useAppSelector(selectVerifyPhoneToken)
  const verifyOtpToken = useAppSelector(selectOTPToken)
  const setPasswordToken = useAppSelector(selectSetPasswordResponse)

  const [errorMessage, setErrorMessage] = useState('')
  const [phoneNo, setPhoneNo] = useState('')
  const [otp, setOtp] = useState('')
  const [newPassword, setNewPassword] = useState('')
  const [activeStep, setActiveStep] = useState(0)

  const onChange = (e: any, type: string): void => {
    const { value } = e.target
    if (type === 'phoneNo') {
      setPhoneNo(value)
    } else if (type === 'otp') {
      setOtp(value)
    } else {
      setNewPassword(value)
    }
  }

  useEffect(() => {
    if (verifyPhoneToken.status === 'succeeded') {
      setActiveStep(1)
      setErrorMessage('')
    } else if (verifyPhoneToken.status === 'failed') {
      setErrorMessage('This phone has not been registered')
    }
  }, [verifyPhoneToken])

  useEffect(() => {
    if (verifyOtpToken.status === 'succeeded') {
      setActiveStep(2)
      setErrorMessage('')
    } else if (verifyOtpToken.status === 'failed') {
      setErrorMessage('Invalid OTP')
    }
  }, [verifyOtpToken])

  useEffect(() => {
    if (setPasswordToken.status === 'succeeded') {
      setActiveStep(3)
      setErrorMessage('')
    } else if (setPasswordToken.status === 'failed') {
      setErrorMessage('Something went wrong. Please try again...')
    }
  }, [setPasswordToken])

  const onVerifyPhoneNumber = () => {
    if (!validator.isMobilePhone(phoneNo, 'th-TH')) {
      setErrorMessage('Please fill in valid phone number')
    } else {
      dispatch(verifyPhoneNumberRequest(phoneNo))
    }
  }

  const onVerifyOTP = () => {
    dispatch(
      verifyForgotPasswordOtpRequest({ otp, token: verifyPhoneToken.token })
    )
  }

  const onSubmit = (): void => {
    if (newPassword !== '') {
      dispatch(
        setPasswordRequest({
          password: newPassword,
          token: verifyOtpToken.token,
        })
      )
    }
  }

  const renderErrorMessage = useCallback((): JSX.Element => {
    if (errorMessage.length > 0) {
      return (
        <Styled.ErrorMessage>
          <Styled.ErrorIcon />
          {errorMessage}
        </Styled.ErrorMessage>
      )
    } else {
      return <></>
    }
  }, [errorMessage])

  const renderFirstStep = () => {
    return (
      <>
        <Input
          onChange={(e: any) => onChange(e, 'phoneNo')}
          value={phoneNo}
          label='Phone no.'
          type='text'
          placeholder='Phone number'
          isRequire
        />
        {renderErrorMessage()}
        <Button onClick={onVerifyPhoneNumber}>Verify phone number</Button>
      </>
    )
  }

  const renderSeconderStep = () => {
    return (
      <>
        <OTPInput
          value={otp}
          onChange={(value) => setOtp(value)}
          resendFn={() =>
            dispatch(resendForgotPasswordOTP(verifyPhoneToken.token))
          }
        />
        {renderErrorMessage()}
        <Button onClick={onVerifyOTP}>Verify OTP</Button>
      </>
    )
  }

  const renderThirdStep = () => {
    return (
      <>
        <Input
          onChange={(e: any) => onChange(e, 'newPassword')}
          value={newPassword}
          label='New password'
          type='password'
          placeholder='New password'
          isRequire
        />
        {renderErrorMessage()}
        <Button onClick={onSubmit}>Submit</Button>
      </>
    )
  }

  const renderFinalStep = () => {
    return (
      <>
        <Styled.SuccessMessage>
          Congratulations! Your password has been successfully changed.
        </Styled.SuccessMessage>
        <Button
          onClick={() => dispatch(setModal({ isShow: true, type: 'login' }))}
        >
          Login
        </Button>
      </>
    )
  }

  const steps = [
    'Verify Phone number',
    'Verify OTP',
    'Set new password',
    'Set password Success',
  ]

  const getActiveContent = () => {
    let content
    if (activeStep === 0) {
      content = renderFirstStep()
    } else if (activeStep === 1) {
      content = renderSeconderStep()
    } else if (activeStep === 2) {
      content = renderThirdStep()
    } else {
      content = renderFinalStep()
    }
    return content
  }

  return (
    <Styled.ForgotPassword>
      <Header text='Forget Password' />
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          return (
            <Step key={step} completed={index < activeStep || activeStep === 3}>
              <StepLabel>{step}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {getActiveContent()}
    </Styled.ForgotPassword>
  )
}
export default ForgotPassword
