import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { addToCart } from '../MenuDetail/actions'
import {
  login,
  logout,
  checkIsLoggedIn,
  verifyLoginOtpRequest,
  clearLogInStorage,
  getCart,
  getPickupSchedule,
  getDeliverySchedule,
  removeCartItem,
  updateQuantity,
} from './actions'

export interface IAppState {
  login: {
    isLoggedIn: boolean
    token: string
    status: string
    error: any
    meta: any
    expired: boolean
  }
  modal: {
    isShow: boolean
    type:
      | 'login'
      | 'forgotPassword'
      | 'resetPassword'
      | 'register'
      | 'loginRequired'
      | 'privacy-policy'
      | 'user-agreement'
      | ''
  }
  cart: {
    status: string
    data: any[]
    total: number
    totalPrice: number
  }
  pickupSchedule: {
    status: string
    data: any[]
  }
  deliveySchedule: {
    status: string
    data: any[]
  }
  addToCartStatus: string
  removeFromCartStatus: string
  updateQuantityStatus: string
}
const loginState = checkIsLoggedIn()

const initialState: IAppState = {
  login: {
    isLoggedIn: loginState?.isLoggedIn,
    token: loginState?.token,
    expired: loginState?.expired,
    status: 'idle',
    error: {},
    meta: {},
  },
  modal: {
    isShow: false,
    type: '',
  },
  cart: {
    status: 'idle',
    data: [],
    total: 0,
    totalPrice: 0,
  },
  pickupSchedule: {
    status: 'idle',
    data: [],
  },
  deliveySchedule: {
    status: 'idle',
    data: [],
  },
  addToCartStatus: 'idle',
  removeFromCartStatus: 'idle',
  updateQuantityStatus: 'idle',
}

export const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setModal: (state, action) => {
      state.modal.isShow = action.payload.isShow
      state.modal.type = action.payload.type
    },
    resetLoginRequest: (state) => {
      clearLogInStorage()
      state.login = initialState.login
    },
    setAddToCartIdle: (state) => {
      state.addToCartStatus = 'idle'
    },
    setRemoveFromCartIdle: (state) => {
      state.removeFromCartStatus = 'idle'
    },
    setUpdateQuantityIdle: (state) => {
      state.updateQuantityStatus = 'idle'
    },
  },
  extraReducers: (builder) => {
    builder.addCase(login.pending, (state) => {
      state.login.status = 'loading'
    })
    builder.addCase(login.fulfilled, (state, action) => {
      state.login.status = 'succeeded'
      state.login.token = action.payload.token
      state.login.isLoggedIn = action.payload.isLoggedIn
      state.login.expired = action.payload.expired
      state.login.meta = action.meta
    })
    builder.addCase(login.rejected, (state, action) => {
      state.login.status = 'failed'
      state.login.token = ''
      state.login.isLoggedIn = false
      state.login.error = action.payload
      state.login.meta = action.meta
    })

    builder.addCase(verifyLoginOtpRequest.pending, (state) => {
      state.login.status = 'loading'
    })
    builder.addCase(verifyLoginOtpRequest.fulfilled, (state, action) => {
      state.login.status = 'succeeded'
      state.login.token = action.payload.token
      state.login.isLoggedIn = action.payload.isLoggedIn
      state.login.expired = action.payload.expired
    })
    builder.addCase(verifyLoginOtpRequest.rejected, (state, action) => {
      state.login.status = 'failed'
      state.login.token = ''
      state.login.isLoggedIn = false
      state.login.error = action.payload
      state.login.meta = action.meta
    })

    builder.addCase(logout.pending, (state) => {
      state.login.status = 'loading'
      builder.addCase(logout.fulfilled, (state) => {
        state.login.status = 'succeeded'
        state.login.token = loginState?.token
        state.login.isLoggedIn = loginState?.isLoggedIn
      })
    })

    builder.addCase(getCart.pending, (state) => {
      state.cart.status = 'loading'
    })
    builder.addCase(getCart.fulfilled, (state, action) => {
      state.cart.status = 'succeeded'
      state.cart.data = action.payload
      state.cart.total = action.payload.reduce(
        (accumulator, currentValue) => accumulator + +currentValue.quantity,
        0
      )
      state.cart.totalPrice = action.payload
        .reduce(
          (accumulator, currentValue) =>
            accumulator + +currentValue.totalPrice.toFixed(2),
          0
        )
        .toFixed(2)
    })
    builder.addCase(getCart.rejected, (state) => {
      state.cart.status = 'failed'
    })

    builder.addCase(getPickupSchedule.pending, (state) => {
      state.pickupSchedule.status = 'loading'
    })
    builder.addCase(getPickupSchedule.fulfilled, (state, action) => {
      state.pickupSchedule.status = 'succeeded'
      state.pickupSchedule.data = action.payload
    })
    builder.addCase(getPickupSchedule.rejected, (state) => {
      state.pickupSchedule.status = 'failed'
      state.pickupSchedule.data = []
    })
    builder.addCase(getDeliverySchedule.pending, (state) => {
      state.deliveySchedule.status = 'loading'
    })
    builder.addCase(getDeliverySchedule.fulfilled, (state, action) => {
      state.deliveySchedule.status = 'succeeded'
      state.deliveySchedule.data = action.payload
    })
    builder.addCase(getDeliverySchedule.rejected, (state) => {
      state.deliveySchedule.status = 'failed'
      state.deliveySchedule.data = []
    })

    builder.addCase(addToCart.pending, (state) => {
      state.addToCartStatus = 'loading'
    })
    builder.addCase(addToCart.fulfilled, (state, action) => {
      state.addToCartStatus = 'succeeded'
    })
    builder.addCase(addToCart.rejected, (state) => {
      state.addToCartStatus = 'failed'
    })

    builder.addCase(removeCartItem.pending, (state) => {
      state.removeFromCartStatus = 'loading'
    })
    builder.addCase(removeCartItem.fulfilled, (state, action) => {
      state.removeFromCartStatus = 'succeeded'
    })
    builder.addCase(removeCartItem.rejected, (state) => {
      state.removeFromCartStatus = 'failed'
    })

    builder.addCase(updateQuantity.pending, (state) => {
      state.updateQuantityStatus = 'loading'
    })
    builder.addCase(updateQuantity.fulfilled, (state, action) => {
      state.updateQuantityStatus = 'succeeded'
    })
    builder.addCase(updateQuantity.rejected, (state) => {
      state.updateQuantityStatus = 'failed'
    })
  },
})

export const {
  setModal,
  resetLoginRequest,
  setAddToCartIdle,
  setRemoveFromCartIdle,
  setUpdateQuantityIdle,
} = appSlice.actions

export const selectLogin = (state: RootState) => state.app.login
export const selectModal = (state: RootState) => state.app.modal
export const selectCart = (state: RootState) => state.app.cart
export const selectAddToCartStatus = (state: RootState) =>
  state.app.addToCartStatus
export const selectRemoveFromCartStatus = (state: RootState) =>
  state.app.removeFromCartStatus
export const selectUpdateQuantityStatus = (state: RootState) =>
  state.app.updateQuantityStatus
export const selectPickupSchedule = (state: RootState) =>
  state.app.pickupSchedule
export const selectDeliverySchedule = (state: RootState) =>
  state.app.deliveySchedule

export default appSlice.reducer
