import styled from 'styled-components'
import { setFlex } from '../../styles/themeStyles'
import Button from '../Button'

export const Wrapper = styled.div`
  ${setFlex()};
  flex-direction: column;
  padding: 20px;
  text-align: center;
`
export const LoginButton = styled(Button)``

export const Text = styled.div`
  font-size: 20px;
  padding: 20px;
`
export const Buttons = styled.div`
  ${setFlex('space-between')};
  width: 100%;
  flex-direction: column;
`
export const TextOr = styled.p`
  font-size: 16px;
`
