import styled from 'styled-components'
import {
  setBoxShadow,
  setBoxShadowHover,
  setTransition,
  setFlex,
} from '../../styles/themeStyles'

import { IoAddCircle } from 'react-icons/io5'

import Button from '../Button'

export const Menu = styled.div`
  ${setFlex('space-between')};
  flex-direction: column;
  padding: 20px;
  ${setBoxShadow};
  border-radius: 5px;
  margin: 10px;
  width: 290px;
  height: 490px;
  ${setTransition()};
  cursor: initial;
  background-color: ${({ theme }) => theme.colors.white};
  &:hover {
    ${setBoxShadowHover};
  }
`
export const Image = styled.img`
  border-radius: 5px;
  width: 250px;
  object-fit: cover;
  height: 250px;
  margin: auto;
  ${setTransition()};
  background-color: ${({ theme }) => theme.colors.grey3};
  background-size: 250px;
  position: absolute;
`

export const ImageWrapper = styled.div`
  ${setFlex()};
`
export const OutOfStock = styled.div<{ isActive: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  width: 250px;
  height: 250px;
  background-color: rgba(0, 0, 0, 0.7);
  ${setFlex()};
  border-radius: 5px;
  position: relative;
  opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  ${setTransition()};
`

export const Name = styled.div`
  font-size: 24px;
  text-align: start;
  font-weight: bold;
  text-transform: capitalize;
`

export const Description = styled.p`
  margin: 8px 0;
  color: ${({ theme }) => theme.colors.grey1};
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 125%;
  width: 100%;
  text-align: left;
  font-size: 14px;
  min-height: 34px;
  font-family: 'Cardo';
`

export const Price = styled.div`
  text-align: center;
  font-size: 18px;
  color: ${({ theme }) => theme.colors.brown2};
  font-weight: bold;
`

export const NameType = styled.div`
  margin-top: 20px;
  ${setFlex('flex-start', 'flex-start')};
  flex-direction: column;
`

export const SubMenuWrapper = styled.div`
  ${setFlex('flex-start')};
  min-height: 20px;
  flex-wrap: wrap;
`

interface ISubMenu {
  isActive: boolean
}

export const SubMenu = styled.button<ISubMenu>`
  color: ${({ theme }) => theme.colors.brown1};
  font-weight: ${({ isActive }) => (isActive ? 'bold' : 'normal')};
  font-size: 16px;
  font-family: 'Cardo';
  &:not(:last-child) {
    &::after {
      content: '/';
      padding: 0 10px;
      font-weight: normal;
    }
  }
`

export const ButtonWrapper = styled.div`
  ${setFlex('space-between')};
  width: 100%;
`

const buttonProps = `
text-transform: capitalize;
font-size: 12px;
width: fit-content;
`
export const AddToCartButton = styled(Button)`
  border-radius: 50%;
  width: 30px;
  height: 30px;
  ${buttonProps};
`
export const AddButton = styled.button`
  cursor: pointer;
  width: 30px;
  height: 30px;
  &:disabled {
    cursor: not-allowed;
  }
`
export const AddButtonIcon = styled(IoAddCircle)`
  fill: ${({ theme }) => theme.colors.brown2};
  width: 30px;
  height: 30px;
  ${setTransition()};
  &:hover {
    transform: scale(1.02);
  }
  &:active {
    transform: scale(0.98);
  }
`
