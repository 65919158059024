import { createGlobalStyle } from 'styled-components'
import { ThemeType } from './themeProvider'
import { setTransition } from './themeStyles'

import SkModernistRegular from './fonts/SkModernistRegular.otf'
import SkModernistBold from './fonts/SkModernistBold.otf'

import CardoRegular from './fonts/Cardo.ttf'
import CardoBold from './fonts/CardoBold.ttf'

import SpartanRegular from './fonts/Spartan.ttf'
import SpartanBold from './fonts/SpartanBold.ttf'

export default createGlobalStyle<{ theme: ThemeType }>`
   @font-face {
    font-family: 'Sk-Modernist';
    src: url(${SkModernistRegular});
    font-weight: normal;
   }     
   @font-face {
    font-family: 'Sk-Modernist';
    src: url(${SkModernistBold});
    font-weight: bold;
   }  
   @font-face {
    font-family: 'Cardo';
    src: url(${CardoRegular});
    font-weight: normal;
   }     
   @font-face {
    font-family: 'Cardo';
    src: url(${CardoBold});
    font-weight: bold;
   }   
   @font-face {
    font-family: 'Spartan';
    src: url(${SpartanRegular});
    font-weight: normal;
   }     
   @font-face {
    font-family: 'Spartan';
    src: url(${SpartanBold});
    font-weight: bold;
   } 

  *{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    color: ${({ theme }) => theme.textColor};
    font-family: 'Sk-Modernist', 'Prompt', serif;
  }
  body {
    font-size: 16px;
    font-weight: 400;
    word-break: break-word;
    overflow-x: hidden;
    background-color: ${({ theme }) => theme.backgroundColor};
  }

  a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    outline: none;
    border: none;
    &:hover{
      color: ${({ theme }) => theme.colors.brown2};
    }
  }

  input {
    font-size: 16px;
    outline: none;
    border: none;
  } 
  p{
    margin:0;
    padding:0;
  }

  button {
    cursor: pointer;
    outline: none;
    border: none;
    background-color: transparent;
    ${setTransition()};
  }

  input:focus,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  html{
    --antd-wave-shadow-color: #BCB4AD;
  }
  .ant-dropdown-menu-title-content{
    a{
      font-family:'Cardo'
    }
  }
  .ant-picker-focused {
    border-color: #BCB4AD;
    box-shadow: 0 0 0 2px rgb(188, 180, 173,0.2);
  }
  .ant-picker:hover, .ant-picker-focused{
    border-color: #BCB4AD;
  }
  .ant-picker-time-panel-column > li.ant-picker-time-panel-cell-selected .ant-picker-time-panel-cell-inner{
    box-shadow: 0 0 0 2px rgb(188, 180, 173,0.5);
  }
  .ant-btn-primary{
    border-color: #BCB4AD;
    background-color: #BCB4AD;
  }
  .ant-picker-today-btn{
    color: #BCB4AD;
  }
  &::selection{
    background: #BCB4AD;
  }
  .ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before{
    border-color: #BCB4AD;
  }
  .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner{
    background: #BCB4AD;
  }
  .ant-input:hover{
    border-color:#BCB4AD;
  }
  .ant-input:focus, .ant-input-focused{
    border-color:#BCB4AD;
    box-shadow: 0 0 0 2px rgb(188, 180, 173,0.2);
  }
  .ant-dropdown-menu-item,
   .ant-dropdown-menu-submenu-title{
    &:hover{
      background-color: rgba(188, 180, 173,0.6);
      color: red !important;
    }
  }
  .ant-checkbox{
    border-color:#BCB4AD;
    &:hover,
    &:focus > .ant-checkbox-inner{
      border-color:#BCB4AD;
    }
  }
  .ant-checkbox-checked::after{
    border-color:#BCB4AD;
    &:hover,&:focus{
      border-color:#BCB4AD;
    }
  }
  .ant-checkbox-inner{
    border-color:#BCB4AD;
  }
  .ant-checkbox-input{
    border-color:#BCB4AD;

  }
  .ant-checkbox-checked .ant-checkbox-inner{
    border-color:#BCB4AD;
    background-color: #BCB4AD;
  }
  .ant-checkbox-wrapper{
    padding:10px 0;
  }
  .ant-checkbox-wrapper:hover .ant-checkbox-inner, .ant-checkbox:hover .ant-checkbox-inner, .ant-checkbox-input:focus + .ant-checkbox-inner{
    border-color:#BCB4AD;
  }

  .ant-message-notice{
    display: flex;
    align-items:center;
    justify-content:center;
    width: 100vw;
    height: 100vh;
    top:0;
    left:0;
  }
  .ant-message-notice-content{
    width:200px;
    height:100px;
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;   
  }
  .ant-message-custom-content{
    display: flex;
    align-items:center;
    justify-content:center;
    flex-direction: column;   
    svg{
      margin-bottom: 5px;
      width: 20px;
      height: 20px;
    }
  }
  .ant-message-error svg{
    fill:${({ theme }) => theme.colors.red};
  } 
  .ant-message-success svg{
    fill:${({ theme }) => theme.colors.success};
  }
`
