import { useAppDispatch } from '../../Hooks/hooks'
import * as Styled from './LoginRequiredModal.styled'
import { setModal } from '../../container/App/appSlice'
import RemedyLogo from '../RemedyLogo'
const LoginRequiredModal = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const handleLogin = () => {
    dispatch(setModal({ isShow: true, type: 'login' }))
  }
  const handleRegister = () => {
    dispatch(setModal({ isShow: true, type: 'register' }))
  }
  return (
    <Styled.Wrapper>
      <RemedyLogo height='60px' />
      <Styled.Text>
        Please login using your phone number to access the cart.
      </Styled.Text>
      <Styled.Buttons>
        <Styled.LoginButton onClick={handleLogin}>Login</Styled.LoginButton>
        <Styled.TextOr>or</Styled.TextOr>
        <Styled.LoginButton onClick={handleRegister}>
          Register
        </Styled.LoginButton>
      </Styled.Buttons>
    </Styled.Wrapper>
  )
}
export default LoginRequiredModal
