import { DatePicker, TimePicker } from 'antd'
import { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { getPickupSchedule } from '../App/actions'
import { selectPickupSchedule } from '../App/appSlice'
import * as Styled from './Checkout.styled'
import moment from 'moment-timezone'
import { ISchedule } from '.'

moment.tz.setDefault('Asia/Bangkok')

const DATE_FORMAT: string = 'DD MMMM YYYY'
const TIME_FORMAT: string = 'HH:mm'
const HOUR_FORMAT: string = 'HH'
const MINUTE_FORMAT: string = 'mm'
const MINUTES_ADVANCE: number = 11

const Pickup = ({
  selectedPickupDate,
  setSelectedPickupDate,
  selectedPickupTime,
  setSelectedPickupTime,
  initSelectedPickupDate,
  setPickupItems,
}) => {
  const dispatch = useAppDispatch()

  const pickupSchedule = useAppSelector(selectPickupSchedule)
  const [scheduleList, setScheduleList] = useState<ISchedule[]>([])

  useEffect(() => {
    dispatch(getPickupSchedule())
    //eslint-disable-next-line
  }, [])

  const setDefaultSelectTime = (closeTime) => {
    const now = moment().add(MINUTES_ADVANCE, 'minutes')
    if (now.isAfter(closeTime)) {
      setSelectedPickupTime(moment(closeTime))
    } else {
      setSelectedPickupTime(moment().add(MINUTES_ADVANCE, 'minutes'))
    }
  }

  useEffect(() => {
    if (pickupSchedule.status === 'succeeded') {
      if (pickupSchedule.data.length) {
        const formatSchedules = pickupSchedule.data.map((schedule) => ({
          date: moment(schedule.date),
          open: moment(schedule.openTime),
          close: moment(schedule.closeTime),
          id: schedule._id,
        }))
        setScheduleList(formatSchedules)
        setSelectedPickupDate(formatSchedules[0])
        setPickupItems({
          open: formatSchedules[0].open,
          close: formatSchedules[0].close,
        })
        if (
          formatSchedules[0].date.format(DATE_FORMAT) ===
            moment().format(DATE_FORMAT) &&
          moment().isSameOrAfter(formatSchedules[0].open) &&
          moment().isSameOrBefore(formatSchedules[0].close)
        ) {
          setDefaultSelectTime(formatSchedules[0].close)
        } else {
          setSelectedPickupTime(moment(formatSchedules[0].open))
        }
      }
    }
    //eslint-disable-next-line
  }, [pickupSchedule.status])

  const getDisabledDate = (current) => {
    let isDisabled
    if (current && scheduleList.length) {
      isDisabled = scheduleList.find(
        ({ date }) => date.format(DATE_FORMAT) === current.format(DATE_FORMAT)
      )
    }
    return !isDisabled
  }

  const isSameDate = (date1, date2 = undefined): boolean =>
    moment(date1).format(DATE_FORMAT) === moment(date2).format(DATE_FORMAT)

  const onSelectDate = (date) => {
    const dateItem = scheduleList.find((schedule) =>
      isSameDate(schedule.date, date)
    )
    setSelectedPickupDate(dateItem ?? initSelectedPickupDate)
    setPickupItems({
      open: dateItem?.open,
      close: dateItem?.close,
    })
    if (isSameDate(date)) {
      setDefaultSelectTime(moment(dateItem?.close))
    } else {
      setSelectedPickupTime(moment(dateItem?.open))
    }
  }

  const onSelectTime = (time) => {
    setSelectedPickupTime(moment(time))
  }

  const getDisabledHour = () => {
    const open = moment(selectedPickupDate.open).format(HOUR_FORMAT)
    const close = moment(selectedPickupDate.close).format(HOUR_FORMAT)
    const now = moment().add(MINUTES_ADVANCE, 'minutes').format(HOUR_FORMAT)
    const isToday =
      moment(selectedPickupDate.open).format(DATE_FORMAT) ===
      moment().format(DATE_FORMAT)
    const hrs: number[] = []

    for (let i = 24; i >= 0; i--) {
      if (isToday) {
        if (i < +now || i > +close) {
          hrs.push(i)
        }
      } else if (!isToday) {
        if (i < +open || i > +close) {
          hrs.push(i)
        }
      }
    }
    return hrs
  }

  const getDisabledMinutes = (selectedHour) => {
    const open = moment(selectedPickupDate.open)
    const close = moment(selectedPickupDate.close)
    const openHr = open.format(HOUR_FORMAT)
    const closeHr = close.format(HOUR_FORMAT)
    const openMin = open.format(MINUTE_FORMAT)
    const closeMin = close.format(MINUTE_FORMAT)
    const mins: number[] = []
    for (let i = 0; i < 60; i++) {
      if (selectedHour === +openHr && i < +openMin) {
        mins.push(i)
      } else if (selectedHour === +closeHr && i > +closeMin) {
        mins.push(i)
      }
    }
    return mins
  }
  return (
    <Styled.BoxWrapper>
      {pickupSchedule.status === 'succeeded' && pickupSchedule.data.length ? (
        <>
          <h3>Pickup option:</h3>
          <Styled.Row>
            <span>Select Date:</span>
            <DatePicker
              disabledDate={getDisabledDate}
              onChange={onSelectDate}
              value={selectedPickupDate.date}
              allowClear={false}
              inputReadOnly
              format={DATE_FORMAT}
            />
          </Styled.Row>
          <Styled.Row>
            <span>Select Time:</span>
            <TimePicker
              format={TIME_FORMAT}
              disabledHours={getDisabledHour}
              disabledMinutes={getDisabledMinutes}
              onSelect={onSelectTime}
              value={selectedPickupTime}
              inputReadOnly
              allowClear={false}
              showNow={false}
            />
          </Styled.Row>
        </>
      ) : (
        <Styled.OutOfService>Out of Service</Styled.OutOfService>
      )}
    </Styled.BoxWrapper>
  )
}
export default Pickup
