const LOCAL_STORAGE_KEY = 'remedy-client-app'
export function setLocalStorage(
  data: object | null,
  flush: boolean = false
): void {
  try {
    if (flush) {
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(data))
    } else {
      const _oldData: any = localStorage.getItem(LOCAL_STORAGE_KEY)
      const oldData = JSON.parse(_oldData)
      const newData = { ...oldData, ...data }
      localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(newData))
    }
  } catch (error) {
    console.warn(`Failure setting local storage with ${data}: ${error.message}`)
  }
}

export function getLocalStorage(): Record<string, any> {
  try {
    const _persistedState: any = localStorage.getItem(LOCAL_STORAGE_KEY)
    return JSON.parse(_persistedState) || {}
  } catch (error) {
    setLocalStorage(null, true)
    return {}
  }
}
