import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import {
  FORGOT_PASSWORD_API,
  VERIFY_OTP_API,
  RESEND_OTP_API,
  SET_PASSWORD_API,
} from '../../utils/API'

interface IPassword {
  password: string
}

interface ISetPasswordRequest extends IPassword {
  token: string
}

export const verifyPhoneNumberRequest = createAsyncThunk(
  'forgotPassword/verifyPhoneNumber',
  async (phone: string, { rejectWithValue }): Promise<any> => {
    try {
      const response = await axios({
        method: 'post',
        url: FORGOT_PASSWORD_API,
        data: {
          phone,
        },
      })
      return response.data.token
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

interface IPayload {
  otp: string
  token: string
}

export const verifyForgotPasswordOtpRequest = createAsyncThunk(
  'forgotPassword/verifyForgotPasswordOtpRequest',
  async ({ otp, token }: IPayload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(VERIFY_OTP_API, {
        type: 'forgotPassword',
        token: otp,
        verifyOtpToken: token,
      })
      return response.data.token
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const setPasswordRequest = createAsyncThunk(
  'forgotPassword/setPasswordRequest',
  async (
    { token, password }: ISetPasswordRequest,
    { rejectWithValue }
  ): Promise<any> => {
    try {
      const response = await axios({
        method: 'post',
        url: SET_PASSWORD_API,
        data: {
          token,
          password,
        },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

export const resendForgotPasswordOTP = createAsyncThunk(
  'forgotPasswoed/resendForgotPasswordOTP',
  async (token: string, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(RESEND_OTP_API, {
        verifyOtpToken: token,
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
