import styled from 'styled-components'
import { Link } from 'react-router-dom'
import logo from '../../images/remedy_coffee_1.png'
interface IRemedyLogoProps {
  className?: string
  onClick?: any
  height?: string
  width?: string
}

const RemedyLogo = ({
  className,
  onClick,
  height = '35px',
  width = 'auto-fit',
}: IRemedyLogoProps): JSX.Element => {
  return (
    <RemedyIcon
      className={className}
      to='/'
      onClick={onClick}
      height={height}
      width={width}
    >
      <img src={logo} alt='remedy' />
    </RemedyIcon>
  )
}

const RemedyIcon = styled(Link)<IRemedyLogoProps>`
  font-size: 20px;
  letter-spacing: 3px;
  padding: 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: ${({ width }) => width};
    height: ${({ height }) => height};
  }
`
export default RemedyLogo
