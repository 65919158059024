import React from 'react'

import numbro from 'numbro'

interface INumberCounterProps {
  target: number
  duration?: number
}

function NumberCounter({
  target,
  duration = 50,
}: INumberCounterProps): JSX.Element {
  const [result, setResult] = React.useState<string>('0')

  React.useEffect(() => {
    const length = target > 100 ? 1000 : 100
    let count = 0

    const timer = setInterval(() => {
      const random = Math.floor(Math.random() * length)
      if (count < 3) {
        count++
        setResult(numbro(random).format({ thousandSeparated: true }))
      } else {
        setResult(numbro(target).format({ thousandSeparated: true }))
        clearInterval(timer)
      }
    }, duration)

    return () => clearInterval(timer)
  }, [target, duration])

  return <>{result}</>
}

export default NumberCounter
