import styled from 'styled-components'
import {
  setBoxShadow,
  setFlex,
  devices,
  setTransition,
} from '../../styles/themeStyles'
import { bounceIn, fadeIn } from '../../styles/animations'
import { CgClose } from 'react-icons/cg'

export const Overlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  z-index: 1000000;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  animation: ${fadeIn} 0.5s ease-in-out;
  ${setFlex()};
`
export const Modal = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 5px;
  animation: ${bounceIn} 0.5s ease-in-out;
  ${setBoxShadow};
  display: grid;
  grid-template-rows: 20px 1fr;
  width: 100%;
  max-width: 400px;
  padding-bottom: 10px;
  ${setTransition()};
  ${devices.tablet} {
    min-height: 80vh;
    max-height: 100vh;
  }
  ${devices.mobile} {
    transform: scale(0.8);
  }
  ${devices.smallMobile} {
    min-height: 80vh;
    max-height: 100vh;
  }
`
export const CloseButton = styled.button`
  justify-self: end;
  padding: 20px;
  z-index: 1;
`
export const CgCloseIcon = styled(CgClose)`
  width: 20px;
  height: 20px;
`

export const ChildrenWrapper = styled.div`
  padding-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
`
