const ENDPOINT: string | undefined = process.env.REACT_APP_API_URL
export const API_ENDPOINT: string | undefined = `${ENDPOINT}/api`

export const BANNER_URL = `${API_ENDPOINT}/banners`
export const RECOMMENDED_MENU_API = `${API_ENDPOINT}/menuRecommended`

export const MENU_URL = `${API_ENDPOINT}/menu`
export const SUB_MENU_URL = `${API_ENDPOINT}/subMenu`

export const GRANT_PHONE_API = `${API_ENDPOINT}/grantPhone`
export const VERIFY_OTP_API = `${API_ENDPOINT}/verifyOtp`
export const RESEND_OTP_API = `${API_ENDPOINT}/resendOtp`

export const LOGIN_URL = `${API_ENDPOINT}/login`
export const REGISTER_API = `${API_ENDPOINT}/register`
export const ME_API = `${API_ENDPOINT}/me`
export const POINT_RATE_API = `${API_ENDPOINT}/pointRate`

export const FORGOT_PASSWORD_API = `${API_ENDPOINT}/forgotPassword`
export const CHANGE_PASSWORD_API = `${API_ENDPOINT}/changePassword`
export const SET_PASSWORD_API = `${API_ENDPOINT}/setPassword`

export const CART_API = `${API_ENDPOINT}/cart`
export const DELIVERY_SCHEDULE_API = `${API_ENDPOINT}/deliverySchedule`
export const PICKUP_API = `${API_ENDPOINT}/order/user/pickup`
export const DELIVERY_API = `${API_ENDPOINT}/order/user/delivery`
export const VERIFY_PAYMENT_API = `${API_ENDPOINT}/order/verifyPayment`
export const ORDER_API = `${API_ENDPOINT}/orders`
export const DELETE_ORDER_API = `${API_ENDPOINT}/order`
