import { useState, useEffect } from 'react'
import * as Styled from './Login.styled'
import Button from '../../component/Button'
import Input from '../../component/Input'
import Header from '../../component/Header'
import { login, verifyLoginOtpRequest, resendLoginOTP } from '../App/actions'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { selectLogin, setModal, resetLoginRequest } from '../App/appSlice'
import OTPInput from '../../component/OTPInput'
import { message } from 'antd'

const Login = (): JSX.Element => {
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [showOtpPage, setShowOtpPage] = useState<boolean>(false)
  const [otp, setOtp] = useState<string>('')
  const [token, setToken] = useState<string>('')

  const dispatch = useAppDispatch()
  const loginState = useAppSelector(selectLogin)
  useEffect(() => {
    if (loginState.status === 'failed') {
      setErrorMessage('Incorrect Phone number or password')
      setPassword(loginState.meta.arg.password)
      setPhoneNumber(loginState.meta.arg.phone)
      setShowOtpPage(false)
    } else if (
      loginState.status === 'succeeded' &&
      !loginState.isLoggedIn &&
      loginState.expired
    ) {
      setToken(loginState.token)
      setShowOtpPage(true)
      setPassword(loginState.meta.arg.password)
      setPhoneNumber(loginState.meta.arg.phone)
    } else if (
      loginState.status === 'succeeded' &&
      loginState.isLoggedIn &&
      !loginState.expired
    ) {
      message.success('Successfully logged in')
      setShowOtpPage(false)
      dispatch(setModal({ isShow: false }))
    } else {
      setErrorMessage('')
      setPhoneNumber('')
      setPassword('')
      setShowOtpPage(false)
      setOtp('')
      setToken('')
    }

    //eslint-disable-next-line
  }, [loginState])

  const onChange = (e: any, type: string, onEnter?: any): void => {
    const { value } = e.target
    if (e.key === 'Enter') {
      onEnter && onEnter()
    } else if (type === 'phoneNumber') {
      setPhoneNumber(value)
    } else if (type === 'password') {
      setPassword(value)
    }
  }

  const checkIsAllInputValid = (): boolean => {
    let message = ''
    const pattern = /^\d+$/
    if (
      !(phoneNumber.length === 10 && pattern.test(phoneNumber)) ||
      password === ''
    ) {
      message = 'Incorrect Phone number or password'
    }
    setErrorMessage(message)
    return message === ''
  }

  const handleLogIn = (): void => {
    const isValid = checkIsAllInputValid()
    if (isValid) {
      dispatch(login({ phone: phoneNumber, password }))
    }
  }

  const handleVerifyOtp = (): void => {
    const pattern = /^\d+$/
    if (otp.length === 6 && pattern.test(otp)) {
      setErrorMessage('')
      dispatch(verifyLoginOtpRequest({ token, otp }))
    } else {
      setErrorMessage('Please enter invalid OTP')
    }
  }

  const renderLoginItems = () => (
    <>
      <Input
        onChange={(e: any) => onChange(e, 'phoneNumber', handleLogIn)}
        value={phoneNumber}
        label='Phone Number'
        type='text'
        placeholder='Phone Number'
        isRequire
        disabled={showOtpPage}
      />
      <Input
        onChange={(e: any) => onChange(e, 'password', handleLogIn)}
        value={password}
        label='Password'
        type='password'
        placeholder='Password'
        isRequire
        disabled={showOtpPage}
      />
      {showOtpPage ? renderOTPItems() : <></>}
      {renderErrorMessage()}
      {showOtpPage ? (
        <>
          <Button
            onClick={handleVerifyOtp}
            isLoading={loginState.status === 'loading'}
          >
            Verify OTP
          </Button>
          <Styled.Link
            width='100%'
            onClick={() => dispatch(resetLoginRequest())}
          >
            Login with another phone number
          </Styled.Link>
        </>
      ) : (
        <Button
          onClick={handleLogIn}
          isLoading={loginState.status === 'loading'}
        >
          LOGIN
        </Button>
      )}
    </>
  )

  const renderErrorMessage = () => {
    if (errorMessage.length > 0) {
      return (
        <Styled.ErrorMessage>
          <Styled.ErrorIcon />
          {errorMessage}
        </Styled.ErrorMessage>
      )
    } else {
      return <></>
    }
  }

  const renderOTPItems = () => (
    <OTPInput
      value={otp}
      onChange={(value) => setOtp(value)}
      resendFn={() => dispatch(resendLoginOTP({ token }))}
    />
  )

  return (
    <Styled.Login>
      <Header text='Log In' />
      <Styled.OTPWrapper>{renderLoginItems()}</Styled.OTPWrapper>

      <Styled.Footer>
        <Styled.Link
          onClick={() => dispatch(setModal({ isShow: true, type: 'register' }))}
        >
          Register a New Account
        </Styled.Link>
        <Styled.Link
          onClick={() =>
            dispatch(setModal({ isShow: true, type: 'forgotPassword' }))
          }
        >
          Forgot your password?
        </Styled.Link>
      </Styled.Footer>
    </Styled.Login>
  )
}
export default Login
