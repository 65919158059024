import styled from 'styled-components'
import { setFlex, setTransition } from '../../styles/themeStyles'
import { RiInformationLine } from 'react-icons/ri'
import { MotionContainer } from '../../styles/animations'

export const Register = styled(MotionContainer)`
  ${setFlex()};
  ${setTransition()};
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin: auto;
  padding: 20px;
  width: 100%;
  max-width: 500px;

  .MuiSvgIcon-root {
    fill: ${({ theme }) => theme.textColor};
    &.MuiStepIcon-active,
    &.MuiStepIcon-completed {
      fill: ${({ theme }) => theme.colors.brown2};
    }
  }
  .MuiStepLabel-labelContainer {
    span {
      font-family: 'Cardo';
    }
  }
`

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  align-self: flex-start;
  margin: 5px 0;
  font-size: 14px;
  ${setFlex('flex-start')};
  padding: 10px;
  width: 100%;
  ${setTransition()};
`

export const ErrorIcon = styled(RiInformationLine)`
  height: 14px;
  fill: ${({ theme }) => theme.colors.red};
  margin-right: 5px;
`

export const Footer = styled.div`
  ${setFlex()};
  width: 100%;
  margin: 5px 0;
  font-size: 14px;
`
export const Link = styled.button`
  padding: 5px;
  cursor: pointer;
  ${setTransition()};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.brown2};
  }
`
export const NameInputWrapper = styled.div`
  ${setFlex('space-between')};
`
export const NameWrapper = styled.div`
  ${setFlex('flex-start', 'flex-start')};
  flex-direction: column;
`
export const SuccessMessage = styled.p`
  text-align: center;
  font-size: 20px;
  padding: 30px 20px;
`
export const OTPMessage = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey1};
  margin-bottom: 5px;
  font-size: 14px;
  text-align: center;
`
export const GenderWrapper = styled.div`
  display: flex;
  width: 100%;
`
export const GenderLabel = styled.span`
  font-size: 14px;
  font-family: 'Cardo';
  width: 100px;
`
export const DateOfBirthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-picker {
    border: none;
    height: 45px;
    padding: 10px 20px;
    input {
      font-family: 'Cardo';
      color: ${({ theme }) => theme.textColor};
    }
  }
`
export const RequireStar = styled.span`
  color: ${({ theme }) => theme.colors.red};
  padding: 0 3px;
`
