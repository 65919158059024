import styled, { css } from 'styled-components'
import Button from '../../component/Button'
import { RiEBike2Line, RiStore2Line } from 'react-icons/ri'
import { devices, setFlex, setTransition } from '../../styles/themeStyles'
import { MotionContainer } from '../../styles/animations'

export const Checkout = styled.div`
  max-width: 60vw;
  width: 100%;
  ${devices.tablet} {
    max-width: 90vw;
  }
`
export const CheckoutWrapper = styled.div`
  display: flex;
  justify-content: center;
`

export const BoxWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteTransparent};
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  .textarea {
    margin: 10px 0;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
  h4 {
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  hr {
    margin: 10px 0;
  }
  span {
    margin-right: 5px;
  }
`
export const ShipmentMethodButton = styled(Button)<any>`
  background-color: ${({ theme }) => theme.colors.white};
  border-color: ${({ theme, isActive }) =>
    isActive ? theme.colors.brown2 : theme.colors.white};
  color: ${({ theme }) => theme.textColor};
  width: 100%;
  &:hover {
    border-color: ${({ theme }) => theme.colors.brown2};
  }
`

export const ButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  width: 100%;
  ${setTransition()};
`

const IconStyle = css`
  fill: ${({ theme }) => theme.textColor};
  margin-right: 10px;
  width: 20px;
  height: 20px;
`
export const DeliveryIcon = styled(RiEBike2Line)`
  ${IconStyle};
`
export const PickupIcon = styled(RiStore2Line)`
  ${IconStyle};
`
export const Row = styled.div`
  padding-left: 5px;
  display: grid;
  grid-template-columns: 120px minmax(120px, 300px);
  margin: 5px 0;
`

export const LocationContainer = styled(MotionContainer)`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  ${devices.mobile} {
    align-items: center;
    justify-content: center;
  }
`
export const LocationRow = styled.div`
  padding-left: 5px;
`
export const LocationWrapper = styled.div<any>`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  border-radius: 5px;
  border: 1px solid ${({ theme }) => theme.colors.white};
  cursor: pointer;
  max-width: 300px;
  min-width: 250px;
  margin: 10px 10px 0 0;
  ${setTransition()};
  ${({ isActive, theme }) =>
    isActive
      ? `border-color:${theme.colors.brown2};
    box-shadow: rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px;`
      : ''};

  &:hover {
    border-color: ${({ theme }) => theme.colors.brown2};
  }
`
export const LocationName = styled.p`
  font-weight: bold;
  text-align: center;
  margin-bottom: 10px;
  text-transform: uppercase;
  align-self: center;
`
export const LocationDetail = styled.div`
  display: flex;
  justify-content: flex-start;
`

export const LocationImage = styled.img`
  border-radius: 5px;
  width: 80px;
  height: 80px;
  object-fit: cover;
`
export const LocationTime = styled.div<{ isShow: boolean; isActive: boolean }>`
  color: ${({ isActive, theme }) =>
    isActive ? theme.colors.white : theme.colors.brown2};
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  padding: 5px;
  border: 1px solid ${({ theme }) => theme.colors.brown2};
  border-radius: 5px;
  width: 100%;
  margin: 5px 0;
  opacity: 1;
  font-size: 15px;
  background-color: ${({ isActive, theme }) => isActive && theme.colors.brown2};
  ${setTransition()};
  ${({ isShow }) =>
    !isShow &&
    `
    visibility: hidden;
    height: 0px;
    padding: 0px;
    border: none;
    margin:0;
    color:transparent;
    opacity:0;
  `};
`

export const LocationDescription = styled.p`
  padding: 5px;
  color: ${({ theme }) => theme.colors.grey2};
  text-overflow: ellipsis;
  ${setFlex()};
  font-size: 12px;
  padding-left: 12px;
`

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 5px;
  p {
    font-size: 15px;
  }
`

export const NoServiceMessage = styled.p`
  padding: 5px;
  margin: 10px 0;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.grey3};
  text-align: center;
`
export const TermAndCondition = styled.button`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brown2};
  cursor: pointer;
`
export const OutOfService = styled.div`
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  color: ${({ theme }) => theme.colors.brown2};
`
export const ConfirmationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  width: 100%;
`
export const ConfirmationDetail = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
  background-color: white;
  border-top: 1px solid ${({ theme }) => theme.colors.brown2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.brown2};
  width: 100%;
  padding: 10px 0;
  margin-bottom: 20px;
  margin-top: 10px;
`

export const PointWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  p {
    margin-right: 10px;
  }
`
export const ModalPointWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.brown2};
  width: 100%;
  padding: 5px;
  margin-bottom: 20px;
  p {
    font-size: 24px;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.success};
  }
  span {
    font-size: 14px;
  }
`
export const ModalPointCalculationWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.25fr 0.5fr;
  width: 100%;
  align-items: center;

  p {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: ${({ theme }) => theme.colors.success};
  }
  span {
    text-align: center;
  }
`

export const PointInput = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.brown2};
  background-color: ${({ theme }) => theme.colors.brown2};
  display: flex;
  align-items: center;

  span {
    border-left: 1px solid ${({ theme }) => theme.colors.brown2};
    padding: 0 5px;
    text-align: center;
    margin: 0;
    color: ${({ theme }) => theme.colors.white};
    width: 100%;
  }
  input {
    text-align: center;
    font-weight: bold;
    max-width: 120px;
    height: 40px;
  }
`

export const PointInfo = styled.p`
  font-size: 14px;
  width: 100%;
  text-align: center;
  padding-top: 5px;
  span {
    color: ${({ theme }) => theme.colors.brown2};
    font-style: italic;
    font-weight: bold;
  }
`
export const PointPriceWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.brown2};
  border-bottom: 1px solid ${({ theme }) => theme.colors.brown2};
  padding: 10px 0;
  width: 100%;
`

export const RedeemModalTotalAmount = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.brown2};
  padding: 20px 0;
  width: 100%;
  margin-bottom: 30px;
  font-weight: bold;
`
export const RedeemModalAmountRow = styled.div`
  display: flex;
  justify-content: space-between;
`
