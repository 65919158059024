import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import { BANNER_URL, RECOMMENDED_MENU_API } from '../../utils/API'

export const getRecommendedMenu = createAsyncThunk(
  'home/getRecommendedMenu',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.get(RECOMMENDED_MENU_API)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const getBanner = createAsyncThunk(
  'home/getBanner',
  async (payload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.get(BANNER_URL)
      return response.data.banners
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
