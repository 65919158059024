import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit'
import appReducer from './container/App/appSlice'
import menusReducer from './container/Menus/menuSlice'
import menuDetailReducer from './container/MenuDetail/menuDetailSlice'
import homeReducer from './container/Home/homeSlice'
import registerReducer from './container/Register/RegisterSlice'
import accountReducer from './container/Account/accountSlice'
import resetPasswordReducer from './container/ResetPassword/resetPasswordSlice'
import forgotPasswordReducer from './container/ForgotPassword/forgotPasswordSlice'
import checkoutReducer from './container/Checkout/checkoutSlice'

export const store = configureStore({
  reducer: {
    app: appReducer,
    menus: menusReducer,
    menuDetail: menuDetailReducer,
    home: homeReducer,
    register: registerReducer,
    account: accountReducer,
    resetPassword: resetPasswordReducer,
    forgotPassword: forgotPasswordReducer,
    checkout: checkoutReducer,
  },
})

export type AppDispatch = typeof store.dispatch
export type RootState = ReturnType<typeof store.getState>
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>
