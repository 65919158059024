import Loader from 'react-loader-spinner'

interface ISpinnerProps {
  height?: number
  width?: number
  type?:
    | 'ThreeDots'
    | 'Audio'
    | 'BallTriangle'
    | 'Bars'
    | 'Circles'
    | 'Grid'
    | 'Hearts'
    | 'Oval'
    | 'Puff'
    | 'Rings'
    | 'TailSpin'
    | 'Watch'
    | 'RevolvingDot'
    | 'Triangle'
    | 'Plane'
    | 'MutatingDots'
    | 'CradleLoader'
  color?: string
}
const Spinner = ({
  height = 50,
  width = 50,
  type = 'ThreeDots',
  color = '#a28e71',
}: ISpinnerProps): JSX.Element => {
  return <Loader type={type} color={color} height={height} width={width} />
}

export default Spinner
