import styled from 'styled-components'
import { devices, setFlex } from '../../styles/themeStyles'
import { Link } from 'react-router-dom'

export const SideBar = styled.div`
  font-size: 16px;

  .bm-burger-button {
    position: fixed;
    width: 36px;
    height: 30px;
    left: 30px;
    top: 17px;
    display: none;
    z-index: 1100 !important;
    ${devices.mobile} {
      display: initial;
    }
    ${devices.smallMobile} {
      left: 14px;
    }
  }

  .bm-burger-bars {
    background: #373a47;
  }

  .bm-cross-button {
    height: 24px;
    width: 24px;
  }

  .bm-cross {
    background: #bdc3c7;
  }

  .bm-menu {
    background: ${({ theme }) => theme.colors.brown3};
    padding: 2.5em 1.5em 0;
    font-size: 1.15em;
  }

  .bm-morph-shape {
    fill: #373a47;
  }

  .bm-overlay {
    background: rgba(0, 0, 0, 0.3);
  }
  .bm-menu-wrap {
    z-index: 1200;
  }
`

export const ListWrapper = styled.div`
  ${setFlex('flex-start')};
  flex-direction: column;
`

export const MenuType = styled.button`
  font-weight: bold;
  text-transform: uppercase;
  width: 100%;
  text-align: start;
  font-size: 16px;
  margin-bottom: 8px;
  &:hover {
    color: ${({ theme }) => theme.colors.brown2};
  }
`
export const MenuItem = styled.button`
  margin-bottom: 5px;
  font-size: 16px;
  width: 100%;
  text-align: start;
  &:hover {
    color: ${({ theme }) => theme.colors.brown2};
  }
`
export const MenuListWrapper = styled.div`
  ${setFlex('flex-start', 'flex-start')};
  flex-direction: column;
  margin-left: 10px;
  margin-bottom: 10px;
`
export const AccLogStyle = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.black};
  margin-top: 20px;
`

export const LoginButton = styled.button`
  text-transform: uppercase;
  padding-top: 15px;
  font-weight: bold;
  font-size: 18px;
  text-align: start;
`
export const AccountButton = styled(Link)`
  text-transform: uppercase;
  padding-top: 15px;
  font-weight: bold;
  font-size: 18px;
`
export const ColFlex = styled.div`
  display: flex;
  flex-direction: column;
`
export const Bottom = styled.div`
  height: 50px;
`
