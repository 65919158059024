import { ThemeProvider as StyledComponentsThemeProvider } from 'styled-components'

import GlobalStyles from './globalStyles'
import { lightTheme } from './themeStyles'

interface IThemeProviderProps {
  children: JSX.Element
}

export type ThemeType = typeof lightTheme

function ThemeProvider({ children }: IThemeProviderProps): JSX.Element {
  return (
    <StyledComponentsThemeProvider theme={lightTheme}>
      <GlobalStyles />
      {children}
    </StyledComponentsThemeProvider>
  )
}

export default ThemeProvider
