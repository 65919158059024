import { useAppDispatch } from '../../Hooks/hooks'
import * as Styled from './Overlay.styled'
import { setModal } from '../../container/App/appSlice'
import { ReactElement } from 'react'

interface IOverlayProps {
  children: ReactElement[] | ReactElement
  onClose?: () => void
  showCloseButton?: boolean
}
const Overlay = ({
  onClose,
  children,
  showCloseButton = true,
}: IOverlayProps) => {
  const dispatch = useAppDispatch()
  const handleCloseModal = () => {
    if (onClose) {
      onClose()
    } else {
      dispatch(setModal({ isShow: false, type: '' }))
    }
  }
  return (
    <Styled.Overlay>
      <Styled.Modal>
        {showCloseButton ? (
          <Styled.CloseButton onClick={handleCloseModal}>
            <Styled.CgCloseIcon />
          </Styled.CloseButton>
        ) : (
          <div />
        )}
        <Styled.ChildrenWrapper>{children}</Styled.ChildrenWrapper>
      </Styled.Modal>
    </Styled.Overlay>
  )
}

export default Overlay
