import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { getRecommendedMenu, getBanner } from './actions'

export interface IMenusState {
  recommendedMenuList: {
    data: any[]
    status: string
  }
  banner: {
    data: any[]
    status: string
  }
}

const initialState: IMenusState = {
  recommendedMenuList: { data: [], status: 'idle' },
  banner: { data: [], status: 'idle' },
}

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRecommendedMenu.pending, (state, action) => {
      state.recommendedMenuList.status = 'loading'
      state.recommendedMenuList.data = []
    })
    builder.addCase(getRecommendedMenu.fulfilled, (state, action) => {
      state.recommendedMenuList.status = 'succeeded'
      state.recommendedMenuList.data = action.payload
    })
    builder.addCase(getRecommendedMenu.rejected, (state, action) => {
      state.recommendedMenuList.status = 'failed'
      state.recommendedMenuList.data = []
    })
    builder.addCase(getBanner.pending, (state, action) => {
      state.banner.status = 'loading'
      state.banner.data = []
    })
    builder.addCase(getBanner.fulfilled, (state, action) => {
      state.banner.status = 'succeeded'
      state.banner.data = action.payload
    })
    builder.addCase(getBanner.rejected, (state, action) => {
      state.banner.status = 'failed'
      state.banner.data = []
    })
  },
})

export const selectRecommendedMenuList = (state: RootState) =>
  state.home.recommendedMenuList
export const selectBanner = (state: RootState) => state.home.banner

export default homeSlice.reducer
