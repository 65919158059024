import styled from 'styled-components'
import { setFlex, setTransition } from '../../styles/themeStyles'
import { RiFacebookCircleLine, RiInstagramLine } from 'react-icons/ri'

export const Footer = styled.div`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.textColor};
  padding: 20px;
`
export const FooterBox = styled.div`
  width: 320px;
`

export const FooterLabel = styled.p`
  text-transform: capitalize;
  font-size: 14px;
  color: ${({ theme }) => theme.textColor};
  letter-spacing: 1px;
  padding: 5px 0 10px 0;
  text-transform: uppercase;
`
export const FooterText = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.brown2};
  font-family: 'Cardo';
`
export const OpenTime = styled.div`
  margin-top: 10px;
`

export const FooterSection = styled.div`
  text-align: center;
  margin-top: 20px;
`

export const Copyright = styled.div`
  background-color: ${({ theme }) => theme.colors.brown2};
  color: ${({ theme }) => theme.textColor};
  height: 35px;
  ${setFlex()};
  font-size: 12px;
`

export const SocialMedia = styled.div`
  ${setFlex()};
  flex-direction: column;
`
export const SocialMediaLink = styled.a`
  ${setFlex()};
  color: ${({ theme }) => theme.colors.brown2};
  text-transform: capitalize;
  width: 130px;
  margin-bottom: 10px;
  font-family: 'Cardo';
  font-size: 12px;
  ${setTransition()};
  &:hover {
    color: ${({ theme }) => theme.colors.grey1};
  }
`
export const Facebook = styled(RiFacebookCircleLine)`
  fill: ${({ theme }) => theme.colors.brown2};
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-right: 10px;
  ${setTransition()};
  &:hover {
    fill: ${({ theme }) => theme.colors.brown2};
  }
`

export const Instagram = styled(RiInstagramLine)`
  fill: ${({ theme }) => theme.colors.brown2};
  width: 28px;
  height: 28px;
  cursor: pointer;
  margin-right: 10px;
  ${setTransition()};
  &:hover {
    fill: ${({ theme }) => theme.textColor};
  }
`
