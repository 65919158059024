import { useCallback, useEffect } from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'
import NavBar from '../../component/Navbar'
import Home from '../Home'
import Login from '../Login'
import Menus from '../Menus'
import Register from '../Register'
import Cart from '../Cart'
import Account from '../Account'
import Checkout from '../Checkout'
import ResetPassword from '../ResetPassword'
import ForgetPassword from '../ForgotPassword'
import MenuDetail from '../MenuDetail'
import * as Styled from './App.styled'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { selectLogin, selectModal, selectCart } from './appSlice'
import OverlayModal from '../../component/OverlayModal'
import LoginRequiredModal from '../../component/LoginReqiredModal'
import Footer from '../../component/Footer'
import SideBar from '../../component/SideBar'
import { getAllMenu } from '../Menus/actions'
import { selectMenuType, selectAllMenuByType } from '../Menus/menuSlice'
import { getCart, logout } from './actions'
import 'antd/dist/antd.css'
import TermsAndConditions from '../TermsAndConditions'
import { ReactElement } from 'react-router/node_modules/@types/react'
import { BackTop, message } from 'antd'
import History from '../Account/history'
import { getHistory, getPointRate, getUserInfo } from '../Account/actions'
import { selectUserInfo } from '../Account/accountSlice'
message.config({
  duration: 1,
})
export default function App(): JSX.Element {
  const dispatch = useAppDispatch()
  const login = useAppSelector(selectLogin)
  const userInfo = useAppSelector(selectUserInfo)
  const modal = useAppSelector(selectModal)
  const menuType = useAppSelector(selectMenuType)
  const allMenuByType = useAppSelector(selectAllMenuByType)
  const cart = useAppSelector(selectCart)

  useEffect(() => {
    dispatch(getAllMenu())
    dispatch(getCart())
    dispatch(getPointRate())
    dispatch(getHistory())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(getUserInfo())
    dispatch(getHistory())
  }, [cart, dispatch])

  useEffect(() => {
    if (login.isLoggedIn) {
      dispatch(getUserInfo())
    }
  }, [login, dispatch])

  useEffect(() => {
    if (login.isLoggedIn && userInfo.status === 'failed') {
      dispatch(logout())
    }
  }, [login, userInfo, dispatch])

  const showModal = useCallback(() => {
    if (modal.isShow) {
      let result
      switch (modal.type) {
        case 'login':
          result = <Login />
          break
        case 'register':
          result = <Register />
          break
        case 'forgotPassword':
          result = <ForgetPassword />
          break
        case 'resetPassword':
          result = <ResetPassword />
          break
        case 'loginRequired':
          result = <LoginRequiredModal />
          break
        case 'privacy-policy':
          result = <TermsAndConditions type='privacy policy' />
          break
        case 'user-agreement':
          result = <TermsAndConditions type='user agreement' />
          break
        default:
          result = <></>
      }
      return <OverlayModal>{result}</OverlayModal>
    } else {
      return <></>
    }
  }, [modal])

  const renderContent = (children): ReactElement => (
    <Styled.AppWrapper>
      <Styled.Content>{children}</Styled.Content>
      <Footer />
    </Styled.AppWrapper>
  )
  return (
    <Styled.App id='app'>
      <BrowserRouter>
        <SideBar
          menuType={menuType}
          allMenuByType={allMenuByType}
          login={login}
        />
        <BackTop />
        {showModal()}
        <NavBar
          menuType={menuType}
          allMenuByType={allMenuByType}
          login={login}
          cartSize={cart.total}
        />
        <Switch>
          <Route
            exact
            path='/'
            render={({ history }) => <Home history={history} />}
          />
          <Route
            exact
            path='/history'
            render={() => {
              return login.isLoggedIn ? (
                renderContent(<History />)
              ) : (
                <Redirect to='/' />
              )
            }}
          />
          <Route
            exact
            path={['/menus', '/menus/:menuType']}
            render={(props) => renderContent(<Menus {...props} />)}
          />
          <Route
            exact
            path={['/menus/:menuId/:subMenuId']}
            render={({ match }) => renderContent(<MenuDetail match={match} />)}
          />
          <Route
            exact
            path='/cart'
            render={() => {
              return login.isLoggedIn ? (
                renderContent(<Cart />)
              ) : (
                <Redirect to='/' />
              )
            }}
          />
          <Route
            exact
            path='/checkout'
            render={() => {
              return login.isLoggedIn ? (
                renderContent(<Checkout />)
              ) : (
                <Redirect to='/' />
              )
            }}
          />
          <Route
            exact
            path='/account'
            render={() => {
              return login.isLoggedIn ? (
                renderContent(<Account />)
              ) : (
                <Redirect to='/' />
              )
            }}
          />
          <Route exact path='*' render={() => <Redirect to='/' />} />
        </Switch>
      </BrowserRouter>
    </Styled.App>
  )
}
