import styled from 'styled-components'

export const TermsAndConditionsWrapper = styled.div`
  padding: 20px;
  margin-top: 20px;
  font-size: 16px;
  h1 {
    text-align: center;
    text-transform: uppercase;
  }
`
export const TextWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px;
  ul {
    padding-left: 10px;
    margin-left: 10px;
    margin-top: 10px;
  }
  span {
    font-weight: bold;
  }
`
