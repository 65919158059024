import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import {
  grantPhoneRequest,
  verifyRegisterOtpRequest,
  registerRequest,
} from './actions'

export interface IMenusState {
  grantPhoneNumber: {
    data: {
      token: string
      phone: string
    }
    meta: any
    status: 'loading' | 'succeeded' | 'failed' | 'idle'
  }
  verifyOtp: {
    data: {
      token: string
    }
    meta: any
    status: 'loading' | 'succeeded' | 'failed' | 'idle'
  }
  registerRespone: {
    error: any
    status: 'loading' | 'succeeded' | 'failed' | 'idle'
  }
}

const initialState: IMenusState = {
  grantPhoneNumber: {
    data: { token: '', phone: '' },
    status: 'idle',
    meta: {},
  },
  verifyOtp: { data: { token: '' }, status: 'idle', meta: {} },
  registerRespone: { error: {}, status: 'idle' },
}

export const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetGrantPhoneNumber: (state) => {
      state.grantPhoneNumber = initialState.grantPhoneNumber
    },
  },
  extraReducers: (builder) => {
    builder.addCase(grantPhoneRequest.pending, (state, action) => {
      state.grantPhoneNumber.status = 'loading'
      state.grantPhoneNumber.meta = action.meta
    })
    builder.addCase(grantPhoneRequest.fulfilled, (state, action) => {
      state.grantPhoneNumber.status = 'succeeded'
      state.grantPhoneNumber.data = action.payload
    })
    builder.addCase(grantPhoneRequest.rejected, (state, action) => {
      state.grantPhoneNumber.status = 'failed'
    })
    builder.addCase(verifyRegisterOtpRequest.pending, (state, action) => {
      state.verifyOtp.status = 'loading'
      state.verifyOtp.meta = action.meta
    })
    builder.addCase(verifyRegisterOtpRequest.fulfilled, (state, action) => {
      state.verifyOtp.status = 'succeeded'
      state.verifyOtp.data = action.payload
    })
    builder.addCase(verifyRegisterOtpRequest.rejected, (state, action) => {
      state.verifyOtp.status = 'failed'
    })
    builder.addCase(registerRequest.pending, (state, action) => {
      state.registerRespone.status = 'loading'
    })
    builder.addCase(registerRequest.fulfilled, (state, action) => {
      state.registerRespone.status = 'succeeded'
      state.registerRespone.error = {}
    })
    builder.addCase(registerRequest.rejected, (state, action) => {
      state.registerRespone.status = 'failed'
      state.registerRespone.error = action.payload
    })
  },
})

export const { resetGrantPhoneNumber } = registerSlice.actions

export const selectGrantPhoneNumber = (state: RootState) =>
  state.register.grantPhoneNumber
export const selectVerifyRegisterOtp = (state: RootState) =>
  state.register.verifyOtp
export const selectRegisterResponse = (state: RootState) =>
  state.register.registerRespone

export default registerSlice.reducer
