import styled, { css } from 'styled-components'

import Button from '../../component/Button'
import { devices, setFlex } from '../../styles/themeStyles'
import { FiCoffee } from 'react-icons/fi'
import { IoArrowForwardOutline } from 'react-icons/io5'
import { RiEBike2Line, RiStore2Line, RiWalkFill } from 'react-icons/ri'
import { HistoryStatus } from './history'
export const Account = styled.div`
  margin: auto;
  ${setFlex()};
  flex-direction: column;
`
export const Label = styled.div`
  text-transform: capitalize;
  font-weight: bold;
  text-align: right;
  font-family: 'Cardo';
  span {
    color: ${({ theme }) => theme.colors.brown2};
    font-size: 20px;
  }
`
export const Value = styled.div`
  margin-left: 10px;
  font-family: 'Cardo';
`

export const InfoWrapper = styled.div`
  ${setFlex()};
  flex-wrap: wrap;
  flex-direction: column;
`
export const ButtonWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
`

export const Info = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.whiteTransparent};
  padding: 10px;
  overflow: hidden;
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  .gender-radio {
    margin-left: 10px;
  }
`

export const Section = styled.div`
  max-width: 60vw;
  width: 100%;
  padding-bottom: 20px;
  &:last-child,
  &:not(::first-child) {
    padding-top: 40px;
    border-top: 2px solid ${({ theme }) => theme.textColor};
  }
  ${devices.tablet} {
    max-width: 90vw;
  }
`
export const ButtonStyled = styled(Button)`
  max-width: 500px;
`
export const Row = styled.div`
  /* margin: 10px 0; */
`
export const InfoText = styled.div`
  margin: 10px 0;
  display: grid;
  grid-template-columns: 120px 1fr;
  align-content: center;
`
export const ErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
`
export const RequireStar = styled.span`
  color: ${({ theme }) => theme.colors.red};
  padding: 0 3px;
`
export const GenderLabel = styled.span`
  font-size: 14px;
  font-family: 'Cardo';
`
export const GenderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 10px 0;
`

export const DateOfBirthWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .ant-picker {
    border: none;
    height: 45px;
    padding: 10px 20px;
    input {
      font-family: 'Cardo';
      color: ${({ theme }) => theme.textColor};
    }
  }
`

export const HistoryWrapper = styled.div`
  ${setFlex()};
  width: 100%;
  background-color: ${({ theme }) => theme.colors.whiteTransparent};
`
export const HistoryItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const orderStatusColors = {
  [HistoryStatus.COMPLETED]: '#82ac7c',
  [HistoryStatus.CANCELED]: '#84342d',
  [HistoryStatus.WAITING]: '#edcc8b',
  [HistoryStatus.PREPARING]: '#6699CC',
  [HistoryStatus.REFUNDED]: '#153B50',
}

export const OrderStatus = styled.div<{ status: HistoryStatus }>`
  border-radius: 5px;
  color: ${({ status }) => orderStatusColors[status]};
  font-weight: bold;
  ${setFlex()};
  text-transform: capitalize;
`

export const OrderTitleWrapper = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  ${devices.mobile} {
    flex-wrap: wrap;
  }
`
export const StatusWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  ${devices.mobile} {
    justify-content: space-between;
  }
  p {
    font-weight: bold;
    padding-right: 10px;
  }
`
export const OrderDateWrapper = styled.div`
  width: 100%;
`

export const OrderDateRow = styled.div`
  p {
    font-weight: bold;
    padding-right: 10px;
  }
  span {
    padding-right: 10px;
  }
  display: flex;
  width: 100%;
`
export const Quantity = styled.div`
  border-radius: 5px;
  margin-right: 10px;
  width: 25px;
  height: 25px;
  ${setFlex()};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brown2};
`
export const OrderMenuWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 10px 0;
`

export const MenuDetail = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`
export const Customize = styled.div`
  &::before {
    content: '(';
  }
  &::after {
    content: ')';
  }
`
export const MenuName = styled.p`
  font-weight: bold;
  &::after {
    content: '/';
    padding: 5px;
  }
`
export const SubMenuName = styled.p`
  &::after {
    content: '/';
    padding: 5px;
  }
`
export const MenuPrice = styled.p`
  font-weight: bold;
  min-width: 50px;
  text-align: end;
  &::before {
    content: '฿';
    padding: 5px;
  }
`
export const DeliveryWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  font-weight: bold;
  span {
    color: ${({ theme }) => theme.colors.brown2};
    text-transform: uppercase;
    padding: 0 5px;
  }
`

export const PayButton = styled.button`
  margin-left: 10px;
  border-top: 1px solid ${({ theme }) => theme.colors.brown2};
  color: ${({ theme }) => theme.colors.white};
  background-color: ${({ theme }) => theme.colors.brown2};
  padding: 2px 5px;
  width: fit-content;
`
export const CancelButton = styled.button`
  border: 1px solid ${({ theme }) => theme.colors.cancel};
  color: ${({ theme }) => theme.colors.cancel};
  padding: 2px 5px;
  width: fit-content;
  margin-top: 10px;
`

export const PriceWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.brown2};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-top: 10px;
`
export const Discount = styled.p`
  margin-left: 20px;
  &::before {
    content: '- ฿';
    padding: 5px;
  }
`
export const Price = styled.p`
  margin-left: 20px;
  &::before {
    content: '฿';
    padding: 5px;
  }
`
export const Point = styled.p`
  margin-left: 20px;
  &::after {
    content: 'points';
    padding-left: 5px;
  }
  &::before {
    content: '-';
    padding-right: 5px;
  }
`

export const TotalPriceLabel = styled.p`
  font-weight: bold;
  margin-top: 5px;
`
export const TotalPrice = styled.p`
  margin-left: 20px;
  font-weight: bold;
  margin-top: 5px;
  &::before {
    content: '฿';
    padding: 5px;
  }
`

export const OriginalPriceWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 40%;
  width: 50%;
  ${devices.mobile} {
    width: 100%;
  }
  align-items: end;
  justify-items: end;
`

export const EditButton = styled.button`
  align-self: flex-end;
  justify-self: flex-end;
`

export const PaginateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 10px;
`

export const CancelModal = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  h3 {
    font-size: 20px;
  }
  p {
    margin: 10px 0;
    text-align: start;
    width: 100%;
  }
  .delete {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.red};
    padding: 0 5px;
  }
`
export const PointInfoModal = styled.div`
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  h3 {
    font-size: 20px;
  }
  p {
    margin: 10px 0;
    text-align: start;
    width: 100%;
    font-weight: bold;
    font-size: 16px;
  }
  span {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.success};
  }
`
export const PointInfoButton = styled.button`
  font-size: 10px;
  font-family: 'Cardo';
  text-decoration: underline;
`
export const PointSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: auto;
`
export const CancelButtonWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
`

export const PasswordMocked = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  display: flex;
  padding: 0 20px;
  width: 100%;
  justify-content: space-between;
`
export const EditButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-self: flex-end;
`
export const DeleteAccountButton = styled.button`
  color: ${({ theme }) => theme.colors.brown2};
  width: fit-content;
  padding: 0 5px;
  margin-top: 5px;
  align-self: center;
  font-style: italic;
`

export const CoffeeIcon = styled(FiCoffee)`
  font-size: 80px;
  margin-top: 20px;
`

export const ZeroState = styled.div`
  width: 100%;
  ${setFlex()};
  flex-direction: column;
  font-size: 20px;
  background-color: ${({ theme }) => theme.backgroundColor};
  p {
    margin: 20px;
  }
`

export const Arrow = styled(IoArrowForwardOutline)`
  margin-left: 5px;
  path {
    color: ${({ theme }) => theme.colors.white};
  }
`
export const OrderType = styled.div`
  text-transform: uppercase;
  color: ${({ theme }) => theme.textColor};
  font-weight: bold;
  display: flex;
  align-items: center;
`
const IconStyle = css`
  fill: ${({ theme }) => theme.textColor};
  margin-right: 10px;
  width: 20px;
  height: 20px;
`
export const DeliveryIcon = styled(RiEBike2Line)`
  ${IconStyle};
`
export const PickupIcon = styled(RiStore2Line)`
  ${IconStyle};
`
export const WalkinIcon = styled(RiWalkFill)`
  ${IconStyle};
`
export const PaymentErrorMessage = styled.p`
  color: ${({ theme }) => theme.colors.red};
  font-style: italic;
  font-size: 12px;
  padding-top: 10px;
`
