import { useState, useEffect, useCallback, useMemo } from 'react'
import * as Styled from './Checkout.styled'
import Header from '../../component/Header'
import {
  useAppDispatch,
  useAppSelector,
  useCartTotalDiscount,
} from '../../Hooks/hooks'
import {
  selectCart,
  selectDeliverySchedule,
  selectPickupSchedule,
  setModal,
} from '../App/appSlice'

import { useHistory } from 'react-router-dom'
import Pickup from './Pickup'

import moment from 'moment-timezone'
import { Delivery } from './Delivery'
import { Input, message, Checkbox } from 'antd'
import {
  checkoutDeliveryRequest,
  checkoutPickupRequest,
  ICheckoutDelivery,
  ICheckoutPickup,
  verifyPayment,
} from './actions'
import Button from '../../component/Button'
import {
  selectCheckoutDeliveryRes,
  selectCheckoutPickupRes,
  selectVerifyPaymentStatusRes,
  setInitialCheckoutDeliveryRes,
  setInitialCheckoutPickupRes,
  setInitialVerifyPaymentStatus,
} from './checkoutSlice'
import Payment from '../../component/Payment'
import {
  selectHistory,
  selectPointRate,
  selectUserInfo,
} from '../Account/accountSlice'
import OverlayModal from '../../component/OverlayModal'

moment.tz.setDefault('Asia/Bangkok')
const DATE_FORMAT: string = 'DD MMMM YYYY'
const TIME_FORMAT: string = 'HH:mm'
const MINUTES_ADVANCE: number = 10
export interface ISchedule {
  date: moment.Moment
  open: moment.Moment
  close: moment.Moment
  id: string
}
export interface IDeliveryPoint {
  date: moment.Moment
  id: string
  location: IDeliveryLocation[]
}
export interface IDeliveryLocation {
  description: string
  image: string
  name: string
  id: string
  startTime: moment.Moment
  endTime: moment.Moment
}
export interface IDeliveryEvent {
  id: string
  lastOrderTime: moment.Moment
  startTime: moment.Moment
  endTime: moment.Moment
}
export interface IPickupItem {
  close: moment.Moment
  open: moment.Moment
}

const initSelectedPickupDate: ISchedule = {
  date: moment(),
  open: moment(),
  close: moment(),
  id: '',
}

const initSelectedDeliveryDate: IDeliveryPoint = {
  date: moment(),
  id: '',
  location: [],
}
const initSelectedDeliveryLocation: IDeliveryLocation = {
  description: '',
  image: '',
  name: '',
  id: '',
  startTime: moment(),
  endTime: moment(),
}

export const initSelectedDeliveryEvent: IDeliveryEvent = {
  id: '',
  lastOrderTime: moment(),
  startTime: moment(),
  endTime: moment(),
}
const initSPickupItem: IPickupItem = {
  close: moment(),
  open: moment(),
}
const MINIMUM_ORDER_AMOUNT: number = 20
const Checkout = (): JSX.Element => {
  const cart = useAppSelector(selectCart)
  const history = useHistory()
  const verifyPaymentStatus = useAppSelector(selectVerifyPaymentStatusRes)
  const orderHistory = useAppSelector(selectHistory)

  const dispatch = useAppDispatch()
  const checkoutPickupRes = useAppSelector(selectCheckoutPickupRes)
  const checkoutDeliveryRes = useAppSelector(selectCheckoutDeliveryRes)
  const pickupSchedule = useAppSelector(selectPickupSchedule)
  const deliverySchedule = useAppSelector(selectDeliverySchedule)
  const userInfo = useAppSelector(selectUserInfo)
  const pointRate = useAppSelector(selectPointRate)
  const [shippingMethod, setShippingMethod] = useState<'pickup' | 'delivery'>(
    'pickup'
  )
  const [isAcceptTermAndCondition, setIsAcceptTermAndCondition] =
    useState<boolean>(true)
  const [messageToSeller, setMessageToSeller] = useState<string>('')
  const [isShowPaymentModal, setIsShowPaymentModal] = useState<boolean>(false)
  const [isShowConfirmationModal, setIsShowConfirmationModal] =
    useState<boolean>(false)
  const [isShowMinimumWarningModal, setIsShowMinimumWarningModal] =
    useState<boolean>(false)

  //PICKUP
  const [selectedPickupDate, setSelectedPickupDate] = useState<ISchedule>(
    initSelectedPickupDate
  )
  const [selectedPickupTime, setSelectedPickupTime] = useState<moment.Moment>(
    moment()
  )
  const [pickupItems, setPickupItems] = useState<IPickupItem>(initSPickupItem)

  //DELIVERY
  const [selectedDeliveryDate, setSelectedDeliveryDate] =
    useState<IDeliveryPoint>(initSelectedDeliveryDate)
  const [selectedLocation, setSelectedLocation] = useState<IDeliveryLocation>(
    initSelectedDeliveryLocation
  )
  const [selectedEvent, setSelectedEvent] = useState<IDeliveryEvent>(
    initSelectedDeliveryEvent
  )

  //POINTS
  const { totalDiscount: totalCartDiscount, totalPrice: totalCartPrice } =
    useCartTotalDiscount()
  const userPoint = userInfo.data.point
  const maxPointCanRedeem = useMemo(() => {
    //NOTE: the final price after all discounts must be at least 20 THB (Omese limitation)
    const { point, discount } = pointRate.pointToDiscount
    let maxPrice: number =
      cart.totalPrice - totalCartDiscount - MINIMUM_ORDER_AMOUNT

    let max = Math.floor((maxPrice * point) / discount)
    //NOTE: if the maximum points can be redeemed more than user's points -> set the maximum to user's points balances
    if (max > userPoint) {
      max = Math.floor(userPoint)
    }
    return pointRate.status === 'succeeded' ? parseFloat(max.toFixed(2)) : 0
  }, [pointRate, cart, totalCartDiscount, userPoint])

  const getCalculatedPointDiscount = useCallback(
    (pointUsed) => {
      const { point, discount } = pointRate.pointToDiscount
      const discountAmount = (
        (pointUsed * discount) / point +
        totalCartDiscount
      ).toFixed(2)
      const totalPrice = (cart.totalPrice - parseFloat(discountAmount)).toFixed(
        2
      )
      return {
        totalDiscount: parseFloat(discountAmount),
        totalPrice: parseFloat(totalPrice),
        discountFromPoints: parseFloat(
          ((pointUsed * discount) / point).toFixed(2)
        ),
      }
    },
    [pointRate, totalCartDiscount, cart]
  )
  const [isShowPointModal, setIsShowPointModal] = useState(false)
  const [usedPoint, setUsedPoint] = useState(0)
  const [isUsedPoint, setIsUsedPoint] = useState(false)

  const setInitialState = () => {
    setIsAcceptTermAndCondition(true)
    setMessageToSeller('')
    setIsShowConfirmationModal(false)
    //PICKUP
    setSelectedPickupDate(initSelectedPickupDate)
    setSelectedPickupTime(moment())
    setPickupItems(initSPickupItem)
    //DELIVERY
    setSelectedDeliveryDate(initSelectedDeliveryDate)
    setSelectedLocation(initSelectedDeliveryLocation)
    setSelectedEvent(initSelectedDeliveryEvent)
    //POINT
    setIsShowPointModal(false)
    setUsedPoint(0)
    setIsUsedPoint(false)
  }

  useEffect(() => {
    if (cart.status === 'succeeded' && cart.total === 0) {
      history.push('/cart')
    }
    dispatch(setInitialCheckoutPickupRes())
    dispatch(setInitialCheckoutDeliveryRes())
    setIsShowPaymentModal(false)
    return () => {
      dispatch(setInitialCheckoutPickupRes())
      dispatch(setInitialCheckoutDeliveryRes())
      setIsShowPaymentModal(false)
    }
    //eslint-disable-next-line
  }, [])

  const checkoutResponse = (status) => {
    if (status === 'loading') {
      message.loading('Ordering...')
    } else if (status === 'failed') {
      message.error({
        content: `Something went wrong, please try again`,
        duration: 3.5,
      })
    } else if (status === 'succeeded') {
      setIsShowPaymentModal(true)
      setInitialState()
    }
  }

  useEffect(() => {
    checkoutResponse(checkoutPickupRes.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutPickupRes])

  useEffect(() => {
    checkoutResponse(checkoutDeliveryRes.status)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDeliveryRes])

  useEffect(() => {
    if (
      verifyPaymentStatus === 'succeeded' &&
      orderHistory.status === 'succeeded' &&
      cart.status === 'succeeded'
    ) {
      dispatch(setInitialVerifyPaymentStatus())
      dispatch(setInitialCheckoutPickupRes())
      dispatch(setInitialCheckoutDeliveryRes())
      history.push('/history')
    }
    //eslint-disable-next-line
  }, [verifyPaymentStatus, orderHistory, cart])

  const toggleShippingMethod = (method: 'pickup' | 'delivery'): void => {
    setShippingMethod(method)
    setSelectedPickupDate(initSelectedPickupDate)
    setSelectedPickupTime(moment())
    setSelectedDeliveryDate(initSelectedDeliveryDate)
    setSelectedLocation(initSelectedDeliveryLocation)
  }

  const renderShipmentDetail = () => {
    return (
      <Styled.BoxWrapper>
        <h3>Select shipping method:</h3>
        <Styled.ButtonWrapper>
          <Styled.ShipmentMethodButton
            onClick={() => toggleShippingMethod('pickup')}
            isActive={shippingMethod === 'pickup'}
          >
            <Styled.PickupIcon />
            Pickup at Store
          </Styled.ShipmentMethodButton>
          <Styled.ShipmentMethodButton
            onClick={() => toggleShippingMethod('delivery')}
            isActive={shippingMethod === 'delivery'}
          >
            <Styled.DeliveryIcon />
            Delivery
          </Styled.ShipmentMethodButton>
        </Styled.ButtonWrapper>
      </Styled.BoxWrapper>
    )
  }

  const handlePickupCheckout = (body) => {
    const nowInAdvance: boolean = moment(selectedPickupTime).isAfter(
      moment().add(MINUTES_ADVANCE, 'minutes')
    )
    const closeTime: boolean = moment(selectedPickupTime).isBefore(
      moment(pickupItems.close).add(MINUTES_ADVANCE, 'minutes')
    )
    const orderInAdvance = nowInAdvance && closeTime
    const bodyTransformed: ICheckoutPickup = {
      ...body,
      pickup: {
        schedule: selectedPickupDate.id,
        time: moment(selectedPickupTime).format('HH:mm'),
      },
    }
    if (orderInAdvance) {
      dispatch(checkoutPickupRequest(bodyTransformed))
    } else {
      message.error({
        content: `Please order ${MINUTES_ADVANCE} minutes in advance before the pick-up time.`,
        duration: 3.5,
      })
    }
  }

  const handleDeliveryCheckout = (body) => {
    const isBeforeLastOrderTime = moment().isBefore(
      moment(selectedEvent.lastOrderTime)
    )
    if (isBeforeLastOrderTime) {
      const bodyTransformed: ICheckoutDelivery = {
        ...body,
        deliveryEvent: selectedEvent.id,
      }
      dispatch(checkoutDeliveryRequest(bodyTransformed))
    } else {
      message.error({
        content: `Last order time has been exceeded. Please select other time`,
        duration: 3.5,
      })
    }
  }

  const onCheckoutRequest = () => {
    setIsShowConfirmationModal(false)
    const carts: { carts: string[] } = {
      carts: cart.data.map((item) => item._id),
    }
    const instruction: { instruction: string } = {
      instruction: messageToSeller || '-',
    }
    const point = isUsedPoint ? { point: usedPoint } : {}
    const body = { ...carts, ...instruction, ...point }
    if (shippingMethod === 'pickup') {
      handlePickupCheckout(body)
    } else {
      handleDeliveryCheckout(body)
    }
  }

  const renderTermsAndConditions = () => {
    return (
      <Checkbox
        onChange={() => setIsAcceptTermAndCondition((prev: boolean) => !prev)}
        checked={isAcceptTermAndCondition}
      >
        I've read and accept the
        <Styled.TermAndCondition
          onClick={() =>
            dispatch(setModal({ isShow: true, type: 'user-agreement' }))
          }
        >
          Terms and Conditions
        </Styled.TermAndCondition>
      </Checkbox>
    )
  }

  const renderSpecialRequest = () => {
    return (
      <>
        <span>Special Request :</span>
        <Input.TextArea
          className='textarea'
          value={messageToSeller}
          onChange={(e) => setMessageToSeller(e.target.value)}
          placeholder='Add your note here'
        />
      </>
    )
  }

  const renderRangeTime = (from, to) => {
    return `${moment(from).format(TIME_FORMAT)} - ${moment(to).format(
      TIME_FORMAT
    )}`
  }

  const renderShipmentDetailSummary = () => {
    const isPickup: boolean = shippingMethod === 'pickup'
    const label: string = isPickup ? 'Pick up' : 'Delivery'
    const shipmentDate = isPickup
      ? selectedPickupDate.date
      : selectedDeliveryDate.date
    const shipmentLocation = isPickup
      ? 'Pick up at store'
      : `Delivery to ${selectedLocation.name || ' -'}`
    const shipmentTime = isPickup
      ? moment(selectedPickupTime).format(TIME_FORMAT)
      : selectedEvent.id === ''
      ? '-'
      : renderRangeTime(selectedEvent.startTime, selectedEvent.endTime)
    const lastOrderTime = !isPickup && (
      <div>
        Please place your order before:{' '}
        {selectedEvent.id
          ? moment(selectedEvent.lastOrderTime).format(
              `${DATE_FORMAT} ${TIME_FORMAT}`
            )
          : '-'}
      </div>
    )
    return (
      <>
        <h4>{shipmentLocation}</h4>
        <div>
          {label} Date: {shipmentDate.format(DATE_FORMAT)}
        </div>
        <div>
          {label} Time: {shipmentTime}
        </div>
        {lastOrderTime}
      </>
    )
  }

  const isDisabledPlaceOrder = (): boolean => {
    if (process.env.REACT_APP_DISABLED_CHECKOUT === `true`) {
      return true
    } else {
      return (
        (shippingMethod !== 'pickup' && selectedEvent.id === '') ||
        (shippingMethod === 'pickup' && pickupItems === initSPickupItem) ||
        !isAcceptTermAndCondition ||
        checkoutPickupRes.status === 'loading' ||
        checkoutDeliveryRes.status === 'loading'
      )
    }
  }

  const handleOnPlaceOrderClick = (totalPrice: number) => {
    if (totalPrice >= MINIMUM_ORDER_AMOUNT) {
      setIsShowConfirmationModal(true)
    } else {
      setIsShowMinimumWarningModal(true)
    }
  }

  const renderMinimumWarningModal = () => {
    const { totalPrice } = getCalculatedPointDiscount(usedPoint)
    return (
      <OverlayModal onClose={() => setIsShowMinimumWarningModal(false)}>
        <Styled.ConfirmationWrapper>
          <Header text='Please review your order' />
          <div style={{ fontSize: '18px', textAlign: 'center' }}>
            <p>
              You must have a minimum order amount of{' '}
              <span style={{ fontWeight: 'bold' }}>
                ฿{MINIMUM_ORDER_AMOUNT}
              </span>{' '}
              to place your order
            </p>
            <br />
            <Styled.ModalPointWrapper>
              Your current order total amount is <p>฿{totalPrice}</p>
            </Styled.ModalPointWrapper>
          </div>
          <Styled.ButtonWrapper>
            <Button
              renderType='outline'
              onClick={() => {
                setIsShowMinimumWarningModal(false)
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setIsShowMinimumWarningModal(false)
                history.push('/cart')
              }}
            >
              Back to cart
            </Button>
          </Styled.ButtonWrapper>
        </Styled.ConfirmationWrapper>
      </OverlayModal>
    )
  }

  const renderCartSummary = () => {
    const isPickupAvailable: boolean =
      shippingMethod === 'pickup' &&
      pickupSchedule.status === 'succeeded' &&
      pickupSchedule.data.length > 0

    const isDeliveryAvailable: boolean =
      shippingMethod !== 'pickup' &&
      deliverySchedule.status === 'succeeded' &&
      deliverySchedule.data.length > 0
    const { totalDiscount, totalPrice, discountFromPoints } =
      getCalculatedPointDiscount(usedPoint)
    if (isPickupAvailable || isDeliveryAvailable) {
      // NOTE: Can redeem if user's points balance more than 0 & Total amount > MINIMUM_ORDER_AMOUNT & maxPointCanRedeem > 0
      const shouldDisplayRedeemSection: boolean =
        userPoint > pointRate.pointToDiscount.point &&
        cart.totalPrice > MINIMUM_ORDER_AMOUNT &&
        maxPointCanRedeem > 0
      return (
        <>
          {shouldDisplayRedeemSection && renderPointSection()}
          <Styled.BoxWrapper>
            <h3>Order Summary:</h3>
            <h4>Cart Detail:</h4>
            <div>
              <p>{cart.total} items</p>
              {isUsedPoint ? (
                <>
                  <p>Original Price: ฿{cart.totalPrice.toLocaleString()}</p>
                  <p>
                    Points redeemed: {usedPoint} points / ฿{discountFromPoints}
                  </p>{' '}
                  <p>Total discount: -฿{totalDiscount}</p>
                  <p style={{ fontWeight: 'bold' }}>
                    Total Price: ฿{totalPrice}
                  </p>
                </>
              ) : (
                <>
                  <p>Original Price: ฿{cart.totalPrice}</p>
                  <p>Total Discount: -฿{totalCartDiscount}</p>
                  <p style={{ fontWeight: 'bold' }}>
                    Total Price: ฿{totalCartPrice.toLocaleString()}
                  </p>
                </>
              )}
            </div>
            <hr />
            {renderShipmentDetailSummary()}
            <hr />
            {renderSpecialRequest()}
            {renderTermsAndConditions()}
            <Styled.ButtonWrapper>
              <Button
                renderType='outline'
                onClick={() => history.push('/cart')}
              >
                Edit Cart
              </Button>
              <Button
                onClick={() => handleOnPlaceOrderClick(totalPrice)}
                disabled={isDisabledPlaceOrder()}
              >
                Place Order
              </Button>
            </Styled.ButtonWrapper>
          </Styled.BoxWrapper>
        </>
      )
    }
  }

  const renderPaymentModal = () => {
    const { data } =
      shippingMethod === 'pickup' ? checkoutPickupRes : checkoutDeliveryRes

    const handlePaidClicked = () => {
      dispatch(verifyPayment(data._id))
    }

    const handleClose = () => {
      dispatch(setInitialVerifyPaymentStatus())
      dispatch(setInitialCheckoutPickupRes())
      dispatch(setInitialCheckoutDeliveryRes())
      history.push('/')
    }

    return (
      <Payment
        data={data}
        setIsShow={setIsShowPaymentModal}
        onPaidClicked={handlePaidClicked}
        shippingMethod={shippingMethod}
        onCloseModal={handleClose}
      />
    )
  }

  const renderConfirmationModal = () => {
    const isPickup: boolean = shippingMethod === 'pickup'
    const { totalPrice } = getCalculatedPointDiscount(usedPoint)
    const shipmentDate = isPickup
      ? selectedPickupDate.date
      : selectedDeliveryDate.date
    const shipmentLocation = isPickup
      ? 'Pick up at store'
      : `Delivery to ${selectedLocation.name || ' -'}`
    const shipmentTime = isPickup
      ? moment(selectedPickupTime).format(TIME_FORMAT)
      : selectedEvent.id === ''
      ? '-'
      : renderRangeTime(selectedEvent.startTime, selectedEvent.endTime)
    const price = isUsedPoint
      ? totalPrice.toLocaleString()
      : cart.totalPrice.toLocaleString()
    return (
      <OverlayModal onClose={() => setIsShowConfirmationModal(false)}>
        <Styled.ConfirmationWrapper>
          <Header text='Order Confirmation ' />
          <Styled.ConfirmationDetail>
            <p>Total items: {cart.total}</p>

            {isUsedPoint ? (
              <p>Total point redeem: {usedPoint.toLocaleString()} points</p>
            ) : (
              <></>
            )}
            <p style={{ fontWeight: 'bold' }}>Total price: ฿{price}</p>
            <hr />
            <p>{shipmentLocation}</p>
            <p>
              {shipmentDate.format(DATE_FORMAT)} | {shipmentTime}
            </p>
          </Styled.ConfirmationDetail>
          <Button onClick={() => onCheckoutRequest()}>CONFIRM</Button>
          <Button
            renderType='outline'
            onClick={() => setIsShowConfirmationModal(false)}
          >
            CANCEL
          </Button>
        </Styled.ConfirmationWrapper>
      </OverlayModal>
    )
  }

  const renderPointSection = () => {
    const onClosePointRedeemModal = () => {
      setIsShowPointModal(false)
      setUsedPoint(0)
      setIsUsedPoint(false)
    }
    const onUsedPointChange = (val) => {
      let points
      if (val <= userPoint && val <= maxPointCanRedeem) {
        points = val
      } else if (val >= maxPointCanRedeem) {
        points = maxPointCanRedeem
      } else if (val >= userPoint) {
        points = userPoint
      } else {
        points = 0
      }

      const { totalDiscount } = getCalculatedPointDiscount(points)
      let totalUsedPoint = points
      if (totalDiscount <= cart.totalPrice) {
        totalUsedPoint = points
      } else {
        if (maxPointCanRedeem > userPoint) {
          totalUsedPoint = userPoint
        } else {
          totalUsedPoint = maxPointCanRedeem
        }
      }
      setUsedPoint(totalUsedPoint < 0 ? 0 : totalUsedPoint)
    }
    const { totalPrice, totalDiscount, discountFromPoints } =
      getCalculatedPointDiscount(usedPoint)
    return (
      <Styled.BoxWrapper>
        <h3>Remedy Points</h3>
        <Styled.PointWrapper>
          {discountFromPoints > 0 ? (
            <Styled.ModalPointWrapper>
              Total points applied{' '}
              <p>
                {usedPoint} (-฿{discountFromPoints.toLocaleString()})
              </p>
              <span style={{ fontStyle: 'italic' }}>
                Your current points balance is{' '}
                {(userPoint - usedPoint).toLocaleString()} points.
              </span>
            </Styled.ModalPointWrapper>
          ) : (
            <Styled.ModalPointWrapper>
              Your points balance{' '}
              <p>{userPoint ? userPoint.toLocaleString() : '0'}</p>
              <span style={{ fontStyle: 'italic' }}>
                Redeem your {maxPointCanRedeem.toLocaleString()} points worth ฿
                {(
                  maxPointCanRedeem * pointRate.pointToDiscount.discount
                ).toLocaleString()}
              </span>
            </Styled.ModalPointWrapper>
          )}
          <Button onClick={() => setIsShowPointModal(true)} width='fit-content'>
            {discountFromPoints > 0 ? 'Edit Redemption' : 'Redeem now'}
          </Button>
        </Styled.PointWrapper>
        {isShowPointModal && (
          <OverlayModal onClose={() => setIsShowPointModal(false)}>
            <Styled.ConfirmationWrapper>
              <Header text='Points Redemption' />
              <Styled.ModalPointWrapper>
                <span>Your points balance</span>
                <p>{userPoint ? userPoint.toLocaleString() : '0'} points</p>
              </Styled.ModalPointWrapper>
              <Styled.ModalPointCalculationWrapper>
                <Styled.PointInput>
                  <input
                    min={0}
                    value={usedPoint.toString() || ''}
                    type='number'
                    onChange={(e) =>
                      onUsedPointChange(parseInt(e.target.value))
                    }
                  />
                  <span>Points</span>
                </Styled.PointInput>
                <span>=</span>
                <p>฿{discountFromPoints.toLocaleString()}</p>
              </Styled.ModalPointCalculationWrapper>

              <Styled.PointInfo>
                Please enter the points to be redeemed.{' '}
                <span>
                  [{pointRate.pointToDiscount.point} points = ฿
                  {pointRate.pointToDiscount.discount}]
                </span>
              </Styled.PointInfo>
              <Styled.PointInfo>
                You must have a minimum order amount of{' '}
                <span>฿{MINIMUM_ORDER_AMOUNT}</span> to place your order due to
                online transaction.
              </Styled.PointInfo>
              <hr />
              <Styled.PointPriceWrapper>
                <Styled.RedeemModalAmountRow>
                  <p>Original amount (before discounts): </p>
                  <p style={{ fontWeight: 'bold' }}>
                    ฿{cart.totalPrice.toLocaleString()}
                  </p>
                </Styled.RedeemModalAmountRow>
                <Styled.RedeemModalAmountRow>
                  <p>Total Discount:</p>
                  <p style={{ fontWeight: 'bold' }}> -฿{totalDiscount}</p>
                </Styled.RedeemModalAmountRow>

                <ul>
                  <li style={{ marginLeft: '40px' }}>
                    <Styled.RedeemModalAmountRow>
                      <p>Other Discount:</p>
                      <p>-฿{totalCartDiscount}</p>
                    </Styled.RedeemModalAmountRow>
                  </li>
                  <li style={{ marginLeft: '40px' }}>
                    <Styled.RedeemModalAmountRow>
                      <p>Point Discount:</p>
                      <p>-฿{discountFromPoints}</p>
                    </Styled.RedeemModalAmountRow>
                  </li>
                </ul>
              </Styled.PointPriceWrapper>
              <Styled.RedeemModalTotalAmount>
                <Styled.RedeemModalAmountRow>
                  <p>Total Amount:</p>
                  <p>฿{totalPrice}</p>
                </Styled.RedeemModalAmountRow>
              </Styled.RedeemModalTotalAmount>
              <Styled.ButtonWrapper>
                <Button
                  renderType='outline'
                  onClick={() => onClosePointRedeemModal()}
                  width='100%'
                >
                  CLEAR
                </Button>
                <Button
                  onClick={() => {
                    setIsUsedPoint(usedPoint > 0)
                    setIsShowPointModal(false)
                  }}
                  width='100%'
                >
                  REDEEM
                </Button>
              </Styled.ButtonWrapper>
            </Styled.ConfirmationWrapper>
          </OverlayModal>
        )}
      </Styled.BoxWrapper>
    )
  }

  return (
    <Styled.CheckoutWrapper>
      <Styled.Checkout>
        <Header text='Checkout' />
        {renderShipmentDetail()}
        {shippingMethod === 'pickup' ? (
          <Pickup
            selectedPickupDate={selectedPickupDate}
            setSelectedPickupDate={setSelectedPickupDate}
            selectedPickupTime={selectedPickupTime}
            setSelectedPickupTime={setSelectedPickupTime}
            initSelectedPickupDate={initSelectedPickupDate}
            setPickupItems={setPickupItems}
          />
        ) : (
          <Delivery
            initSelectedDeliveryDate={initSelectedDeliveryDate}
            selectedDeliveryDate={selectedDeliveryDate}
            setSelectedDeliveryDate={setSelectedDeliveryDate}
            selectedLocation={selectedLocation}
            setSelectedLocation={setSelectedLocation}
            selectedEvent={selectedEvent}
            setSelectedEvent={setSelectedEvent}
          />
        )}

        {renderCartSummary()}
        {isShowPaymentModal && renderPaymentModal()}
        {isShowConfirmationModal && renderConfirmationModal()}
        {isShowMinimumWarningModal && renderMinimumWarningModal()}
      </Styled.Checkout>
    </Styled.CheckoutWrapper>
  )
}
export default Checkout
