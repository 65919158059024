import { useState, useEffect } from 'react'
import * as Styled from './Register.styled'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import moment from 'moment'
import Button from '../../component/Button'
import OTPInput from '../../component/OTPInput'
import Header from '../../component/Header'
import Radio from '../../component/Radio'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { setModal } from '../App/appSlice'
import Input from '../../component/Input'
import * as InputStyled from '../../component/Input/Input.styled'
import { DatePicker } from 'antd'
import './overrideAntd.scss'
import {
  grantPhoneRequest,
  verifyRegisterOtpRequest,
  registerRequest,
  resendLoginOTP,
} from './actions'
import {
  selectGrantPhoneNumber,
  selectVerifyRegisterOtp,
  resetGrantPhoneNumber,
  selectRegisterResponse,
} from './RegisterSlice'
const Register = (): JSX.Element => {
  const dispatch = useAppDispatch()
  const grantPhoneNumber = useAppSelector(selectGrantPhoneNumber)
  const verifyRegisterOtp = useAppSelector(selectVerifyRegisterOtp)
  const registerResponse = useAppSelector(selectRegisterResponse)

  const [errorMessage, setErrorMessage] = useState<string>('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [firstName, setFirstName] = useState<string>('')
  const [lastName, setLastName] = useState<string>('')
  const [gender, setGender] = useState<'male' | 'female' | 'other'>('male')
  const [dateOfBirth, setDateOfBirth] = useState<any>(moment())
  const [email, setEmail] = useState<string>('')
  const [password, setPassword] = useState<string>('')
  const [token, setToken] = useState<string>('')
  const [otp, setOtp] = useState<string>('')
  const [activeStep, setActiveStep] = useState(0)
  const setInitState = () => {
    setErrorMessage('')
    setPhoneNumber('')
    setFirstName('')
    setLastName('')
    setGender('male')
    setDateOfBirth(moment())
    setEmail('')
    setPassword('')
    setToken('')
    setOtp('')
    setActiveStep(0)
  }
  useEffect(() => {
    if (grantPhoneNumber.status === 'failed') {
      setErrorMessage('This phone number is already registered.')
      setPhoneNumber(grantPhoneNumber.meta.arg)
    } else if (grantPhoneNumber.status === 'succeeded') {
      setErrorMessage('')
      setToken(grantPhoneNumber.data.token)
      setPhoneNumber(grantPhoneNumber.meta.arg)
    } else {
      setInitState()
    }
  }, [grantPhoneNumber])

  useEffect(() => {
    setInitState()
    dispatch(resetGrantPhoneNumber())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (verifyRegisterOtp.status === 'failed') {
      setErrorMessage('OTP incorrect')
    } else if (verifyRegisterOtp.status === 'succeeded') {
      setErrorMessage('')
      setToken(verifyRegisterOtp.data.token)
      setActiveStep((prev) => prev + 1)
    }
  }, [verifyRegisterOtp])

  useEffect(() => {
    if (registerResponse.status === 'failed') {
      setErrorMessage('Something went wrong. Please try again.')
    } else if (registerResponse.status === 'succeeded') {
      setErrorMessage('')
      setActiveStep((prev) => prev + 1)
    }
  }, [registerResponse])

  const handleVerifyPhoneNumber = () => {
    const pattern = /^\d+$/
    if (phoneNumber.length === 10 && pattern.test(phoneNumber)) {
      dispatch(grantPhoneRequest(phoneNumber))
    } else {
      setErrorMessage('Please enter valid phone number.')
    }
  }

  const handleVerifyOTP = () => {
    const pattern = /^\d+$/
    if (otp.length === 6 && pattern.test(otp)) {
      dispatch(verifyRegisterOtpRequest({ otp, token }))
    } else {
      setErrorMessage('Please enter valid otp.')
    }
  }

  const inputPhoneNoItems = [
    {
      label: 'Phone no.',
      key: 'phoneNumber',
      type: 'number',
      value: phoneNumber,
      isRequire: true,
      disabled: grantPhoneNumber.status === 'succeeded',
      setData: setPhoneNumber,
      onEnter: handleVerifyPhoneNumber,
    },
  ]

  const inputInfoItems = [
    {
      label: 'Email',
      key: 'text',
      type: 'text',
      value: email,
      isRequire: false,
      setData: setEmail,
    },
    {
      label: 'Set Password',
      key: 'password',
      type: 'password',
      value: password,
      isRequire: true,
      setData: setPassword,
    },
  ]

  const onChange = (e: any, setState: any, onEnter: any): void => {
    const { target, key } = e
    if (key === 'Enter') {
      onEnter && onEnter()
    } else {
      setState(target.value)
    }
  }

  const handelGenderSelect = (e) => {
    setGender(e.target.value)
  }

  const renderErrorMessage = () => {
    if (errorMessage.length > 0) {
      return (
        <Styled.ErrorMessage>
          <Styled.ErrorIcon />
          {errorMessage}
        </Styled.ErrorMessage>
      )
    } else {
      return <></>
    }
  }

  const renderGenders = () => {
    const data = [
      { key: 'male', label: 'Male' },
      { key: 'female', label: 'Female' },
    ]
    return (
      <Styled.GenderWrapper>
        <Styled.GenderLabel>
          Gender:<Styled.RequireStar>*</Styled.RequireStar>
        </Styled.GenderLabel>
        <Radio
          name='gender'
          value={gender}
          onChange={handelGenderSelect}
          data={data}
        />
      </Styled.GenderWrapper>
    )
  }

  const checkIsAllInputValid = (): boolean => {
    let message = ''
    if (
      firstName === '' ||
      lastName === '' ||
      dateOfBirth === '' ||
      password === ''
    ) {
      message = 'Required field'
    }
    setErrorMessage(message)
    return message === ''
  }

  const renderNameInput = () => {
    const names = [
      {
        key: 'firstName',
        type: 'text',
        value: firstName,
        isRequire: true,
        placeholder: 'First name',
        setData: setFirstName,
      },
      {
        key: 'lastName',
        type: 'text',
        value: lastName,
        isRequire: true,
        placeholder: 'Last name',
        setData: setLastName,
      },
    ]
    return (
      <Styled.NameWrapper>
        <InputStyled.Label>
          Name:<InputStyled.RequireStar>*</InputStyled.RequireStar>
        </InputStyled.Label>
        <Styled.NameInputWrapper>
          {names.map((input) => (
            <Input
              value={input.value}
              placeholder={input.placeholder}
              onChange={(e: any) => onChange(e, input.setData, undefined)}
              isRequire={input.isRequire}
              type={input.type}
              key={input.key}
            />
          ))}
        </Styled.NameInputWrapper>
      </Styled.NameWrapper>
    )
  }

  const renderInputs = (inputs): any =>
    inputs.map((input) => (
      <Input
        onChange={(e: any) => onChange(e, input.setData, input?.onEnter)}
        value={input.value}
        label={input.label}
        type={input.type}
        isRequire={input.isRequire}
        placeholder={input.label}
        key={input.key}
        disabled={input.disabled}
      />
    ))

  const handleRegister = (): void => {
    const isValid = checkIsAllInputValid()
    if (isValid) {
      const dob = new Date(dateOfBirth).toISOString()
      const emailField = email === '' ? {} : { email }
      const payload = {
        token,
        password,
        firstname: firstName,
        lastname: lastName,
        gender,
        dob,
        ...emailField,
      }
      dispatch(registerRequest(payload))
    }
  }
  const onDateChange = (date): void => {
    setDateOfBirth(moment(date).format())
  }

  const renderDOB = () => {
    return (
      <Styled.DateOfBirthWrapper>
        <Styled.GenderLabel>
          Date of Birth: <Styled.RequireStar>*</Styled.RequireStar>
        </Styled.GenderLabel>
        <DatePicker
          onChange={onDateChange}
          defaultValue={moment(dateOfBirth)}
          inputReadOnly
          allowClear={false}
          dropdownClassName='datepicker-antd'
          format='DD MMM YYYY'
        />
      </Styled.DateOfBirthWrapper>
    )
  }

  const renderSeconderStep = () => {
    return (
      <>
        {renderNameInput()}
        {renderDOB()}
        {renderInputs(inputInfoItems)}
        {renderGenders()}
        {renderErrorMessage()}
        <Button onClick={handleRegister}>Register</Button>
      </>
    )
  }

  const handleVerifyFirstStep = () => {
    if (grantPhoneNumber.status === 'succeeded') {
      handleVerifyOTP()
    } else {
      handleVerifyPhoneNumber()
    }
  }

  const renderFirstStep = () => {
    return (
      <>
        {renderInputs(inputPhoneNoItems)}
        {grantPhoneNumber.data.token && (
          <OTPInput
            value={otp}
            onChange={(value) => setOtp(value)}
            resendFn={() =>
              dispatch(resendLoginOTP({ token: grantPhoneNumber.data.token }))
            }
          />
        )}
        {renderErrorMessage()}
        <Button
          onClick={handleVerifyFirstStep}
          isLoading={grantPhoneNumber.status === 'loading'}
        >
          Verify
        </Button>
        {grantPhoneNumber.data.token && (
          <Styled.Link onClick={() => dispatch(resetGrantPhoneNumber())}>
            Register with another phone number
          </Styled.Link>
        )}
      </>
    )
  }

  const renderLastStep = () => {
    return (
      <>
        <Styled.SuccessMessage>
          Congratulations! Your phone number has successfully registered.
        </Styled.SuccessMessage>
        <Button
          onClick={() => dispatch(setModal({ isShow: true, type: 'login' }))}
        >
          Login
        </Button>
      </>
    )
  }

  const steps = [
    'Verify Phone number',
    'User Information',
    'Registration Success',
  ]

  const getActiveContent = () => {
    let content
    if (activeStep === 0) {
      content = renderFirstStep()
    } else if (activeStep === 1) {
      content = renderSeconderStep()
    } else {
      content = renderLastStep()
    }
    return content
  }

  return (
    <Styled.Register>
      <Header text='Register' />
      <Stepper activeStep={activeStep} alternativeLabel>
        {steps.map((step, index) => {
          return (
            <Step key={step} completed={index < activeStep || activeStep === 2}>
              <StepLabel>{step}</StepLabel>
            </Step>
          )
        })}
      </Stepper>
      {getActiveContent()}
      {activeStep !== 2 && (
        <Styled.Footer>
          Already have an account?
          <Styled.Link
            onClick={() => dispatch(setModal({ isShow: true, type: 'login' }))}
          >
            Login
          </Styled.Link>
        </Styled.Footer>
      )}
    </Styled.Register>
  )
}
export default Register
