import * as Styled from './Navbar.styled'
import { Link, useLocation, useHistory } from 'react-router-dom'
import { useAppDispatch } from '../../Hooks/hooks'
import { setModal } from '../../container/App/appSlice'
import RemedyLogo from '../RemedyLogo'
import { FaUserAlt } from 'react-icons/fa'
import { Menu } from 'antd'
import { logout } from '../../container/App/actions'
interface INavbarProp {
  menuType: string[]
  allMenuByType: {
    [key: string]: any[]
  }
  login: {
    isLoggedIn: boolean
  }
  cartSize: number
}
const Navbar = ({
  menuType,
  allMenuByType,
  login,
  cartSize = 0,
}: INavbarProp): JSX.Element => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const location = useLocation()
  const PATH_CART: string = `/cart`
  const PATH_ACCOUNT: string = `/account`
  const PATH_HISTORY: string = `/history`

  const handleToCart = () => {
    if (!login.isLoggedIn) {
      dispatch(setModal({ isShow: true, type: 'loginRequired' }))
    } else {
      history.push(PATH_CART)
    }
  }

  const renderMenu = () => {
    return menuType.map((item) => {
      const menuPath = `/${item.toLowerCase()}`
      const to = `/menus${menuPath}`
      const menuByType =
        Object.keys(allMenuByType).length > 0 &&
        allMenuByType[item].map((menu) => {
          return (
            <Styled.DropdownMenuItem key={menu._id}>
              <Link to={`/menus/${menu._id}/${menu.subMenu[0]?._id}`}>
                {menu.name}
              </Link>
            </Styled.DropdownMenuItem>
          )
        })
      const menuItems = <Styled.DropdownMenu>{menuByType}</Styled.DropdownMenu>
      return (
        <Styled.DropdownStyled overlay={menuItems} key={item}>
          <Styled.Menu key={item} isActive={history.location.pathname === to}>
            <Link to={to}>{item}</Link>
          </Styled.Menu>
        </Styled.DropdownStyled>
      )
    })
  }

  const handleRedirect = (path: string): void => {
    history.push(path)
  }
  const handleLogout = () => {
    dispatch(logout())
  }

  const renderAccountDropdown = () => {
    return (
      <Styled.DropdownMenu>
        <Styled.DropdownMenuItem onClick={() => handleRedirect(PATH_HISTORY)}>
          Order History
        </Styled.DropdownMenuItem>
        <Menu.Divider />
        <Styled.DropdownMenuItem onClick={() => handleRedirect(PATH_ACCOUNT)}>
          My Account
        </Styled.DropdownMenuItem>
        <Styled.DropdownMenuItem onClick={handleLogout}>
          Logout
        </Styled.DropdownMenuItem>
      </Styled.DropdownMenu>
    )
  }
  const toggleIsLogin = () => {
    if (login.isLoggedIn) {
      return (
        <>
          <Styled.DropdownStyled
            overlay={renderAccountDropdown}
            trigger={['click']}
          >
            <FaUserAlt style={{ cursor: 'pointer' }} />
          </Styled.DropdownStyled>
          <Styled.Separator>|</Styled.Separator>
          <div onClick={handleToCart}>
            <Styled.Cart>
              <Styled.CupIcon />
              <Styled.AddedNo isActive={location.pathname === PATH_CART}>
                ({cartSize})
              </Styled.AddedNo>
            </Styled.Cart>
          </div>
        </>
      )
    } else {
      return (
        <>
          <Styled.LoginBTN
            onClick={() => dispatch(setModal({ isShow: true, type: 'login' }))}
          >
            Login
          </Styled.LoginBTN>
          <Styled.Separator>|</Styled.Separator>
          <Styled.LoginBTN
            onClick={() =>
              dispatch(setModal({ isShow: true, type: 'register' }))
            }
          >
            Register
          </Styled.LoginBTN>
        </>
      )
    }
  }
  return (
    <Styled.Navbar>
      <Styled.LogoWrapper>
        <Styled.ReservedBurger />
        <Styled.Burger>
          <RemedyLogo />
          {renderMenu()}
        </Styled.Burger>
        <Styled.LoginWrapper>{toggleIsLogin()}</Styled.LoginWrapper>
      </Styled.LogoWrapper>
    </Styled.Navbar>
  )
}
export default Navbar
