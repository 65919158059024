import * as Styled from './Header.styled'

interface IHeaderProps {
  children?: JSX.Element | JSX.Element[] | string
  text: string
}
const Header = ({ children, text }: IHeaderProps): JSX.Element => {
  return (
    <Styled.Header>
      {text}
      {children && children}
    </Styled.Header>
  )
}
export default Header
