import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { getAllMenu } from './actions'

export interface IMenusState {
  menuList: {
    data: any[]
    status: string
  }
  menuType: string[]
  allMenuByType: {
    [key: string]: any[]
  }
}

const initialState: IMenusState = {
  menuList: { data: [], status: 'idle' },
  menuType: [],
  allMenuByType: {},
}

export const menusSlice = createSlice({
  name: 'menus',
  initialState,
  reducers: {
    setMenuType: (state, action) => {
      state.menuType = action.payload
    },
    setAllMenuByType: (state, action) => {
      state.allMenuByType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAllMenu.pending, (state, action) => {
      state.menuList.status = 'loading'
      state.menuList.data = []
    })
    builder.addCase(getAllMenu.fulfilled, (state, action) => {
      state.menuList.status = 'succeeded'
      state.menuList.data = action.payload
    })
    builder.addCase(getAllMenu.rejected, (state, action) => {
      state.menuList.status = 'failed'
      state.menuList.data = []
    })
  },
})

export const { setMenuType, setAllMenuByType } = menusSlice.actions

export const selectMenuList = (state: RootState) => state.menus.menuList
export const selectMenuType = (state: RootState) => state.menus.menuType
export const selectAllMenuByType = (state: RootState) =>
  state.menus.allMenuByType

export default menusSlice.reducer
