import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import { MENU_URL, SUB_MENU_URL, CART_API } from '../../utils/API'
import { getLocalStorage } from '../../utils/localStorage'
import { getCart } from '../App/actions'

export const getSubMenuById = createAsyncThunk(
  'menuDetail/getSubMenuById',
  async (id: string, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.get(`${SUB_MENU_URL}/${id}`)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

export const getMenuById = createAsyncThunk(
  'menuDetail/getMenuById',
  async (id: string, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.get(`${MENU_URL}/${id}`)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

interface IAddToCartPayload {
  subMenu: string
  choices: string[]
  quantity: number
}
export const addToCart = createAsyncThunk(
  'menuDetail/addToCart',
  async (
    paylaod: IAddToCartPayload,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    const local = getLocalStorage()
    try {
      const response: any = await axios.post(`${CART_API}`, paylaod, {
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      dispatch(getCart())
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
