import styled from 'styled-components'
import {
  setFlex,
  devices,
  setTransition,
  setBoxShadow,
} from '../../styles/themeStyles'
import { BiCoffeeTogo } from 'react-icons/bi'
import { TiThMenu } from 'react-icons/ti'
import { Link } from 'react-router-dom'
import { Dropdown, Menu as AntdMenu } from 'antd'

export const Navbar = styled.div`
  padding: 5px 30px;
  ${devices.tablet} {
    padding: 5px 20px;
  }
  ${devices.mobile} {
    padding: 5px 10px;
  }
  ${setFlex()};
  flex-direction: column;
  position: fixed;
  z-index: 1000;
  background-color: ${({ theme }) => theme.colors.brown3};
  width: 100vw;
  height: 60px;
`
export const Buttons = styled.div`
  ${setFlex('space-between', 'space-between')};
`

export const LogoWrapper = styled.div`
  ${setFlex('space-between')};
  width: 100%;
`

export const Remedy = styled(Link)`
  font-size: 20px;
  letter-spacing: 3px;
  padding: 10px;
`

export const Burger = styled.div`
  background-color: transparent;
  ${setFlex()};
`

export const Menus = styled.div`
  background-color: ${({ theme }) => theme.colors.white};
  width: 100%;
  max-width: 500px;
  align-self: center;
  position: absolute;
  ${setBoxShadow};
  top: 75px;
  border-radius: 50px;
  z-index: 1;
  ${setFlex('space-evenly')};
  ${devices.tablet} {
    margin-top: 0px;
  }
  ${devices.mobile} {
    width: 85vw;
  }
`
interface IMenu {
  isActive?: boolean
}
export const ReservedBurger = styled.div`
  display: none;
  width: 36px;
  height: 30px;
  ${devices.mobile} {
    display: initial;
  }
`

export const Menu = styled.div<IMenu>`
  color: ${({ theme, isActive }) => !isActive || theme.colors.brown2};
  padding: 4px 10px 0px 10px;
  text-transform: uppercase;

  ${setTransition()};
  ${devices.mobile} {
    display: none;
  }
  ${devices.tablet} {
    font-size: 14px;
  }
`
interface ILoginBTN {
  isActive?: boolean
}

export const LoginBTN = styled.button<ILoginBTN>`
  text-transform: capitalize;
  color: ${({ theme, isActive }) => !isActive || theme.colors.brown2};
  ${devices.tablet} {
    font-size: 14px;
  }
  &:hover {
    color: ${({ theme }) => theme.colors.brown2};
  }
`

export const CupIcon = styled(BiCoffeeTogo)`
  height: 20px;
  width: 20px;
  fill: ${({ theme }) => theme.textColor};
`
export const BurgerIcon = styled(TiThMenu)`
  height: 30px;
  width: 30px;
  fill: ${({ theme }) => theme.textColor};
  margin-right: 5px;
`
interface IAddedNo {
  isActive: boolean
}
export const AddedNo = styled.span<IAddedNo>`
  color: ${({ theme, isActive }) => !isActive || theme.colors.brown2};
`

export const Cart = styled.button`
  background-color: transparent;
  ${setFlex()};

  &:hover > ${AddedNo} {
    color: ${({ theme }) => theme.colors.brown2};
  }
  &:hover > ${CupIcon} {
    fill: ${({ theme }) => theme.colors.brown2};
  }
`
export const Separator = styled.span`
  font-size: 16px;
  padding: 0 8px;
`

export const LoginWrapper = styled.div`
  ${setFlex()};
`

export const DropdownStyled = styled(Dropdown)``

export const DropdownMenu = styled(AntdMenu)``

export const DropdownMenuItem = styled(AntdMenu.Item)``
