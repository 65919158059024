import styled from 'styled-components'
import { setTransition } from '../../styles/themeStyles'

export const Header = styled.h1`
  font-size: 30px;
  margin-bottom: 10px;
  ${setTransition()};
  text-align: center;
  font-weight: normal;
  letter-spacing: 4px;
  width: 100%;
`
