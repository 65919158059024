import * as Styled from './Button.styled'
import Spinner from '../Spinner'
export interface IButtonProps {
  children: JSX.Element | JSX.Element[] | string
  onClick?: any
  style?: any
  className?: string
  type?: 'submit' | 'button' | 'reset' | undefined
  renderType?: 'primary' | 'outline' | undefined
  isActive?: boolean
  width?: string
  disabled?: boolean
  isLoading?: boolean
}

const Button = ({
  children,
  onClick,
  className,
  type = 'submit',
  renderType = 'primary',
  isActive = false,
  width = '100%',
  disabled = false,
  isLoading = false,
  style,
}: IButtonProps): JSX.Element => {
  return (
    <Styled.Button
      renderType={renderType}
      onClick={onClick}
      className={className}
      type={type}
      isActive={isActive}
      width={width}
      disabled={disabled}
      style={style}
    >
      {isLoading ? <Spinner width={8} height={10} color='#fff' /> : children}
    </Styled.Button>
  )
}
export default Button
