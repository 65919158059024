import styled from 'styled-components'
import { setFlex, setTransition, devices } from '../../styles/themeStyles'
import { RiInformationLine } from 'react-icons/ri'
import { MotionContainer } from '../../styles/animations'

export const Login = styled.div`
  ${setFlex()};
  ${setTransition()};
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin: auto;
  padding: 20px;
  width: 100%;
  max-width: 500px;
`

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  align-self: flex-start;
  margin: 5px 0;
  font-size: 14px;
  ${setFlex('flex-start')};
  padding: 10px;
  width: 100%;
  ${setTransition()};
`

export const ErrorIcon = styled(RiInformationLine)`
  height: 14px;
  fill: ${({ theme }) => theme.colors.red};
  margin-right: 5px;
`

export const Footer = styled.div`
  ${setFlex('space-between')};
  width: 100%;
  margin: 5px 0;
  ${devices.smallMobile} {
    flex-direction: column;
  }
`
interface ILink {
  width?: string
}
export const Link = styled.button<ILink>`
  padding: 5px;
  cursor: pointer;
  font-size: 14px;
  ${setTransition()};
  text-decoration: underline;
  width: ${({ width = 'fit-content' }) => width};
  &:hover {
    color: ${({ theme }) => theme.colors.brown2};
  }
`
export const OTPWrapper = styled(MotionContainer)`
  width: 100%;
`
export const OTPMessage = styled.p`
  font-size: 12px;
  color: ${({ theme }) => theme.colors.grey1};
  margin-bottom: 5px;
  font-size: 14px;
  text-align: center;
`
