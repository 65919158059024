import * as Styled from './Breadcrumbs.styled'
interface IList {
  label: string
  to: string
  isActive?: boolean
}
interface IBreadcrumbsCustomProps {
  list: IList[]
}

const BreadcrumbsCustom = ({ list }: IBreadcrumbsCustomProps): JSX.Element => {
  const renderLink = () => {
    return list.map((item) =>
      item ? (
        <Styled.List
          key={item.label}
          to={item.to}
          isActive={item?.isActive || false}
        >
          {item.label}
        </Styled.List>
      ) : null
    )
  }

  return <Styled.BreadcrumbsStyle>{renderLink()}</Styled.BreadcrumbsStyle>
}
export default BreadcrumbsCustom
