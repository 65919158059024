import styled from 'styled-components'
import { setFlex, setTransition } from '../../styles/themeStyles'
import { RiInformationLine } from 'react-icons/ri'
import { Link as RouterLink } from 'react-router-dom'
import { MotionContainer } from '../../styles/animations'
export const ForgotPassword = styled(MotionContainer)`
  ${setFlex()};
  ${setTransition()};
  flex-direction: column;
  align-self: center;
  justify-self: center;
  margin: auto;
  padding: 20px;
  width: 100%;
  max-width: 500px;
`

export const ErrorMessage = styled.span`
  color: ${({ theme }) => theme.colors.red};
  align-self: flex-start;
  margin: 5px 0;
  font-size: 14px;
  ${setFlex('flex-start')};
  padding: 10px;
  width: 100%;
  ${setTransition()};
`

export const ErrorIcon = styled(RiInformationLine)`
  height: 14px;
  fill: ${({ theme }) => theme.colors.red};
  margin-right: 5px;
`

export const Footer = styled.div`
  ${setFlex()};
  width: 100%;
  margin: 5px 0;
  font-size: 14px;
`
export const Link = styled(RouterLink)`
  padding: 5px;
  cursor: pointer;
  ${setTransition()};
  text-decoration: underline;
  &:hover {
    color: ${({ theme }) => theme.colors.brown2};
  }
`
export const SuccessMessage = styled.p`
  text-align: center;
  font-size: 20px;
  padding: 30px 20px;
`
