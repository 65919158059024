import { DatePicker } from 'antd'
import * as Styled from './Checkout.styled'
import moment from 'moment-timezone'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { initSelectedDeliveryEvent } from '.'
import { useEffect, useState } from 'react'
import { getDeliverySchedule } from '../App/actions'
import { selectDeliverySchedule } from '../App/appSlice'
import { MotionDiv } from '../../styles/animations'
import defaultImage from '../../images/remedy_coffee_2.webp'
import { onImageError } from '../../utils/imageError'

moment.tz.setDefault('Asia/Bangkok')

const DATE_FORMAT: string = 'DD MMMM YYYY'
const TIME_FORMAT: string = 'HH:mm'

export const Delivery = ({
  initSelectedDeliveryDate,
  selectedDeliveryDate,
  setSelectedDeliveryDate,
  selectedLocation,
  setSelectedLocation,
  selectedEvent,
  setSelectedEvent,
}) => {
  const dispatch = useAppDispatch()

  const deliverySchedule = useAppSelector(selectDeliverySchedule)
  const [scheduleList, setScheduleList] = useState<any[]>([])

  useEffect(() => {
    dispatch(getDeliverySchedule())
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (deliverySchedule.status === 'succeeded') {
      setScheduleList([])
      const formatSchedules: any[] = []
      if (deliverySchedule.data.length) {
        deliverySchedule.data.forEach((schedule) => {
          formatSchedules.push({
            date: moment(schedule._id),
            id: schedule._id,
            location: schedule.schedules,
          })
        })
        setScheduleList(formatSchedules)
        setSelectedDeliveryDate(formatSchedules[0])
      }
    }
    //eslint-disable-next-line
  }, [deliverySchedule.status])

  const getDisabledDate = (current) => {
    let isDisabled
    if (current && scheduleList.length) {
      isDisabled = scheduleList.find(
        ({ date }) => date.format(DATE_FORMAT) === current.format(DATE_FORMAT)
      )
    }
    return !isDisabled
  }

  const isSameDate = (date1, date2 = undefined): boolean =>
    moment(date1).format(DATE_FORMAT) === moment(date2).format(DATE_FORMAT)

  const onSelectDate = (date) => {
    const dateItem = scheduleList.find((schedule) =>
      isSameDate(schedule.date, date)
    )
    setSelectedDeliveryDate(dateItem ?? initSelectedDeliveryDate)
  }

  const onSelectLocation = (e, location) => {
    e.preventDefault()
    setSelectedLocation(location)
    setSelectedEvent(initSelectedDeliveryEvent)
  }

  const renderRangeTime = (from, to) => {
    return `${moment(from).format(TIME_FORMAT)} - ${moment(to).format(
      TIME_FORMAT
    )}`
  }
  const handleEventSelection = (e, event) => {
    e.preventDefault()
    setSelectedEvent({
      id: event._id,
      lastOrderTime: event.lastOrderTime,
      startTime: event.startTime,
      endTime: event.endTime,
    })
  }

  const renderLocation = () => {
    return selectedDeliveryDate.location.map(({ location, deliveryEvents }) => {
      const isActive = location._id === selectedLocation._id
      return (
        <MotionDiv key={location._id}>
          <Styled.LocationWrapper
            isActive={isActive}
            onClick={(e) => !isActive && onSelectLocation(e, location)}
          >
            <Styled.LocationName>{location.name}</Styled.LocationName>
            <Styled.LocationDetail>
              <Styled.LocationImage
                src={location.image || defaultImage}
                alt={location.name}
                onError={onImageError}
              />
              <Styled.LocationDescription>
                {location.description}
              </Styled.LocationDescription>
            </Styled.LocationDetail>
            <Styled.TimeWrapper>
              {isActive && <p>Please select time:</p>}
              {deliveryEvents.map((event) => (
                <Styled.LocationTime
                  isShow={isActive}
                  isActive={event._id === selectedEvent.id}
                  onClick={(e) => handleEventSelection(e, event)}
                >
                  {renderRangeTime(event.startTime, event.endTime)}
                </Styled.LocationTime>
              ))}
            </Styled.TimeWrapper>
          </Styled.LocationWrapper>
        </MotionDiv>
      )
    })
  }

  return (
    <Styled.BoxWrapper>
      {deliverySchedule.status === 'succeeded' &&
      deliverySchedule.data.length ? (
        <>
          <h3>Delivery option:</h3>
          <Styled.Row>
            <span>Select Date:</span>
            <DatePicker
              disabledDate={getDisabledDate}
              onChange={onSelectDate}
              value={selectedDeliveryDate.date}
              inputReadOnly
              format='DD MMM YYYY'
            />
          </Styled.Row>
          <Styled.LocationRow>
            <span>Select Delivery Point:</span>
            <Styled.LocationContainer>
              {renderLocation()}
            </Styled.LocationContainer>
          </Styled.LocationRow>
        </>
      ) : (
        <Styled.OutOfService>Out of Service</Styled.OutOfService>
      )}
    </Styled.BoxWrapper>
  )
}
