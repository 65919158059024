import styled from 'styled-components'
import { IoIosArrowDropupCircle } from 'react-icons/io'
import {
  devices,
  setBoxShadow,
  setBoxShadowHover,
  setTransition,
} from '../../styles/themeStyles'

export const App = styled.div`
  min-height: 100vh;
  position: relative;
  background: ${({ theme }) => theme.backgroundColor};
  display: flex;
  flex-direction: column;
  .ant-back-top-icon {
    svg {
      fill: ${({ theme }) => theme.colors.white};
    }
  }
  .ant-back-top-content {
    background-color: ${({ theme }) => theme.colors.brown2};
    opacity: 0.5;
  }
`
export const Content = styled.div`
  width: 100%;
  padding: 0px 30px 30px 30px;
  background-color: ${({ theme }) => theme.backgroundColor};
  ${devices.smallMobile} {
    padding: 10px 5px 20px 5px;
  }
`
export const AppWrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.backgroundColor};
  height: calc(100vh - 60px);
  margin-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const ToTopButton = styled(IoIosArrowDropupCircle)`
  position: fixed;
  bottom: 40px;
  right: 10px;
  width: 40px;
  height: 40px;
  opacity: 0.5;
  cursor: pointer;
  ${setBoxShadow};
  fill: ${({ theme }) => theme.colors.brown2};
  ${setTransition()};
  ${devices.tablet} {
    bottom: 10px;
  }
  &:hover {
    ${setBoxShadowHover};
    opacity: 1;
  }
`
