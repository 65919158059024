import styled from 'styled-components'

export const BoxWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.whiteTransparent};
  width: 100%;
  padding: 0px 20px;
  display: flex;
  flex-direction: column;
  overflow: auto;
  max-height: 70vh;
  .textarea {
    margin: 10px 0;
  }
  h3 {
    font-size: 18px;
    font-weight: bold;
  }
  h4 {
    font-size: 16px;
    font-weight: bold;
    padding: 0;
    margin: 0;
  }
  hr {
    margin: 10px 0;
  }
  span {
    margin-right: 5px;
  }
`

export const RemedyIcon = styled.img`
  width: 150px;
  align-self: center;
  margin-bottom: 10px;
`

export const Row = styled.div`
  padding-left: 5px;
  display: grid;
  grid-template-columns: 120px minmax(120px, 300px);
  margin: 5px 0;
`

export const QRImage = styled.img`
  width: 200px;
  align-self: center;
  margin-top: 20px;
`

export const PaymentLabel = styled.p`
  &::after {
    content: ':';
    padding: 0 5px;
  }
`

export const Price = styled.p`
  text-align: end;
  margin-right: 10px;
  &::before {
    content: '฿';
  }
`
export const Discount = styled.p`
  text-align: end;
  margin-right: 10px;
  &::before {
    content: '-฿';
  }
`
export const TotalPrice = styled.p`
  text-align: end;
  margin-right: 10px;
  font-weight: bold;
  &::before {
    content: '฿';
  }
`
export const TotalLabel = styled.p`
  font-weight: bold;
  &::after {
    content: ':';
    padding: 0 5px;
  }
`
