import { useCallback, useState } from 'react'
import * as Styled from './Menus.styled'
import { useEffect } from 'react'
import Header from '../../component/Header'
import { useAppSelector, useAppDispatch } from '../../Hooks/hooks'
import { selectMenuList } from './menuSlice'
import { getAllMenu } from './actions'
import Menu from '../../component/Menu'
import Breadcrumbs from '../../component/Breadcrumbs'
import Spinner from '../../component/Spinner'
import { MotionContainer } from '../../styles/animations'

const Menus = ({ match }): JSX.Element => {
  const dispatch = useAppDispatch()
  const pathname = match.url
  const menuList = useAppSelector(selectMenuList)
  const [menuByType, setMenuByType] = useState<any[] | []>([])

  const filterMenuList = () => {
    if (pathname !== '/menus') {
      const filtered = menuList.data.filter(
        (menu) => `/menus/${menu.type.toLowerCase()}` === pathname
      )
      setMenuByType(filtered)
    } else {
      setMenuByType(menuList.data)
    }
  }

  useEffect(() => {
    if (menuList.status === 'idle') {
      dispatch(getAllMenu())
    }
    filterMenuList()
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    filterMenuList()
    // eslint-disable-next-line
  }, [menuList, pathname])

  const toggleState = useCallback((): JSX.Element[] | JSX.Element => {
    const renderMenuList = () => {
      return (
        <MotionContainer>
          <Styled.MenuListWrapper>
            {menuByType.map((menu) => (
              <Menu menu={menu} key={menu._id} />
            ))}
          </Styled.MenuListWrapper>
        </MotionContainer>
      )
    }

    let result = <></>
    const { status } = menuList
    if (status === 'loading') {
      result = <Spinner />
    } else if (status === 'failed') {
      result = <>Fetching data error...</>
    } else {
      result = renderMenuList()
    }
    return result
  }, [menuList, menuByType])
  const allMenuCrumb = match.params?.menuType && {
    label: 'Menu',
    to: '/menus',
  }
  return (
    <Styled.Menus>
      <Header text={match.params?.menuType?.toUpperCase() || 'MENU'} />
      <Breadcrumbs
        list={[
          allMenuCrumb,
          {
            label: match.params?.menuType,
            to: match.url,
            isActive: true,
          },
        ]}
      />
      {toggleState()}
    </Styled.Menus>
  )
}
export default Menus
