import { createAsyncThunk } from '@reduxjs/toolkit'
import { getLocalStorage } from '../../utils/localStorage'
import axios from 'axios'
import { CHANGE_PASSWORD_API } from '../../utils/API'

interface IChangePasswordRequest {
  password: string
  newPassword: string
}

export const changePasswordRequest = createAsyncThunk(
  'changePassword/changePasswordRequest',
  async (
    { password, newPassword }: IChangePasswordRequest,
    { rejectWithValue }
  ): Promise<any> => {
    const local = getLocalStorage()
    try {
      const response = await axios({
        method: 'post',
        url: CHANGE_PASSWORD_API,
        data: {
          password,
          newPassword,
        },
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      return response.data
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)
