import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import {
  GRANT_PHONE_API,
  VERIFY_OTP_API,
  REGISTER_API,
  RESEND_OTP_API,
} from '../../utils/API'

export const grantPhoneRequest = createAsyncThunk(
  'regisger/grantPhoneRequest',
  async (phone: string, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(GRANT_PHONE_API, { phone })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

interface IPayload {
  otp: string
  token: string
}
export const verifyRegisterOtpRequest = createAsyncThunk(
  'regisger/verifyRegisterOtpRequest',
  async ({ otp, token }: IPayload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(VERIFY_OTP_API, {
        type: 'register',
        token: otp,
        verifyOtpToken: token,
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

interface IRegisterPayload {
  token: string
  password: string
  firstname: string
  lastname: string
  gender: string
  dob: string
  email?: string
}

export const registerRequest = createAsyncThunk(
  'regisger/registerRequest',
  async (payload: IRegisterPayload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(REGISTER_API, payload)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)

interface IResendOTPPayload {
  token: string
}
export const resendLoginOTP = createAsyncThunk(
  'app/resendLoginOTP',
  async ({ token }: IResendOTPPayload, { rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.post(RESEND_OTP_API, {
        verifyOtpToken: token,
      })
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
