import * as Styled from './Input.styled'
import { useState, useCallback } from 'react'
interface IInputProps {
  label?: JSX.Element | string
  type: string
  value: string
  placeholder: string
  onChange: any
  key?: string
  isRequire?: boolean
  className?: string
  disabled?: boolean
  name?: string
}
const Input = ({
  label,
  type,
  value,
  onChange,
  placeholder,
  key,
  isRequire,
  className,
  disabled = false,
  name,
}: IInputProps): JSX.Element => {
  const [isShowPassword, setIsShowPassword] = useState(false)
  const [typeState, setTypeState] = useState(type)

  const renderTogglePassword = useCallback(() => {
    const togglePassword = () => {
      const temp = !isShowPassword
      setIsShowPassword(!isShowPassword)
      setTypeState(temp ? 'text' : type)
    }
    return (
      type === 'password' && (
        <Styled.TogglePassword onClick={togglePassword}>
          {isShowPassword ? <Styled.ShowPassword /> : <Styled.HidePassword />}
        </Styled.TogglePassword>
      )
    )
  }, [isShowPassword, type])

  return (
    <Styled.Wrapper key={key} className={className}>
      {label && (
        <Styled.Label>
          {label}
          {isRequire && <Styled.RequireStar>*</Styled.RequireStar>}
        </Styled.Label>
      )}
      <Styled.InputWrapper disabled={disabled}>
        <Styled.Input
          type={typeState}
          value={value}
          onChange={onChange}
          onKeyDown={onChange}
          placeholder={placeholder}
          disabled={disabled}
          name={name}
        />
        {renderTogglePassword()}
      </Styled.InputWrapper>
    </Styled.Wrapper>
  )
}
export default Input
