import React from 'react'
import { useHistory } from 'react-router-dom'

import { MotionDiv } from '../../styles/animations'

import NumberCounter from '../NumberCounter'
import imageDefault from '../../images/remedy_coffee_2.webp'
import * as Styled from './Menu.styled'
import { onImageError } from '../../utils/imageError'

interface ISubMenu {
  name: string
  _id: string
  basePrice: number
  image: string
  active: boolean
}

interface IMenuProps {
  menu: {
    name: string
    description: string
    type: string
    subMenu: [] | ISubMenu[]
    _id: string
  }
}

const Menu = ({ menu }: IMenuProps): JSX.Element => {
  const history = useHistory()

  const haveSubMenu = menu.subMenu.length > 0

  const [activeSubMenu, setActiveSubMenu] = React.useState<string>(
    haveSubMenu ? menu.subMenu[0]._id : ''
  )
  const [price, setPrice] = React.useState<number>(
    haveSubMenu ? menu.subMenu[0].basePrice : 0
  )
  const [image, setImage] = React.useState<string>(
    haveSubMenu ? menu.subMenu[0].image : ''
  )

  const [isActive, setsIsActive] = React.useState<boolean>(
    haveSubMenu ? menu.subMenu[0].active : false
  )

  const handleCustomizeClicked = () => {
    const subMenuPath = haveSubMenu ? `/${activeSubMenu}` : ''
    history.push(`/menus/${menu._id}${subMenuPath}`)
  }

  const renderSubMenu = (): JSX.Element => {
    const onSelectSubMenu = ({ basePrice, image, _id, active }) => {
      setActiveSubMenu(_id)
      setPrice(basePrice)
      setImage(image)
      setsIsActive(active)
    }
    let subMenu
    if (haveSubMenu) {
      subMenu = menu.subMenu.map((subMenu) => (
        <Styled.SubMenu
          key={subMenu._id}
          isActive={activeSubMenu === subMenu._id}
          onClick={() => onSelectSubMenu(subMenu)}
        >
          {subMenu.name}
        </Styled.SubMenu>
      ))
    } else {
      subMenu = <></>
    }
    return subMenu
  }

  return (
    <MotionDiv>
      <Styled.Menu>
        <div>
          <Styled.ImageWrapper>
            <Styled.Image
              src={image || imageDefault}
              alt={menu.name}
              onError={onImageError}
            />
            <Styled.OutOfStock isActive={isActive}>
              Out of Stock
            </Styled.OutOfStock>
          </Styled.ImageWrapper>

          <Styled.NameType>
            <Styled.Name>{menu.name}</Styled.Name>
            <Styled.SubMenuWrapper>{renderSubMenu()}</Styled.SubMenuWrapper>
          </Styled.NameType>
          <Styled.Description>{menu.description}</Styled.Description>
        </div>
        <Styled.ButtonWrapper>
          <Styled.Price>
            ฿ <NumberCounter target={price} duration={70} />
          </Styled.Price>
          <Styled.AddButton onClick={handleCustomizeClicked}>
            <Styled.AddButtonIcon />
          </Styled.AddButton>
        </Styled.ButtonWrapper>
      </Styled.Menu>
    </MotionDiv>
  )
}
export default Menu
