import { createAsyncThunk } from '@reduxjs/toolkit'

import axios from 'axios'

import { MENU_URL } from '../../utils/API'

import { setMenuType, setAllMenuByType } from './menuSlice'

const storeMenuTypes = (menus: { type: string }[], dispatch: any): void => {
  const types = menus.map((menu: { type: string }) => {
    return menu.type.toLowerCase()
  })
  const unique = types.filter((type, index) => types.indexOf(type) === index)
  unique.sort((a, b) => (a > b ? 1 : -1))
  dispatch(setMenuType([...unique]))
  let temp = unique.map((item) => ({
    [item]: menus.filter((menu) => menu.type.toLowerCase() === item),
  }))

  const allMenuByType = temp.reduce(
    (result, current) => Object.assign(result, current),
    {}
  )
  dispatch(setAllMenuByType({ ...allMenuByType, all: menus }))
}

export const getAllMenu = createAsyncThunk(
  'app/getAllMenu',
  async (payload, { dispatch, rejectWithValue }): Promise<any> => {
    try {
      const response: any = await axios.get(MENU_URL)
      storeMenuTypes(response.data, dispatch)
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response)
    }
  }
)
