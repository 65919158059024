import { motion } from 'framer-motion'
import { keyframes } from 'styled-components'
export const bounceIn = keyframes`
  0%{
    opacity: 0;
    transform: scale(0.3) translate3d(0,0,0);
  }
  50%{
    opacity: 0.9;
    transform: scale(1.1);
  }
  80%{
    opacity: 1;
    transform: scale(0.89);
  }
  100%{
    opacity: 1;
    transform: scale(1) translate3d(0,0,0);
  }
`
export const fadeIn = keyframes`
  0%{
    opacity: 0;
  }
  100%{
    opacity: 1;
  }
`

interface IMotionProps {
  children: JSX.Element | JSX.Element[] | string | string[]
  className?: string
}

export const MotionContainer = ({ children, className }: IMotionProps) => {
  const containerAnimation = {
    hidden: { opacity: 1, scale: 0 },
    visible: {
      opacity: 1,
      scale: 1,
      transition: {
        delayChildren: 0.3,
        staggerChildren: 0.2,
        duration: 0.5,
      },
    },
  }

  return (
    <motion.div
      variants={containerAnimation}
      initial='hidden'
      animate='visible'
      className={className}
    >
      {children}
    </motion.div>
  )
}

export const MotionDiv = ({ children, className }: IMotionProps) => {
  const menuAnimation = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: {
        duration: 1,
      },
    },
  }
  return (
    <motion.div variants={menuAnimation} className={className}>
      {children}
    </motion.div>
  )
}
