import { createAsyncThunk } from '@reduxjs/toolkit'
import axios from 'axios'
import { DELIVERY_API, PICKUP_API, VERIFY_PAYMENT_API } from '../../utils/API'
import { getLocalStorage } from '../../utils/localStorage'
import { getHistory } from '../Account/actions'
import { getCart } from '../App/actions'

export interface ICheckoutPickup {
  carts: string[]
  pickup: {
    schedule: string
    time: string
  }
  instruction: string
}
export interface ICheckoutDelivery {
  carts: string[]
  deliveryEvent: string
  instruction: string
}

export const checkoutPickupRequest = createAsyncThunk(
  'checkout/checkoutPickupRequest',
  async (
    payload: ICheckoutPickup,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response = await axios({
        method: 'post',
        url: PICKUP_API,
        data: payload,
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      dispatch(getCart())
      return response.data
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)

export const checkoutDeliveryRequest = createAsyncThunk(
  'checkout/checkoutDeliveryRequest',
  async (
    payload: ICheckoutDelivery,
    { rejectWithValue, dispatch }
  ): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response = await axios({
        method: 'post',
        url: DELIVERY_API,
        data: payload,
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      dispatch(getCart())
      return response.data
    } catch (err) {
      return rejectWithValue(err.response)
    }
  }
)
export const verifyPayment = createAsyncThunk(
  'checkout/verifyPayment',
  async (id: string, { rejectWithValue, dispatch }): Promise<any> => {
    try {
      const local = getLocalStorage()
      const response = await axios({
        method: 'post',
        url: `${VERIFY_PAYMENT_API}/${id}`,
        headers: { Authorization: `Bearer ${local.login.token}` },
      })
      dispatch(getCart())
      dispatch(getHistory())
      return response.data
    } catch (err) {
      const { message, status } = err.response.data.error
      if (message === 'Order already paid.' && status === 400) {
        dispatch(getCart())
        dispatch(getHistory())
        return 'success'
      }
      return rejectWithValue(err.response)
    }
  }
)
