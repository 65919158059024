import { useState, useEffect } from 'react'
import Header from '../../component/Header'
import * as Styled from './Account.styled'
import { useAppDispatch, useAppSelector } from '../../Hooks/hooks'
import { cancelOrder, getHistory, getUserInfo } from './actions'
import { Collapse, message, Pagination } from 'antd'
import Spinner from '../../component/Spinner'
import Payment from '../../component/Payment'
import OverlayModal from '../../component/OverlayModal'
import Button from '../../component/Button'
import './overrideAntd.scss'
import { verifyPayment } from '../Checkout/actions'
import {
  selectCancelOrderStatus,
  selectHistory,
  selectUserInfo,
  setInitialCancelOrderStatus,
} from './accountSlice'
import moment from 'moment-timezone'
import { useHistory } from 'react-router'
const { Panel } = Collapse
moment.tz.setDefault('Asia/Bangkok')

interface ICustomizes {
  choiceId: string
  choiceName: string
  customizeId: string
  customizeName: string
}
interface IProducts {
  menu: string
  quantity: number
  subMenu: string
  totalPrice: number
  _id: string
  customizes: ICustomizes[]
}
interface IHistory {
  createdAt: string
  discount: number
  instruction: string
  location: { name: string; startTime: string; endTime: string }
  orderStatus: string
  orderType: string
  originalPrice: number
  paymentId: string
  paymentQRUrl: string
  paymentStatus: string
  paymentType: string
  products: IProducts[]
  summaryPrice: number
  updatedAt: string
  usedPoint?: number
  _id: string
}
const MAX_ROW: number = 10

enum OrderStatus {
  CANCEL = 'cancel',
  QUEUE = 'queue',
  DONE = 'done',
}
enum PaymentStatus {
  REFUND = 'refund',
  FAILED = 'failed',
  EXPIRED = 'expired',
  PENDING = 'pending',
  SUCCESS = 'success',
}
enum OrderType {
  STORE = 'store',
  PICKUP = 'pickup',
  DELIVERY = 'delivery',
}

export enum HistoryStatus {
  COMPLETED = 'completed',
  CANCELED = 'canceled',
  WAITING = 'waiting for payment',
  PREPARING = 'order preparing',
  REFUNDED = 'refunded',
}

export enum CanceledMessage {
  REFUND = '*This order has been refunded.',
  FAILED = '*There was an error processing your payment. Please contact support (098 274 0302).',
  EXPIRED = '*This transaction has been expired.',
}

const History = () => {
  const dispatch = useAppDispatch()
  const history = useHistory()
  const cancelOrderStatus = useAppSelector(selectCancelOrderStatus)
  const myHistory = useAppSelector(selectHistory)
  const userInfo = useAppSelector(selectUserInfo)

  const [isShowPaymentModal, setIsShowPaymentModal] = useState(false)
  const [isCancelModal, setIsCancelModal] = useState(false)
  const [dataPerPage, setDataPerPage] = useState<IHistory[]>([])
  const [activePage, setActivePage] = useState(1)
  const [paymentDetail, setPaymentDetail] = useState({
    _id: '',
    location: { name: '' },
  })
  const [menuToBeCanceled, setMenuToBeCanceled] = useState<any>({ _id: '' })

  useEffect(() => {
    if (myHistory.status === 'idle') {
      dispatch(getHistory())
    }
    if (userInfo.status === 'idle') {
      dispatch(getUserInfo())
    }
    //eslint-disable-next-line
  }, [])

  const formatDataPerPage = (page) => {
    let items = myHistory.data.slice((page - 1) * MAX_ROW, page * MAX_ROW)
    setDataPerPage(items)
  }

  useEffect(() => {
    if (myHistory.status === 'succeeded') {
      setActivePage(1)
      formatDataPerPage(1)
    }
    //eslint-disable-next-line
  }, [myHistory.status])

  useEffect(() => {
    if (cancelOrderStatus === 'succeeded') {
      message.success('Your order has been successfully canceled')
      setIsCancelModal(false)
    } else if (cancelOrderStatus === 'failed') {
      message.error('Your order has been unsuccessfully canceled')
      setIsCancelModal(false)
    } else if (cancelOrderStatus === 'loading') {
      message.loading('Canceling Order')
    }
    setTimeout(() => {
      dispatch(setInitialCancelOrderStatus())
    }, 500)
    //eslint-disable-next-line
  }, [cancelOrderStatus])

  const handlePaidClicked = () => {
    dispatch(verifyPayment(paymentDetail._id))
    setIsShowPaymentModal(false)
  }

  const renderCancelModal = () => {
    return (
      isCancelModal && (
        <OverlayModal
          onClose={() => {
            setIsCancelModal(false)
          }}
        >
          <Styled.CancelModal>
            <Header text='CANCEL ORDER' />
            <h3>Are you sure you want to cancel this order?</h3>
            <p>
              This will cancel all menu in your order and This action cannot be
              undone.
            </p>
            <Styled.CancelButtonWrapper>
              <Button
                renderType='outline'
                onClick={() => {
                  setIsCancelModal(false)
                }}
              >
                Keep Order
              </Button>
              <Button
                onClick={() => {
                  dispatch(cancelOrder(menuToBeCanceled?._id))
                }}
              >
                Cancel Order
              </Button>
            </Styled.CancelButtonWrapper>
          </Styled.CancelModal>
        </OverlayModal>
      )
    )
  }

  const renderHistory = () => {
    const handelPayClicked = (data) => {
      setPaymentDetail(data)
      setIsShowPaymentModal(true)
    }

    const handleCancelClick = (data) => {
      setIsCancelModal(true)
      setMenuToBeCanceled(data)
    }

    const renderEmptyHistory = () => (
      <Styled.ZeroState>
        <Styled.CoffeeIcon />
        <p>NO ORDER HISTORY</p>
        <Button width='fit-content' onClick={() => history.push('/menus')}>
          <>
            ORDER NOW
            <Styled.Arrow />
          </>
        </Button>
      </Styled.ZeroState>
    )

    const getStatus = (orderType, orderStatus, paymentStatus): JSX.Element => {
      const pickupOrDeliveryStatus = {
        [OrderStatus.CANCEL]: {
          [PaymentStatus.PENDING]: HistoryStatus.CANCELED,
          [PaymentStatus.SUCCESS]: HistoryStatus.CANCELED,
          [PaymentStatus.REFUND]: HistoryStatus.REFUNDED,
          [PaymentStatus.EXPIRED]: HistoryStatus.CANCELED,
          [PaymentStatus.FAILED]: HistoryStatus.CANCELED,
        },
        [OrderStatus.QUEUE]: {
          [PaymentStatus.PENDING]: HistoryStatus.WAITING,
          [PaymentStatus.SUCCESS]: HistoryStatus.PREPARING,
          [PaymentStatus.REFUND]: HistoryStatus.REFUNDED,
          [PaymentStatus.EXPIRED]: HistoryStatus.PREPARING,
          [PaymentStatus.FAILED]: HistoryStatus.PREPARING,
        },
        [OrderStatus.DONE]: {
          [PaymentStatus.PENDING]: HistoryStatus.COMPLETED,
          [PaymentStatus.SUCCESS]: HistoryStatus.COMPLETED,
          [PaymentStatus.REFUND]: HistoryStatus.REFUNDED,
          [PaymentStatus.EXPIRED]: HistoryStatus.COMPLETED,
          [PaymentStatus.FAILED]: HistoryStatus.COMPLETED,
        },
      }
      const statusMessage = {
        [OrderType.PICKUP]: pickupOrDeliveryStatus,
        [OrderType.DELIVERY]: pickupOrDeliveryStatus,
        [OrderType.STORE]: {
          [OrderStatus.QUEUE]: pickupOrDeliveryStatus[OrderStatus.DONE],
          [OrderStatus.DONE]: pickupOrDeliveryStatus[OrderStatus.DONE],
        },
      }

      const result = statusMessage[orderType][orderStatus][paymentStatus]
      return <Styled.OrderStatus status={result}>{result}</Styled.OrderStatus>
    }

    if (myHistory.status === 'loading') {
      return <Spinner />
    } else if (myHistory.status === 'failed') {
      return <div>Something went wrong. Cannot get your order history</div>
    } else if (myHistory.data.length === 0) {
      return renderEmptyHistory()
    } else if (myHistory.status === 'succeeded' && dataPerPage.length > 0) {
      const historyItems = dataPerPage.map((item: IHistory, index) => {
        const shipmentTime =
          item.location.name === 'store'
            ? moment(item.location.startTime).format('HH:mm')
            : `${moment(item.location.startTime).format('HH:mm')}-${moment(
                item.location.endTime
              ).format('HH:mm')}`

        const shipmentDateTime = `${moment(item.location.startTime).format(
          'DD MMM YYYY'
        )} `

        const orderTypeItem = {
          store: { label: 'walk-in', icon: <Styled.WalkinIcon /> },
          delivery: { label: 'delivery', icon: <Styled.DeliveryIcon /> },
          pickup: { label: 'pick-up', icon: <Styled.PickupIcon /> },
        }

        const isRequiredPayment: boolean =
          item.paymentStatus === PaymentStatus.PENDING &&
          item.orderStatus !== OrderStatus.CANCEL &&
          item.orderStatus !== OrderStatus.DONE

        const renderCancelOrderButton = () => {
          return (
            isRequiredPayment && (
              <Styled.CancelButton onClick={() => handleCancelClick(item)}>
                Cancel
              </Styled.CancelButton>
            )
          )
        }

        const renderPayNowButton = () => {
          return (
            isRequiredPayment && (
              <Styled.PayButton onClick={() => handelPayClicked(item)}>
                Pay now
              </Styled.PayButton>
            )
          )
        }
        const header = (
          <Styled.OrderTitleWrapper>
            <Styled.OrderDateWrapper>
              <Styled.OrderType>
                {orderTypeItem[item.orderType].icon}
                {orderTypeItem[item.orderType].label}
              </Styled.OrderType>
              <Styled.OrderDateRow>
                <p>Date:</p>
                {shipmentDateTime}
                <div style={{ margin: '0 5px' }}></div>
                <p>Time:</p>
                {shipmentTime}
              </Styled.OrderDateRow>
            </Styled.OrderDateWrapper>
            <Styled.StatusWrapper>
              {getStatus(item.orderType, item.orderStatus, item.paymentStatus)}
              {renderPayNowButton()}
            </Styled.StatusWrapper>
          </Styled.OrderTitleWrapper>
        )

        const renderShipmentDetail = () => {
          return (
            <div>
              {item.location.name === 'store' ? (
                <>
                  Pick up at <span>store </span>
                </>
              ) : (
                <>
                  Delivery to <span>{item.location.name}</span>
                </>
              )}
            </div>
          )
        }

        const renderCustomize = (product) => {
          return product.customizes.map(
            (cus, index) =>
              `${cus.choiceName} ${
                product.customizes.length - 1 === index ? '' : ', '
              }`
          )
        }
        const renderCancelMessage = (
          paymentStatus: string,
          orderStatus: string
        ) => {
          const paymentErrorMessage = {
            [PaymentStatus.REFUND]: CanceledMessage.REFUND,
            [PaymentStatus.FAILED]: CanceledMessage.FAILED,
            [PaymentStatus.EXPIRED]: CanceledMessage.EXPIRED,
          }
          return (
            orderStatus === OrderStatus.CANCEL && (
              <Styled.PaymentErrorMessage>
                {paymentErrorMessage[paymentStatus]}
              </Styled.PaymentErrorMessage>
            )
          )
        }
        return (
          <Collapse accordion>
            <Panel header={header} key={index}>
              <Styled.DeliveryWrapper>
                {renderShipmentDetail()}
              </Styled.DeliveryWrapper>

              <Styled.OrderDateRow>
                <p>Transaction Date:</p>
                <span>
                  {moment(item.createdAt).format('DD MMM YYYY HH:mm')}
                </span>
              </Styled.OrderDateRow>
              {item.orderType === OrderType.PICKUP && (
                <Styled.OrderDateRow>
                  <p>Customer Name:</p>
                  <span>{`${userInfo.data.firstname} ${userInfo.data.lastname}`}</span>
                </Styled.OrderDateRow>
              )}
              <Styled.OrderDateRow>
                <p>Special Note:</p>
                {item.instruction}
              </Styled.OrderDateRow>
              {item.products.map((product: IProducts, index) => (
                <Styled.OrderMenuWrapper key={index}>
                  <Styled.MenuDetail>
                    <Styled.Quantity>{product.quantity}</Styled.Quantity>
                    <Styled.MenuName>{product.menu}</Styled.MenuName>
                    <Styled.SubMenuName>{product.subMenu}</Styled.SubMenuName>
                    <Styled.Customize>
                      {renderCustomize(product)}
                    </Styled.Customize>
                  </Styled.MenuDetail>
                  <Styled.MenuPrice>
                    {product.totalPrice.toLocaleString()}
                  </Styled.MenuPrice>
                </Styled.OrderMenuWrapper>
              ))}
              <Styled.PriceWrapper>
                <Styled.OriginalPriceWrapper>
                  <p>Subtotal</p>
                  <Styled.Price>
                    {item.originalPrice.toLocaleString()}
                  </Styled.Price>
                </Styled.OriginalPriceWrapper>

                {item?.usedPoint && item?.usedPoint > 0 ? (
                  <Styled.OriginalPriceWrapper>
                    <p>Total Points used</p>
                    <Styled.Point>
                      {item?.usedPoint.toLocaleString()}
                    </Styled.Point>
                  </Styled.OriginalPriceWrapper>
                ) : (
                  <></>
                )}

                <Styled.OriginalPriceWrapper>
                  <p>Discount</p>
                  <Styled.Discount>
                    {item.discount.toLocaleString()}
                  </Styled.Discount>
                </Styled.OriginalPriceWrapper>
                <Styled.OriginalPriceWrapper>
                  <Styled.TotalPriceLabel>Total</Styled.TotalPriceLabel>
                  <Styled.TotalPrice>
                    {item.summaryPrice.toLocaleString()}
                  </Styled.TotalPrice>
                </Styled.OriginalPriceWrapper>

                {renderCancelOrderButton()}
                {renderCancelMessage(item.paymentStatus, item.orderStatus)}
              </Styled.PriceWrapper>
            </Panel>
          </Collapse>
        )
      })
      return (
        <Styled.HistoryItemWrapper>{historyItems}</Styled.HistoryItemWrapper>
      )
    }
  }

  const onPageChange = (page) => {
    setActivePage(page)
    formatDataPerPage(page)
  }

  return (
    <Styled.Account>
      <Styled.Section>
        <Header text='Your Order History' />
        <Styled.HistoryWrapper>{renderHistory()}</Styled.HistoryWrapper>
        {myHistory.data.length !== 0 && (
          <Styled.PaginateWrapper>
            <Pagination
              size='small'
              total={myHistory.data.length}
              defaultPageSize={MAX_ROW}
              current={activePage}
              onChange={onPageChange}
              showSizeChanger={false}
            />
          </Styled.PaginateWrapper>
        )}
        {isShowPaymentModal && (
          <Payment
            setIsShow={isShowPaymentModal}
            data={paymentDetail}
            onPaidClicked={handlePaidClicked}
            shippingMethod={
              paymentDetail.location.name === 'store' ? 'pickup' : 'delivery'
            }
            onCloseModal={() => setIsShowPaymentModal(false)}
          />
        )}
        {renderCancelModal()}
      </Styled.Section>
    </Styled.Account>
  )
}

export default History
