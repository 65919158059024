import styled, { css } from 'styled-components'
import { setFlex, devices, setTransition } from '../../styles/themeStyles'
import Button from '../../component/Button'
import { MdAddCircle, MdRemoveCircle } from 'react-icons/md'
export const MenuDetail = styled.div`
  max-width: 60vw;
  width: 100%;
  margin: auto;

  ${devices.tablet} {
    max-width: 90vw;
  }
`

export const Detail = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-column-gap: 20px;
  margin-bottom: 20px;
  ${devices.mobile} {
    margin-bottom: 10px;
    ${setFlex('center', 'flex-start')};
    flex-direction: column;
  }
`

export const Description = styled.p`
  color: ${({ theme }) => theme.colors.grey1};
  margin: 10px 0;
  text-align: start;
  width: 100%;
  font-family: 'Cardo';

  ${devices.mobile} {
    text-align: center;
  }
`
export const Name = styled.div`
  font-size: 50px;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin: 5px 0;
`
export const Type = styled.div`
  font-size: 16px;
  font-weight: lighter;
`

export const RadioWrapper = styled.div`
  ${setFlex()};
  flex-direction: column;
  width: 100%;
  .radio-submenu {
    ${setFlex()};
  }
`
export const MenuDetailWrapper = styled.div`
  width: 100%;
`
export const RadioTitle = styled.div`
  font-weight: bold;
  margin-top: 10px;
  align-self: flex-start;
`
export const AddToCart = styled(Button)`
  width: 100%;
  margin-top: 10px;
`
export const Customize = styled.div`
  ${setFlex('flex-start', 'flex-start')};
  flex-direction: column;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin: 5px 0;
  background-color: ${({ theme }) => theme.colors.whiteTransparent};

  .radio-choice {
    width: 100%;
    ${setFlex('flex-start')};
    background-color: ${({ theme }) => theme.colors.white};
    border-radius: 5px;
    margin: 10px 0;
    border: 1px solid ${({ theme }) => theme.colors.white};
    padding: 0 20px;
    ${setTransition()};
    &:hover {
      border: 1px solid ${({ theme }) => theme.colors.brown2};
    }
  }
`

export const CustomizeTitle = styled.p`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.brown2};
`

export const Choices = styled.div`
  width: 100%;
`

export const Quantity = styled.p`
  font-size: 20px;
  margin: 0 10px;
`
export const QuantityButton = styled.button`
  ${setFlex()};
`

const IconStyle = css`
  width: 30px;
  height: 30px;
  fill: ${({ theme }) => theme.colors.brown2};
`
export const AddIcon = styled(MdAddCircle)`
  ${IconStyle};
`
export const RemoveIcon = styled(MdRemoveCircle)`
  ${IconStyle};
`

export const QuantityWrapper = styled.div`
  ${setFlex()};
  margin: 20px 0;
`
export const TotalPrice = styled.div`
  text-align: center;
  font-size: 24px;
  margin: 10px 0;
`

export const SubMenuImage = styled.img`
  border-radius: 5px;
  align-self: center;
  width: 100%;
`

export const ImageWrapper = styled.div`
  position: relative;
  ${setFlex()};
  width: 100%;
`
export const OutOfStock = styled.div<{ isActive: boolean }>`
  color: ${({ theme }) => theme.colors.white};
  background-color: rgba(0, 0, 0, 0.7);
  ${setFlex()};
  border-radius: 5px;
  position: relative;
  opacity: ${({ isActive }) => (isActive ? 0 : 1)};
  ${setTransition()};
  position: absolute;
  width: 100%;
  height: 100%;
  font-size: 20px;
`
