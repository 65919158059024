import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { getMenuById, getSubMenuById } from './actions'

interface ICustomize {
  _id: string
  image: string
  choice: [] | any[]
  name: string
}
interface ISubMenu {
  _id: string
  active: boolean
  basePrice: number
  image: string
  name: string
  customizes: ICustomize[] | []
}
interface IMenu {
  _id: string
  name: string
  type: string
  description: string
  subMenu: ISubMenu[]
}

interface IMenusState {
  menu: {
    data: IMenu
    status: string
  }
  subMenu: {
    data: ISubMenu
    status: string
  }
}

const intiMenu: IMenu = {
  _id: '',
  name: '',
  type: '',
  description: '',
  subMenu: [],
}

const intiSubMenu: ISubMenu = {
  _id: '',
  active: true,
  basePrice: 0,
  image: '',
  name: '',
  customizes: [],
}

const initialState: IMenusState = {
  menu: {
    status: 'idle',
    data: intiMenu,
  },
  subMenu: {
    status: 'idle',
    data: intiSubMenu,
  },
}

export const menusSlice = createSlice({
  name: 'menuDetail',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMenuById.pending, (state, action) => {
      state.menu.status = 'loading'
      state.menu.data = intiMenu
    })
    builder.addCase(getMenuById.fulfilled, (state, action) => {
      state.menu.status = 'succeeded'
      state.menu.data = action.payload
    })
    builder.addCase(getMenuById.rejected, (state, action) => {
      state.menu.status = 'failed'
      state.menu.data = intiMenu
    })
    builder.addCase(getSubMenuById.pending, (state, action) => {
      state.subMenu.status = 'loading'
      state.subMenu.data = intiSubMenu
    })
    builder.addCase(getSubMenuById.fulfilled, (state, action) => {
      state.subMenu.status = 'succeeded'
      state.subMenu.data = action.payload
    })
    builder.addCase(getSubMenuById.rejected, (state, action) => {
      state.subMenu.status = 'failed'
      state.subMenu.data = intiSubMenu
    })
  },
})

export const selectMenu = (state: RootState) => state.menuDetail.menu
export const selectSubMenu = (state: RootState) => state.menuDetail.subMenu

export default menusSlice.reducer
