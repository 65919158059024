import { ReactElement } from 'react'
import * as Styled from './TermsAndConditions.styled'
interface ITermsAndConditionsProps {
  type: string
}
const TermsAndConditions = ({
  type,
}: ITermsAndConditionsProps): ReactElement => {
  const renderContent = () => {
    if (type === 'privacy policy') {
      return (
        <Styled.TextWrapper>
          This privacy policy discloses the privacy practices for
          https://www.remedy.coffee. This privacy policy applies solely to
          information collected by this website
          <ul>
            <li>
              Remedy website cannot transit any user information without
              obtaining permission of the user.
            </li>
            <li>
              The security procedures in place to protect the misuse of your
              information.
            </li>
            <li>
              Personally information collected in website for business
              development in Remedy coffee shop.
            </li>
            <li>Personal data will be keep within 5 years.</li>
            <li>
              We respect your privacy. The personal data collect from Remedy
              website is held with care and security.We do not sell,rent or
              transfer this information to third parties for their marketing
              purposes unless you agree otherwise.
            </li>
          </ul>
        </Styled.TextWrapper>
      )
    } else {
      return (
        <Styled.TextWrapper>
          <ul>
            <li>
              <span>Cancellation: </span>
              The cancellation will be approved when store couldn’t make
              beverages.
            </li>
            <li>
              <span>Refund: </span>
              Refund will be approved under our conditions and refund at the
              store.
            </li>
            <li>
              <span>Deliver : </span>
              Order will be delivered by the pick-up time with two alternative
              choices pick up at store or pick-up points.
            </li>
          </ul>
          If you have any questions about our Returns and Refunds Policy,Please
          contact us
          <ul>
            <li>
              <span>By email : </span>remedy.coffeth@gmail.com
            </li>
            <li>
              <span>By Facebook : </span>@remedycoffeeth
            </li>
            <li>
              <span>By phone number : </span>098-274-0302
            </li>
          </ul>
        </Styled.TextWrapper>
      )
    }
  }
  return (
    <Styled.TermsAndConditionsWrapper>
      <h1>{type}</h1>
      {renderContent()}
    </Styled.TermsAndConditionsWrapper>
  )
}

export default TermsAndConditions
