import { createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import { changePasswordRequest } from './actions'

export interface IMenusState {
  resetPasswordResponse: {
    status: string
    data: any
  }
}

const initialState: IMenusState = {
  resetPasswordResponse: {
    status: 'idle',
    data: '',
  },
}

export const resetPasswordSlice = createSlice({
  name: 'resetPassword',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(changePasswordRequest.pending, (state, action) => {
      state.resetPasswordResponse.status = 'loading'
    })
    builder.addCase(changePasswordRequest.fulfilled, (state, action) => {
      state.resetPasswordResponse.status = 'succeeded'
      state.resetPasswordResponse.data = action.payload.message
    })
    builder.addCase(changePasswordRequest.rejected, (state, action) => {
      state.resetPasswordResponse.status = 'failed'
      state.resetPasswordResponse.data = action.payload
    })
  },
})

export const selectResetPasswordResponse = (state: RootState) =>
  state.resetPassword.resetPasswordResponse

export default resetPasswordSlice.reducer
